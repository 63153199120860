import { differenceInDays, format, subDays } from "date-fns";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import { FormEvent, useEffect, useState } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { Spinner, Dialog } from "../../components";
import { DataTableWrapper } from "../../components/DataTableWrapper";
import { Calendar, Dropdown } from "../../components/form";
import { ChequePayment as ChequePaymentType, ChequePaymentsDocument, ChequePaymentsQuery, useChequePaymentsQuery, useGetSupplierListQuery, useUpdateChequeStatusMutation } from "../../generated/graphql";
import { currencyFormat } from "../../helpers/currencyFormat";

const ChequePayment = () => {
    const dateFilterOptions = [
        { value: 'today', label: 'Today' },
        { value: 'tomorrow', label: 'Tomorrow' },
        { value: 'thisWeek', label: 'This Week' },
        { value: 'thisMonth', label: 'This Month' },
        { value: 'thisYear', label: 'This Year' },
    ];
    
    const limitOptions = [
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '75', label: '75' },
        { value: '100', label: '100' },
    ];
    const [cursor, setCursor] = useState('');
    const [limit, setLimit] = useState('');
    const [from, setFrom] = useState<Date>();
    const [to, setTo] = useState<Date>();
    const [supplier, setSupplier] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [order, setOrder] = useState('ASC');
    const [dateFilter, setDateFilter] = useState('');
    const [stat, setStat] = useState('');
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [status] = useState<'pending' | 'settled' | 'returned'>('pending');
    const [form, setForm] = useState<{ id: number; status: string }>();
    const { loading, data, client, fetchMore } = useChequePaymentsQuery({
        variables: {
            input: {
                limit,
                from: from?.toString(),
                to: to?.toString(),
                date_filter: dateFilter,
                supplierId: supplier,
                order,
                stat
            }
        }
    });
    const { data: suppliers } = useGetSupplierListQuery();
    const [updateStatus] = useUpdateChequeStatusMutation();

    useEffect(() => {
        setHasMore(data?.chequePayments.hasMore!);
        if (data?.chequePayments.hasMore) {
            const chequePayments = data.chequePayments.chequePayments || []
            if (chequePayments.length > 0) {
                setCursor(chequePayments[chequePayments.length - 1].id.toString());
            }
        }
    }, [loading]);

    const daysToRealize = (data: ChequePaymentType) => {
        const chequeDate = new Date(Number(data.chequeDate));
        const today = subDays(new Date(), 1);
        if (chequeDate > today) {
            return differenceInDays(chequeDate, today);
        } else {
            return 0;
        }
    };

    const handleLimit = (e: {
        originalEvent: Event
        value: any
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setLimit(e.value);
        client.resetStore();
    };

    const handleDateDropdownFilters = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setDateFilter(e.value);
        client.resetStore();
    };
    
    const handleSupplier = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setSupplier(e.value)
        client.resetStore()
    };

    const handleRangeDateFilters = (
        e: { originalEvent: Event; value: Date },
        filter: string,
    ) => {
        if (filter === 'from') {
            setFrom(e.value);
        } else {
            setTo(e.value);
        }
        client.resetStore();
    };

    const getSupplierName = (id: number) => suppliers?.getSupplierList.find(s => Number(s.id) === id)?.name;

    const clearFilters = () => {
        setLimit('');
        setFrom(undefined);
        setTo(undefined);
        setDateFilter('');
        setSupplier('');
        setOrder('ASC');
        setStat('');
        client.resetStore();
    };

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                input: {
                    limit,
                    cursor,
                    from: from?.toString(),
                    to: to?.toString(),
                    date_filter: dateFilter,
                    supplier,
                    stat
                },
            },
        });
    };

    const handleOrder = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setOrder(e.value);
        client.resetStore();
    };

    const handleStat = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setStat(e.value);
        client.resetStore();
    };

    const tableHeader = (
        <div className="p-grid">
            <Calendar
                columnSize="p-col-3"
                placeholder="From"
                value={from}
                handleSelect={(e) => handleRangeDateFilters(e, 'from')}
            />
            <Calendar
                columnSize="p-col-3"
                placeholder="To"
                value={to}
                handleSelect={(e) => handleRangeDateFilters(e, 'to')}
            />
            <Dropdown
                columnSize="p-col-3"
                icon={<AiOutlineNumber color="#01c0f8" />}
                placeholder="Record Limit"
                value={limit}
                handleChange={handleLimit}
                name="limit"
                options={limitOptions}
                optionLabel="label"
                optionValue="value"
            />
             <Dropdown
                columnSize="p-col-3"
                placeholder="Pick a date filter"
                value={dateFilter}
                handleChange={handleDateDropdownFilters}
                name="dateFilter"
                options={dateFilterOptions}
                optionLabel="label"
                optionValue="value"
            />
            <Dropdown
                columnSize="p-col-3"
                placeholder="Select Supplier"
                value={supplier}
                handleChange={handleSupplier}
                name="supplier"
                options={suppliers?.getSupplierList}
                optionLabel="name"
                optionValue="id"
            />
             <Dropdown
                columnSize="p-col-3"
                placeholder="Sort Records"
                icon={<BiSortDown color="#02c0f8" />}
                value={order}
                handleChange={handleOrder}
                name="order"
                options={[
                { value: 'ASC', label: 'Oldest First' },
                { value: 'DESC', label: 'Latest First' },
                ]}
                optionLabel="label"
                optionValue="value"
            />
            <Dropdown
                columnSize="p-col-3"
                placeholder="Select status"
                icon={<BiSortDown color="#02c0f8" />}
                value={stat}
                handleChange={handleStat}
                name="stat"
                options={[
                    { value: 'pending', label: 'Pending' },
                    { value: 'settled', label: 'Settled' },
                    { value: 'returned', label: 'Returned' },
                ]}
                optionLabel="label"
                optionValue="value"
            />
        </div>
    );

    const getStatus = (status: string) => {
        if (status === 'pending') {
            return <span
                style={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#feedaf',
                    color: '#8a5340',
                    borderRadius: '2px',
                    padding: '.1em .3rem',
                    letterSpacing: '.3px',
                    textTransform: 'uppercase',
                    }}
                >
                Pending
            </span>
        } else if (status === 'settled') {
            return <span
                style={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#c8e6c9',
                    color: '#256029',
                    borderRadius: '2px',
                    padding: '.1em .3rem',
                    letterSpacing: '.3px',
                    textTransform: 'uppercase',
                    }}
                >
                Settled
          </span>
        } else {
            return <span
                style={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#ffcdd2',
                    color: '#c63737',
                    borderRadius: '2px',
                    padding: '.1em .3rem',
                    letterSpacing: '.3px',
                    textTransform: 'uppercase',
                    }}
                >
                Returned
          </span>
        };
    };

    const handleUpdateStatus = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { data } = await updateStatus({
            variables: {
                input: {...form!}
            },
            update: (cache, { data }) => {
                if (data?.updateChequeStatus) {
                    const chequePayments = cache.readQuery<ChequePaymentsQuery>({ query: ChequePaymentsDocument });
                    const hasMore = chequePayments?.chequePayments.hasMore || false;
                    const totalAmount = chequePayments?.chequePayments.totalAmount || 0;
                    const payments = chequePayments?.chequePayments.chequePayments || [];
                    const existingPayments = [...payments];
                    const index = existingPayments.findIndex(p => data.updateChequeStatus.id === p.id);
                    if (index !== -1) {
                        existingPayments[index] = data.updateChequeStatus;
                        cache.writeQuery<ChequePaymentsQuery>({
                            query: ChequePaymentsDocument,
                            data: { chequePayments: { chequePayments: existingPayments, hasMore, totalAmount } }
                        });
                    }
                }
            }
        });
        if (data?.updateChequeStatus) {
            setShowEdit(false);
        }
    }

    return (
        <DataTableWrapper>
            <Dialog
                visible={showEdit}
                header="Update Status"
                handleOnHide={() => setShowEdit(false)}
            >
                <form onSubmit={handleUpdateStatus}>
                    <div className="p-d-flex p-jc-around">
                        <div>
                            <RadioButton
                                inputId="pending"
                                value="pending"
                                name="status"
                                onChange={(e) => setForm(s => ({ ...s!, status: e.target.value }))}
                                checked={form?.status === 'pending'}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="pending">Pending</label>
                        </div>
                            <div>
                            <RadioButton
                                inputId="settled"
                                value="settled"
                                name="status"
                                onChange={(e) => setForm(s => ({ ...s!, status: e.target.value }))}
                                checked={form?.status === 'settled'}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="settled">Settled</label>
                        </div>
                        <div>
                            <RadioButton
                                inputId="returned"
                                value="returned"
                                name="status"
                                onChange={(e) => setForm(s => ({ ...s!, status: e.target.value }))}
                                checked={form?.status === 'returned'}
                            />
                            &nbsp;&nbsp;
                            <label htmlFor="returned">Returned</label>
                        </div>
                    </div>
                    <div className="p-grid p-text-right" style={{ marginTop: '4rem' }}>
                        <div className="p-col-3 p-offset-9">
                            <Button className="button p-button-sm p-text-uppercase" type="submit">Update Status</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            {loading ? <Spinner /> : <DataTable header={tableHeader} value={data?.chequePayments.chequePayments} emptyMessage="No records">
                <Column header="Payment Date" body={(data: ChequePaymentType) => format(Number(data.paymentDate), 'yyyy-MM-dd')} />
                <Column header="Supplier" body={(data: ChequePaymentType) => getSupplierName(data.supplierId)} />
                <Column header="Amount (Rs.)" body={(data: ChequePaymentType) => currencyFormat(data.amount)} />
                <Column header="Cheque Date" body={(data: ChequePaymentType) => format(Number(data.chequeDate), 'yyyy-MM-dd')} />
                <Column header="Days to realize" body={(data: ChequePaymentType) => daysToRealize(data)} />
                <Column header="Status" body={(data: ChequePaymentType) => getStatus(data.status)} />
                <Column header="Action" body={(data: ChequePaymentType) =>
                    <Button
                        tooltip="Update Status"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-pencil"
                        className="p-button-text p-button-sm"
                        onClick={() => { setShowEdit(true); setForm({ id: data.id, status: status }) }}
                        type="button"
                    />}
                />
            </DataTable>}
            <div className="p-mt-4">Pending Amount: { currencyFormat(data?.chequePayments.totalAmount!) }</div>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    marginTop: '2rem',
                }}
            >
            <div className="p-d-flex p-jc-between">
                <Button onClick={clearFilters} type="button" className="p-button-sm">
                    {loading ? 'Loading' : 'Clear Filters'}
                </Button>
                <Button
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                    className="p-button-sm"
                >
                    {loading ? 'Loading' : 'Load More'}
                </Button>
            </div>
        </div>
    </DataTableWrapper>
    )
}

export { ChequePayment };