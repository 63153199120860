import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AutoComplete, Calendar, Dropdown } from '../../components/form'
import {
  useGetUpdateStockQuantityQuery,
  useCategoriesQuery,
  useGetBrandsQuery,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductColorsQuery,
  useGetUniqueProductNamesQuery,
  UpdateStockQuantity,
  useGetSupplierListQuery,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { Button } from 'primereact/button'
import { AvailabilityWrapper } from '../../components/AvailabilityWrapper'
import { arrayUnique } from '../../helpers/arrayUnique'
import { BiSearchAlt } from 'react-icons/bi'
import { AiOutlineNumber } from 'react-icons/ai'
import { format } from 'date-fns'

const StockQuantity = () => {
  const limitOptions = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ]

  const [cursor, setCursor] = useState('');
  const [limit, setLimit] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { data: codes } = useGetUniqueProductCodesQuery();
  const { data: names } = useGetUniqueProductNamesQuery();
  const { data: colors } = useGetUniqueProductColorsQuery();
  const { data: brands } = useGetBrandsQuery();
  const { data: categories } = useCategoriesQuery();
  const [from, setFrom] = useState<Date | undefined>();
  const [to, setTo] = useState<Date | undefined>();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [supplier, setSupplier] = useState('');
  const [color, setColor] = useState('');
  const [category, setCategory] = useState('');
  const [filteredNames, setFilteredNames] = useState<string[]>();
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredBrands, setFilteredBrands] = useState<string[]>();
  const [filteredCategories, setFilteredCategories] = useState<string[]>();
  const [filteredColors, setFilteredColors] = useState<string[]>();
  const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>();
  const { data: suppliers } = useGetSupplierListQuery();
  const { loading, data, client, fetchMore } = useGetUpdateStockQuantityQuery({
    variables: { input: { limit, code, category, supplier, color, brand, from: from?.toString(), to: to?.toString() } },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setHasMore(data?.getUpdateStockQuantity.hasMore!)
    if (data?.getUpdateStockQuantity.hasMore) {
      const stock = data.getUpdateStockQuantity.stock!;
      if (stock.length > 0) {
        setCursor(stock[stock.length - 1].id.toString())
      }
    }
  }, [loading]);

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value);
    client.resetStore();
  };

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchName = (e: { originalEvent: Event; query: string }) => {
    let filteredNames: string[]
    filteredNames =
      names?.getUniqueProductNames.filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredNames(arrayUnique(filteredNames))
  }

  const searchColor = (e: { originalEvent: Event; query: string }) => {
    let filteredColors: string[]
    filteredColors =
      colors?.getUniqueProductColors.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredColors(arrayUnique(filteredColors))
  }

  const searchBrand = (e: { originalEvent: Event; query: string }) => {
    let filteredBrands: string[]
    filteredBrands =
      brands?.getBrands
        .filter((b) => {
          return b.name.toLowerCase().includes(e.query.toLowerCase())
        })
        .map((fb) => fb.name) || []
    setFilteredBrands(arrayUnique(filteredBrands))
  }

  const searchCategory = (e: { originalEvent: Event; query: string }) => {
    let filteredCategories: string[]
    if (categories && categories.categories.length > 0) {
      filteredCategories = categories.categories
        .filter((c) => {
          return c.name!.toLowerCase().includes(e.query.toLowerCase())
        })
        .map((fc) => fc.name) as string[]
      setFilteredCategories(arrayUnique(filteredCategories))
    }
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleRangeDateFilters = (
    e: { originalEvent: Event; value: Date },
    filter: string,
) => {
    if (filter === 'from') {
        setFrom(e.value)
    } else {
        setTo(e.value)
    }
    client.resetStore()
};

  const handleAutoCompleteColor = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value)
  }

  const handleAutoCompleteName = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setName(e.value)
  }

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setBrand(e.value)
  }

  const handleAutoCompleteCategory = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCategory(e.value)
  }

  const handleAutoCompleteSupplier = (e: {
    originalEvent: Event
    value: string
    target: {
        name: string
        id: string
        value: any
    }
  }) => {
    setSupplier(e.value);
  };
  
  const searchSupplier = (e: { originalEvent: Event; query: string }) => {
    let filteredSuppliers: string[]
    filteredSuppliers =
        suppliers?.getSupplierList.filter((c) => {
            return c.name.toLowerCase().includes(e.query.toLowerCase())
        }).map(c => c.name) || []
    setFilteredSuppliers(arrayUnique(filteredSuppliers))
};

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          code,
          name,
          brand,
          category,
          color,
          from,
          to
        },
      },
    })
  }

  const clearFilters = () => {
    setLimit('')
    setCode('')
    setName('')
    setBrand('')
    setCategory('')
    setFrom(undefined);
    setTo(undefined);
    setSupplier('');
    setColor('');
    client.resetStore()
  }

  useEffect(() => {
    clearFilters()
  }, [])

  const tableHeader = (
    <div className="p-grid">
      <Calendar
          columnSize="p-col-3"
          placeholder="From"
          value={from}
          handleSelect={(e) => handleRangeDateFilters(e, 'from')}
      />
      <Calendar
          columnSize="p-col-3"
          placeholder="To"
          value={to}
          handleSelect={(e) => handleRangeDateFilters(e, 'to')}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Category"
        value={category}
        handleCompleteMethod={searchCategory}
        suggestions={filteredCategories}
        handleChange={handleAutoCompleteCategory}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Brand"
        value={brand}
        handleCompleteMethod={searchBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteBrand}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Name"
        value={name}
        handleCompleteMethod={searchName}
        suggestions={filteredNames}
        handleChange={handleAutoCompleteName}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search supplier"
            value={supplier}
            handleCompleteMethod={searchSupplier}
            suggestions={filteredSuppliers}
            handleChange={handleAutoCompleteSupplier}
            handleSelect={handleAutoCompleteSelect}
        />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search color"
        value={color}
        handleCompleteMethod={searchColor}
        suggestions={filteredColors}
        handleChange={handleAutoCompleteColor}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  );

  return loading ? (
    <Spinner />
  ) : (
    <>
      <AvailabilityWrapper>
        <DataTable
          header={tableHeader}
          value={data?.getUpdateStockQuantity.stock!}
          sortMode="single"
          sortField="code"
          sortOrder={1}
          emptyMessage="No records"
        >
            <Column field="category" header="Category"></Column>
            <Column field="brand" header="Brand"></Column>
            <Column field="code" header="Code"></Column>
            <Column field="name" header="Name"></Column>
            <Column header="Supplier" field="supplier"></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column header="User" body={(data: UpdateStockQuantity) => <>{ data.user.username }</>}></Column>
            <Column header="Date" field="date"></Column>
        </DataTable>
      </AvailabilityWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} className="p-button-sm">
            Clear Filters
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            Load More
          </Button>
        </div>
      </div>
    </>
  )
}

export { StockQuantity }
