import styled from 'styled-components';

export const LogoWrapper = styled.div`
  @media print {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem !important;
    img {
      width: 100%;
    }
  }
`;
