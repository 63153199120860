import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import {
  CustomerForm,
  CustomerState,
} from '../../components/customer/CustomerForm';
import { Spinner } from '../../components';
import {
  GetCustomersDocument,
  GetCustomersQuery,
  useCreateCustomerMutation,
} from '../../generated/graphql';
import { showToast } from '../../helpers/showSuccessToast';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/rootReducer';
import { AuthState } from '../../redux/auth/types';

const AddCustomer = () => {
  const toastSuccess = useRef<Toast>(null);
  const toastError = useRef<Toast>(null);
  const [createCustomer, { loading }] = useCreateCustomerMutation();
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);

  const handleSubmit = async (form: CustomerState) => {
    const joined = form.joined.toISOString();
    const dob = form.dob && form.dob.toISOString();
    const response = await createCustomer({
      variables: {
        input: {
          ...form,
          joined,
          dob,
          branchId: user?.branch.id!,
        },
      },
      update(cache, { data }) {
        try {
          const customers = cache.readQuery<GetCustomersQuery>({
            query: GetCustomersDocument,
          });
          if (customers?.getCustomers.customers) {
            let exisitngCustomers = [...customers?.getCustomers.customers];
            for (let c of exisitngCustomers) {
              if (c.id === data?.createCustomer.customer?.id) {
                c = { ...data.createCustomer.customer };
              }
              break;
            }
            cache.writeQuery<GetCustomersQuery>({
              query: GetCustomersDocument,
              data: {
                getCustomers: {
                  customers: exisitngCustomers,
                },
              },
            });
          }
        } catch (error) {}
      },
    });
    if (response?.data?.createCustomer.customer) {
      showToast(toastSuccess, 'Customer has been created');
    } else {
      showToast(
        toastSuccess,
        response?.data?.createCustomer.errors![0].message!,
        'error'
      );
    }
  };

  return (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <div className="form">
        {loading ? (
          <Spinner />
        ) : (
          <CustomerForm buttonText="Add Customer" handleSubmit={handleSubmit} />
        )}
      </div>
    </>
  );
};
export { AddCustomer };
