import styled from 'styled-components';

export const ReceiptContainer = styled.div`
  @media print {
    border: none;
    color: #000;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
  }
`;
