import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from '../../components';
import { Return, useGetExchangeReceiptsQuery } from '../../generated/graphql';

const ReceipExchangetList: FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useGetExchangeReceiptsQuery();

  useEffect(() => { 
    refetch();
  }, [refetch]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="card">
      <DataTable
        emptyMessage="No records found."
        value={data?.getExchangeReceipts}
      >
        <Column
          field="return_receipt_number"
          header="Exchange Receipt"
        ></Column>
        <Column
          field="associated.order_number"
          header="Receipt Number"
        ></Column>
        <Column
          field="date"
          header="Date"
          body={(x: Return) => format(Number(x.date), 'Y-MM-dd')}
        ></Column>
        <Column
          header="Action"
          body={(data: Return) => (
            <Button
              className="p-button-sm"
              icon="pi pi-undo"
              onClick={() =>
                history.push(
                  `/sales/return/receipt/create/${data.return_receipt_number}`
                )
              }
            />
          )}
        ></Column>
      </DataTable>
    </div>
  );
};

export { ReceipExchangetList };
