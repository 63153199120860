import { Toast } from "primereact/toast";

export interface SuccessToastInterface {
    toast: React.RefObject<Toast>;
    message: string;
    severity: "success" | "info" | "warn" | "error" | undefined;
    summary: string;
    life: number;
}

export const showToast = (
    toast: React.RefObject<Toast>,
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined = "success",
    summary: string = "Success",
    life: number = 5000
) => {
    if (toast && toast.current) {
        toast.current.show({
            severity: severity,
            summary: summary,
            detail: message,
            life: life,
        });
    }
};