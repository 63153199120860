import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  Category,
  useCategoriesQuery,
  useDeleteCategoryMutation,
  CategoriesDocument,
  AvaiableStockDocument,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { Button } from 'primereact/button'
import { useRef, useState, MouseEvent } from 'react'
import { Toast } from 'primereact/toast'
import { Dialog } from '../../components'
import { AddCategory } from '../../components/inventory'
import { confirmPopup } from 'primereact/confirmpopup'

const CategoryList = () => {
  const [addCategory, setAddCategory] = useState(false)
  const { loading, data } = useCategoriesQuery()
  const [category, setCategory] = useState<Category>()
  const [show, setShow] = useState(false)
  const toastSuccess = useRef<Toast>(null)
  const toastError = useRef<Toast>(null)
  const [deleteCategory] = useDeleteCategoryMutation()

  const handleOnClickAction = (
    e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: Category,
  ) => {
    if (action === 'edit') {
      setCategory(data)
      setShow(true)
    }
    if (action === 'delete') {
      confirmPopup({
        target: e.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteCategory({
            variables: { input: data.id! },
            refetchQueries: [
              { query: CategoriesDocument },
              { query: AvaiableStockDocument, variables: { input: {} } },
            ],
          })
          if (response.data?.deleteCategory.message) {
            showSuccess(response.data?.deleteCategory.message)
          } else {
            showError(response.data?.deleteCategory.errors![0].message!)
          }
        },
        reject: () => false,
      })
    }
  }

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 5000,
      })
    }
  }

  const showError = (message: string) => {
    if (toastError && toastError.current) {
      toastError.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 5000,
      })
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Dialog
        header="Add New Category"
        visible={show}
        handleOnHide={() => setShow(false)}
      >
        <AddCategory
          hideDialog={() => setShow(false)}
          toastSuccess={showSuccess}
          toastError={showError}
          category={category}
          edit
        />
      </Dialog>
      <div className="p-col-12">
        <Button
          onClick={() => setAddCategory(true)}
          className="p-mb-2"
          type="button"
        >
          <i className="pi pi-plus p-mr-2" /> New
        </Button>
      </div>
      <Dialog
        header="Add New Category"
        visible={addCategory}
        handleOnHide={() => setAddCategory(false)}
      >
        <AddCategory
          hideDialog={() => setAddCategory(false)}
          toastSuccess={showSuccess}
          toastError={showError}
        />
      </Dialog>
      <DataTable
        value={data?.categories}
        emptyMessage="No Categories"
        className="p-datatable-responsive"
      >
        <Column header="Id" body={(_: any, props: any) => props.rowIndex + 1} />
        <Column header="Name" field="name" />
        <Column
          header="Action"
          body={(data: Category) => (
            <>
              <Button
                icon="pi pi-pencil"
                tooltip={`Edit ${data.name}`}
                tooltipOptions={{ position: 'bottom' }}
                className="p-button-text p-button-sm"
                onClick={(e: any) => handleOnClickAction(e, 'edit', data)}
                type="button"
              />
              <Button
                icon="pi pi-trash"
                tooltip={`Delete ${data.name}`}
                tooltipOptions={{ position: 'bottom' }}
                className="p-button-text p-button-sm"
                style={{ marginLeft: '1rem' }}
                onClick={(e: any) => handleOnClickAction(e, 'delete', data)}
                type="button"
              />
            </>
          )}
          headerStyle={{ width: '8em', textAlign: 'center' }}
          bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
        />
      </DataTable>
    </>
  )
}

export { CategoryList }
