export const checkAvailability = (quantity: number, availability: number, in_stock: boolean): string => {
    if (Number(quantity) === 0) {
        return 'Quantity can not be 0';
    }
    if (!in_stock) {
        return 'Out of stock';
    }
    if (quantity > availability!) {
        return `Available stock is ${availability}`;
    }
    return ''
}