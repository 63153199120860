import styled from 'styled-components';

export const ReceiptTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0.5rem 0;
  text-transform: uppercase;
`;
