import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useMeQuery, usePermissionsQuery } from './generated/graphql';
import { Home } from './pages/Home';
import { Login } from './pages/auth/Login';
import { AuthAction, AuthTypes } from './redux/auth/types';

const Routes: FC = () => {
  const { data: user } = useMeQuery();
  const { data: permissions } = usePermissionsQuery();
  const dispatch = useDispatch<Dispatch<AuthAction>>();
  useEffect(() => {
    if (user?.me.user) {
      dispatch({ type: AuthTypes.FETCH_AUTH_USER, payload: user.me.user });
    }
  }, [user?.me.user, dispatch]);

  useEffect(() => {
    if (permissions?.permissions) {
      dispatch({
        type: AuthTypes.FETCH_PERMISSIONS,
        payload: permissions.permissions,
      });
    }
  }, [permissions?.permissions, dispatch]);

  
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Home />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;