import { FC, FormEvent, useRef, useState } from 'react'
import {
  useGetSupplierListQuery,
  GetproductsForReceiptDocument,
  ProductListResponse,
  StockItem,
  useCreateStockMutation,
  AvaiableStockDocument,
  GetStockVarianceDocument,
  GetReturnableStocksDocument,
  StockSize,
} from '../../generated/graphql'
import { Dialog } from '../../components'
import { BarcodeModule } from '../../components'
import { Dropdown, Input, Button } from '../../components/form'
import { Toast } from 'primereact/toast'
import { FaUser } from 'react-icons/fa'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/rootReducer'
import { format } from 'date-fns'
import styled from 'styled-components'
import { currencyFormat } from '../../helpers/currencyFormat'
import { RouteComponentProps } from 'react-router-dom'

const StockSummary = styled.div.attrs((props) => props.className)`
  padding: 0 0.5rem;
`

const SummaryTitle = styled.div`
  margin-right: 1rem;
`

const SummaryValue = styled.div``

interface InitialValues {
  supplierId: string;
  date: Date;
  creditPeriod?: string;
}

const schema = Yup.object().shape({
  supplierId: Yup.string().required('Supplier is required'),
})

const AddStock: FC<RouteComponentProps> = ({ history }) => {
  const toastSuccess = useRef<Toast>(null);
  const toastError = useRef<Toast>(null);
  const [items, setItems] = useState<ProductListResponse[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [errors, setErrors] = useState<ClientErrors[]>([]);
  const { data: supplierData } = useGetSupplierListQuery();
  const [form, setForm] = useState<InitialValues>({
    date: new Date(),
    supplierId: '',
    creditPeriod: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestId] = useState(`${Date.now()}-${Math.random().toString(36).substring(2, 9)}`);
  const branchId = useSelector<AppState, number>(
    (state) => state.auth.user?.branch.id!,
  )
  const [createStock, { loading }] = useCreateStockMutation()
  const [getSizes, setGetSizes] = useState<{ code: string; size: number; quantity: number; color: string; name: string }[]>([]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const f = { ...form }
    f.creditPeriod = e.currentTarget.value
    setForm(f)
  }

  const onSupplierChange = (e: { originalEvent: Event; value: any }) => {
    setForm({ ...form, supplierId: e.value })
  }

  const confirmFooter = () => {
    return (
      <div>
        <Button
          label="Cancel"
          type="button"
          icon="pi pi-times"
          onClick={() => setShowConfirm(false)}
        />
        <Button label="Confirm"  disabled={items.length === 0 || loading || isSubmitting} type="submit" icon="pi pi-check" />
      </div>
    )
  }
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrors([]);
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      });
    const stockItems: StockItem[] = [];
    items.forEach(item => {
      const sizes: StockSize[] = [];
      getSizes.forEach(size => {
        if (size.code === item.code && size.color === item.color) {
          sizes.push({ size: size.size, quantity: size.quantity, color: size.color });
        }
      });
      stockItems.push({ code: item.code, purchasePrice: item.price, sizes });
    });
    try {
      if (await schema.isValid(form)) {
        const response = await createStock({
          variables: {
            input: {
              supplierId: Number(form.supplierId),
              branchId,
              date: form.date.toISOString(),
              creditPeriod: Number(form.creditPeriod),
              items: stockItems,
              requestId
            },
          },
          refetchQueries: [
            { query: AvaiableStockDocument, variables: { input: {} } },
            { query: GetproductsForReceiptDocument, variables: { input: 'stock' } },
            {
              query: GetStockVarianceDocument,
              variables: {
                input: {},
              },
            },
            {
              query: GetReturnableStocksDocument,
              variables: { input: Number(form.supplierId) },
            },
          ],
        })
        if (response.data?.createStock) {
          showSuccess('Stock has been updated')
          setShowConfirm(false)
          setItems([]);
          setGetSizes([]);
          setForm({
            date: new Date(),
            supplierId: '',
            creditPeriod: ''
          });
          setTimeout(() => {
            // history.go(0);
            window.location.reload();
          }, 1000);
        } else {
          showError('Something went wrong');
        }
      }
    } catch (error) {
      showError('Something went wrong')
    } finally { 
      setIsSubmitting(false);
    }
  }

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 3000,
      })
    }
  }

  const showError = (message: string) => {
    if (toastError && toastError.current) {
      toastError.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 3000,
      })
    }
  }

  return (
    <div>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <div style={{ marginTop: '2rem' }}>
        <form onSubmit={handleSubmit}>
          <>
            <BarcodeModule
              getDate={setDate}
              dateName="Stock"
              headers={{ priceType: 'Purchase' }}
              showInvoiceNumber={false}
              getItems={setItems}
              type="stock"
              setGetSizes={setGetSizes}
            />
            <Dropdown
              icon={<FaUser color="#02c0f8" />}
              style={{
                width: '25%',
                marginLeft: 'auto',
              }}
              name="supplierId"
              value={form.supplierId}
              handleChange={onSupplierChange}
              options={supplierData?.getSupplierList}
              label="Supplier"
              optionValue="id"
              optionLabel="name"
              filterBy="name"
              placeholder="Pick a supplier"
              errorMsg={validateField('supplierId', errors)}
            />
            <Input
              columnSize="p-col-3"
              icon={<AiOutlineFieldTime />}
              value={form.creditPeriod}
              placeholder="Credit Period"
              name="creditPeriod"
              type="number"
              change={handleChange}
            />
            <div className="p-grid" style={{ marginTop: '3rem' }}>
              <div
                className="p-col-2 p-offset-10"
                style={{ textAlign: 'right' }}
              >
                <Button
                  className="button"
                  type="button"
                  disabled={items.length === 0 || loading}
                  onClick={() => setShowConfirm(true)}
                >
                  Update Stock
                </Button>
              </div>
            </div>
            <Dialog
              header="Confirm Stock Update"
              handleOnHide={() => setShowConfirm(false)}
              visible={showConfirm}
              handleFooter={confirmFooter()}
            >
              <StockSummary
                className="p-grid"
                style={{ color: '#f4f4fc', marginTop: '1rem' }}
              >
                <SummaryTitle>Date:</SummaryTitle>
                <SummaryValue>{format(form.date, 'Y-MM-d')}</SummaryValue>
              </StockSummary>
              <div style={{ width: '100%', marginTop: '1rem', borderCollapse: 'collapse' }}>
                  {items.map((i) => (
                    <div style={{ color: '#f4f4fc' }} key={i.id}>
                      <div className="p-d-flex p-jc-between">
                        <div style={{ padding: '1rem' }}>Name: {i.name}</div>
                        <div style={{ padding: '1rem' }}>Code: {i.code}</div>
                        <div style={{ padding: '1rem' }}>Purchase Price: {currencyFormat(i.price)}</div>
                      </div>
                      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '1rem' }}>
                        <thead>
                          <tr>
                            <th style={{ border: '1px solid #02c0f8', padding: '5px 2px' }}>Size</th>
                            <th style={{ border: '1px solid #02c0f8', padding: '5px 2px' }}>Quantity</th>
                            <th style={{ border: '1px solid #02c0f8', padding: '5px 2px' }}>Sub Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getSizes.filter(s => s.name === i.name && s.code === i.code).map(s =>
                            <tr key={s.size} style={{ textAlign: 'center' }}>
                              <td>{s.size}</td>
                              <td>{s.quantity}</td>
                              <td>{currencyFormat(s.quantity * i.price)}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ))}
              </div>
              <StockSummary
                className="p-grid p-mt-6"
                style={{ color: '#f4f4fc', marginTop: '1rem' }}
              >
                <SummaryTitle>Supplier:</SummaryTitle>
                <SummaryValue>
                  {
                    supplierData?.getSupplierList.find(
                      (x) => x.id === form.supplierId,
                    )?.name
                  }
                </SummaryValue>
              </StockSummary>
              <StockSummary
                className="p-grid"
                style={{ color: '#f4f4fc', marginTop: '1rem' }}
              >
                <SummaryTitle>Credit Period:</SummaryTitle>
                <SummaryValue>
                  {form.creditPeriod ? form.creditPeriod : 'none'}
                </SummaryValue>
              </StockSummary>
            </Dialog>
          </>
        </form>
      </div>
    </div>
  )
}

export { AddStock }
