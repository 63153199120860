import { Toast } from 'primereact/components/toast/Toast';
import { Dispatch, FC, SetStateAction } from 'react';
import {
  Customer,
  useUpdateCustomerMutation,
  GetCustomersDocument,
  GetCustomersQuery,
} from '../../generated/graphql';
import { Spinner } from '../';
import { CustomerForm, CustomerState } from './CustomerForm';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/rootReducer';
import { AuthState } from '../../redux/auth/types';
import { showToast } from '../../helpers/showSuccessToast';

interface EditCustomerProps {
  customer: Customer;
  setShowEdit: Dispatch<SetStateAction<boolean>>;
  success: React.RefObject<Toast>;
  fail: React.RefObject<Toast>;
}

const EditCustomer: FC<EditCustomerProps> = (props) => {
  const [updateCustomer, { loading }] = useUpdateCustomerMutation();
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);

  const handleSubmit = async (form: CustomerState) => {
    const joined = form.joined.toISOString();
    const dob = form.dob && form.dob.toISOString();
    props.setShowEdit(false);
    try {
      const response = await updateCustomer({
        variables: {
          input: {
            id: props.customer.id,
            ...form,
            joined,
            dob,
            branchId: user?.branch.id!,
          },
        },
        update(cache, { data }) {
          try {
            const customers = cache.readQuery<GetCustomersQuery>({
              query: GetCustomersDocument,
            });
            if (customers?.getCustomers.customers) {
              let exisitngCustomers = [...customers?.getCustomers.customers];
              for (let c of exisitngCustomers) {
                if (c.id === data?.updateCustomer.customer?.id) {
                  c = { ...data.updateCustomer.customer };
                }
                break;
              }
              cache.writeQuery<GetCustomersQuery>({
                query: GetCustomersDocument,
                data: {
                  getCustomers: {
                    customers: exisitngCustomers,
                  },
                },
              });
            }
          } catch (error) {}
        },
      });
      if (response?.data?.updateCustomer.customer) {
        showToast(props.success, 'Customer has been updated');
      } else {
        showToast(props.fail, response.errors![0].message, 'error');
      }
    } catch (error) {
      showToast(props.fail, error.message, 'error');
    }
  };

  return (
    <>
      <div className="form">
        {loading ? (
          <Spinner />
        ) : (
          <CustomerForm
            customer={props.customer}
            buttonText="Update Customer"
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
};
export { EditCustomer };
