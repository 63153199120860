import { FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataTableWrapper } from "../../components/DataTableWrapper";
import { Dialog, Spinner } from '../../components';
import { Dropdown, Input } from '../../components/form';
import { months } from '../../helpers/months';
import { RiCalendar2Fill } from 'react-icons/ri';
import { GiReceiveMoney } from 'react-icons/gi';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { ClientErrors, setSchemaErrors, validateField } from '../../helpers/handleClientValidation';
import { getYears } from '../../helpers/years';
import { BgSaleAchieved, GetSalesAchievedDocument, HcSaleAchieved, OgffSaleAchieved, OgfSaleAchieved, useCreateSalesTargetMutation, useDeleteSalesTargetMutation, useGetSalesAchievedQuery, useUpdateSalesTargetMutation } from '../../generated/graphql';
import { Toast } from 'primereact/toast';
import { showToast } from '../../helpers/showSuccessToast';
import { currencyFormat } from '../../helpers/currencyFormat';
import { confirmPopup } from 'primereact/confirmpopup';

const validationSchema = Yup.object().shape({
    year: Yup.string().required('Year is required'),
    month: Yup.string().required('Month is required'),
    target: Yup.string().required('Target is required')
});

const SalesTarget = () => {
    const [limit, setLimit] = useState('');
    const [cursor, setCursor] = useState('');
    const [showCreate, setShowCreate] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [selected, setSelected] = useState<BgSaleAchieved | OgfSaleAchieved | HcSaleAchieved | OgffSaleAchieved>();
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [target, setTarget] = useState('');
    const [errors, setErrors] = useState<ClientErrors[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [create] = useCreateSalesTargetMutation();
    const [update] = useUpdateSalesTargetMutation();
    const [deleteTarget] = useDeleteSalesTargetMutation();
    const toastSuccess = useRef<Toast>(null)
    const toastError = useRef<Toast>(null)
    const { loading, data, fetchMore, client } = useGetSalesAchievedQuery({
        variables: {
            input: {
                limit
            }
        }
    });

    const dataDatableData = () => { 
        if (data?.getSalesAchieved.bgSaleAchieved && data?.getSalesAchieved.bgSaleAchieved.length > 0) {
          return data.getSalesAchieved.bgSaleAchieved;
        } else if (data?.getSalesAchieved.ogfSaleAchieved && data?.getSalesAchieved.ogfSaleAchieved.length > 0) { 
          return data.getSalesAchieved.ogfSaleAchieved;
        } else if (data?.getSalesAchieved.ogffSaleAchieved && data?.getSalesAchieved.ogffSaleAchieved.length > 0) { 
            return data.getSalesAchieved.ogffSaleAchieved;
        }
        else {
          return data?.getSalesAchieved.hcSaleAchieved;
        }
      }

    useEffect(() => {
        setHasMore(data?.getSalesAchieved.hasMore!)
        if (data?.getSalesAchieved.hasMore) {
            const salesAchieved = dataDatableData()!;
            if (salesAchieved.length > 0) {
                setCursor(salesAchieved[salesAchieved.length - 1].id.toString());
            }
        }
    }, [loading]);

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                input: {
                    limit,
                    cursor
                },
            },
        });
    };

    const clearFilters = () => {
        setLimit('');
        client.resetStore();
    };

    useEffect(() => {
        clearFilters();
    }, []);

    const handleMonth = (e: {
        originalEvent: Event;
        value: any;
        target: {
            name: string;
            id: string;
            value: any;
        };
    }) => setMonth(e.value);

    const handleYear = (e: {
        originalEvent: Event;
        value: any;
        target: {
            name: string;
            id: string;
            value: any;
        };
    }) => setYear(e.value);

    const handleTarget = (e: FormEvent<HTMLInputElement>) => setTarget(e.currentTarget.value);

    const handleOnClickAction = (
        e: MouseEvent,
        action: string,
        data: BgSaleAchieved | OgfSaleAchieved | HcSaleAchieved | OgffSaleAchieved,
      ) => {
        if (action === 'edit') {
            setShowUpdate(true)
            setSelected(data);
            setIsUpdating(true);
            setYear(String(data.year));
            setMonth(String(data.month));
            setTarget(String(data.target));
        }
        if (action === 'delete') {
          confirmPopup({
            target: e.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
              accept: async () => {
                  const response = await deleteTarget({
                      variables: { input: data.id }, update: (cache, { data }) => {
                          if (data?.deleteSalesTarget.ogfSaleAchieved) {
                              const id = cache.identify(data?.deleteSalesTarget.ogfSaleAchieved!);
                              cache.evict({ id });
                              cache.reset();
                          } else if (data?.deleteSalesTarget.ogffSaleAchieved) {
                            const id = cache.identify(data?.deleteSalesTarget.ogffSaleAchieved!);
                            cache.evict({ id });
                            cache.reset();
                           }
                          else if (data?.deleteSalesTarget.bgSaleAchieved) {
                            const id = cache.identify(data?.deleteSalesTarget.bgSaleAchieved!);
                            cache.evict({ id });
                            cache.reset();
                          } else {
                            const id = cache.identify(data?.deleteSalesTarget.hcSaleAchieved!);
                            cache.evict({ id });
                            cache.reset();
                        }
                      }
                  });
                  if (response.data?.deleteSalesTarget.bgSaleAchieved || response.data?.deleteSalesTarget.ogfSaleAchieved || response.data?.deleteSalesTarget.hcSaleAchieved || response.data?.deleteSalesTarget.ogffSaleAchieved) {
                    showToast(toastSuccess, 'Sales target has been deleted');
                  } else {
                    showToast(toastError, response.data?.deleteSalesTarget.errors![0].message!, 'error');
                  }
            },
            reject: () => false,
          })
        }
      }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        setShowCreate(false);
        e.preventDefault();
        setErrors([]);
        setSchemaErrors(validationSchema, {year, month, target}, setErrors);
        if (await validationSchema.isValid({ year, month, target }, { abortEarly: false })) {
            if (isCreating) {
                const { data } = await create({
                    variables: {
                        input: { month, year, target }
                    },
                    refetchQueries: [{ query: GetSalesAchievedDocument, variables: { input: {} } }]
                });
                if (data?.createSalesTarget) {
                    showToast(toastSuccess, 'Sales target has been created');
                } else if (data?.createSalesTarget === null) {
                    showToast(toastError, 'Sales target already exists', 'error');
                } else {
                    showToast(toastError, 'Something went wrong, please try again', 'error');
                }
                setIsCreating(false);
            }
            if (isUpdating) {
                const { data } = await update({
                    variables: {
                        input: {
                            id: selected?.id, year, month, target
                        }
                    },
                    refetchQueries: [{ query: GetSalesAchievedDocument, variables: { input: {} } }]
                });
                if (data?.updateSalesTarget.ogfSaleAchieved || data?.updateSalesTarget.ogffSaleAchieved || data?.updateSalesTarget.bgSaleAchieved || data?.updateSalesTarget.hcSaleAchieved) {
                    showToast(toastSuccess, 'Sales target has been updated');
                } else {
                    showToast(toastError, data?.updateSalesTarget.errors![0].message!, 'error');
                }
                setIsUpdating(false);
                setShowUpdate(false);
            }
        }
    }

    return (
        <>
            <Toast ref={toastSuccess} />
            <Toast ref={toastError} />
             <Dialog
                header="Add New Target"
                visible={showCreate}
                handleOnHide={() => { setShowCreate(false); setIsCreating(false); setMonth(''); setTarget(''); setYear(''); setErrors([]); }}
            >
                <form onSubmit={handleSubmit}>
                    <Dropdown
                        columnSize="p-col-12"
                        icon={<RiCalendar2Fill color="#01c0f8" />}
                        placeholder="Pick a Year"
                        value={year}
                        handleChange={handleYear}
                        name="year"
                        options={getYears().map(y => ({ label: y, value: y }))}
                        optionLabel="label"
                        optionValue="value"
                        errorMsg={validateField('year', errors)}
                    />
                    <Dropdown
                        columnSize="p-col-12"
                        icon={<RiCalendar2Fill color="#01c0f8" />}
                        placeholder="Pick a month"
                        value={month}
                        handleChange={handleMonth}
                        name="month"
                        options={months}
                        optionLabel="label"
                        optionValue="value"
                        errorMsg={validateField('month', errors)}
                    />
                    <Input type="number" name="target" value={target} placeholder="Set monthly target" change={handleTarget} icon={<GiReceiveMoney />}  errorMsg={validateField('target', errors)} />
                    <div className="p-grid" style={{ marginTop: '1rem' }}>
                        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                            <Button type="submit" className="p-button-sm">Save Target</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog header="Update target" visible={showUpdate} handleOnHide={() => { setShowUpdate(false); setIsUpdating(false); }}>
                <form onSubmit={handleSubmit}>
                    <Dropdown
                        columnSize="p-col-12"
                        icon={<RiCalendar2Fill color="#01c0f8" />}
                        placeholder="Pick a Year"
                        value={year}
                        handleChange={handleYear}
                        name="year"
                        options={getYears().map(y => ({ label: y, value: y }))}
                        optionLabel="label"
                        optionValue="value"
                        errorMsg={validateField('year', errors)}
                    />
                    <Dropdown
                        columnSize="p-col-12"
                        icon={<RiCalendar2Fill color="#01c0f8" />}
                        placeholder="Pick a month"
                        value={month}
                        handleChange={handleMonth}
                        name="month"
                        options={months}
                        optionLabel="label"
                        optionValue="value"
                        errorMsg={validateField('month', errors)}
                    />
                    <Input type="number" name="target" value={target} placeholder="Set monthly target" change={handleTarget} icon={<GiReceiveMoney />}  errorMsg={validateField('target', errors)} />
                    <div className="p-grid" style={{ marginTop: '1rem' }}>
                        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                            <Button type="submit" className="p-button-sm">Update Target</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            <div className="p-grid" style={{ marginTop: '1rem' }}>
                <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                    <Button className="p-button-sm" type="button" onClick={() => { setShowCreate(true); setIsCreating(true); }}>New</Button>
                </div>
            </div>
            <DataTableWrapper>
                {loading ? <Spinner /> :
                    <DataTable emptyMessage="No records" value={dataDatableData()!}>
                        <Column field="year" header="Year" />
                        <Column header="Month" body={(data: any) => months.find(m => m.value === data.month.toString())?.label} />
                        <Column field="target" header="Target (Rs.)" body={(data: any) => currencyFormat(data.target)} />
                        <Column field="achieved" header="Achieved (Rs.)" body={(data: any) => currencyFormat(data.achieved ?? 0)} />
                        <Column header="Achieved %" body={(data: any) => data.achievedPercentage + '%'} />
                        <Column header="Action" body={(data: any) => <>
                            <Button
                                tooltip="Edit Target"
                                tooltipOptions={{ position: 'bottom' }}
                                className="p-button-text p-button-sm"
                                style={{ marginLeft: '5px' }}
                                icon="pi pi-pencil"
                                onClick={(e: MouseEvent) =>
                                    handleOnClickAction(e, 'edit', data)
                                }
                            />
                            <Button
                                tooltip="Delete Target"
                                tooltipOptions={{ position: 'bottom' }}
                                className="p-button-text p-button-sm"
                                style={{ marginLeft: '5px' }}
                                icon="pi pi-times"
                                onClick={(e: any) =>
                                    handleOnClickAction(e, 'delete', data)
                                }
                                type="button"
                            />
                        </>} />
                    </DataTable>
                }
            </DataTableWrapper>
            <div style={{ position: 'relative', width: '100%', marginTop: '2rem'}}>
                <div className="p-d-flex p-jc-between">
                <Button onClick={clearFilters} type="button" className="p-button-sm">
                    {loading ? 'Loading' : 'Clear Filters'}
                </Button>
                <Button
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                    className="p-button-sm"
                >
                    {loading ? 'Loading' : 'Load More'}
                </Button>
                </div>
            </div>
        </>
    )
}

export { SalesTarget };