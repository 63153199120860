import { FC, FormEvent, useEffect, useState } from 'react'
import countries from 'country-list'
import { Dropdown, Button, Input } from '../form'
import {
  useCategoriesQuery,
  useCreateBrandMutation,
  GetBrandsDocument,
  Product,
  useUpdateBrandMutation,
  AvaiableStockDocument,
} from '../../generated/graphql'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import { GoGlobe } from 'react-icons/go'
import { Checkbox } from 'primereact/checkbox'
import { GiCheckboxTree } from 'react-icons/gi'

interface AddBrandProps {
  hideDialog: () => void
  toastSuccess: (message: string) => void
  toastError: (message: string) => void
  product?: Product
  edit?: boolean
}

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(20),
})

const AddBrand: FC<AddBrandProps> = (props) => {
  const [form, setForm] = useState({ name: '', country: '' })
  const [pickedCategories, setPickedCategories] = useState<number[]>([])
  const { data } = useCategoriesQuery()
  const [createBrand] = useCreateBrandMutation()
  const [updateBrand] = useUpdateBrandMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])

  useEffect(() => {
    if (
      props.product &&
      props.product.categories &&
      props.product.categories.length > 0
    ) {
      const categoryIds = props.product!.categories!.map((c) => c.id) as Array<
        number
      >
      setPickedCategories(categoryIds)
      if (props.product) {
        setForm({ name: props.product.name, country: props.product.country! })
      }
    }
  }, [props.product])

  const handleDropdownChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setForm((s) => ({ ...s, country: e.target.value }))
  }

  const handleChange = (e: any) => {
    setForm((s) => ({ ...s, name: e.target.value }))
  }

  const changeCategories = (e: {
    originalEvent: Event
    value: any
    checked: boolean
    target: {
      type: string
      name: string
      id: string
      value: any
      checked: boolean
    }
  }) => {
    const categories = [...pickedCategories]
    const cIndex = categories.findIndex((c) => c === Number(e.value))
    if (cIndex !== -1) {
      categories.splice(cIndex, 1)
      setPickedCategories(categories)
    } else {
      setPickedCategories((c) => [...c, Number(e.value)])
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid(form, { abortEarly: false })) {
      if (props.edit) {
        const response = await updateBrand({
          variables: {
            input: {
              id: props.product!.id,
              categoryIds: pickedCategories,
              country: form.country,
              name: form.name,
            },
          },
          refetchQueries: [
            { query: GetBrandsDocument },
            { query: AvaiableStockDocument, variables: { input: {} } },
          ],
        })
        if (!response.data?.updateBrand.errors) {
          props.toastSuccess(response.data?.updateBrand.message!)
        } else {
          props.toastError(response.data.updateBrand.errors[0].message)
        }
      } else {
        const response = await createBrand({
          variables: {
            input: {
              categoryIds: pickedCategories,
              name: form.name,
              country: form.country,
            },
          },
          refetchQueries: [{ query: GetBrandsDocument }],
        })
        if (!response.data?.createBrand.errors) {
          props.toastSuccess(response.data?.createBrand.message!)
        } else {
          props.toastError(response.data.createBrand.errors[0].message)
        }
      }
      props.hideDialog()
    }
  }
  return (
    <form style={{ height: '40vh' }} onSubmit={handleSubmit}>
      <Dropdown
        name="country"
        label="Country"
        icon={<GoGlobe color="#02c0f8" />}
        options={countries.getData()}
        optionLabel="name"
        optionValue="name"
        placeholder="Pick a Country"
        handleChange={handleDropdownChange}
        value={form.country}
      />
      <Input
        icon={<GiCheckboxTree />}
        value={form.name}
        name="name"
        change={handleChange}
        placeholder="Brand Name"
        errorMsg={validateField('name', errors)}
      />
      <h4>Categories</h4>
      {data?.categories &&
        data.categories.length > 0 &&
        data.categories.map((c) => (
          <div
            key={c.id}
            className="p-mt-3 p-mr-4 p-text-center p-d-inline-flex"
          >
            <Checkbox
              name="categories"
              inputId={c.id!.toString()}
              onChange={changeCategories}
              value={c.id}
              checked={pickedCategories.includes(c.id!)}
              style={{ marginTop: '4px' }}
            />
            <label
              htmlFor={c.id!.toString()}
              className="p-checkbox-label"
              style={{ fontSize: '.95rem' }}
            >
              {c.name}
            </label>
          </div>
        ))}
      <div className="p-grid" style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            {props.edit ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { AddBrand }
