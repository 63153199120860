import {
  FC,
  FormEvent,
  SetStateAction,
  Dispatch,
  MouseEvent,
  useRef,
  useEffect,
} from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  Role,
  useRolesQuery,
  Permission,
  useAssignPermissionsToRoleMutation,
  RolesDocument,
  useDeleteRoleMutation,
  DeleteRoleMutation,
  GetRolesQuery,
  GetRolesDocument,
  useEditRoleMutation,
} from '../../../generated/graphql'
import { Spinner } from '../../../components'
import { Button } from 'primereact/button'
import { Dialog } from '../../../components'
import { useState } from 'react'
import { pascalCase } from '../../../helpers/pascalCase'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/rootReducer'
import { Checkbox } from 'primereact/checkbox'
import { confirmPopup } from 'primereact/confirmpopup'
import { showToast } from '../../../helpers/showSuccessToast'
import { Toast } from 'primereact/toast'
import { ApolloCache } from '@apollo/client'
import { Input } from '../../../components/form'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'

interface PermissionHashTable {
  [name: string]: {
    id: number;
    name: string;
  }[]
}

const RoleList: FC = () => {
  const allPermissionsRedux = useSelector<AppState, Permission[]>(
    (state) => state.auth.allPermissions,
  )
  const [assignPermissions] = useAssignPermissionsToRoleMutation()
  const { loading, data } = useRolesQuery()
  const [showCurrentPermissions, setShowCurrentPermissions] = useState(false)
  const [showAllPermissions, setShowAllPermissions] = useState(false)
  const [currentPermissions, setCurrentPermissions] = useState<
    PermissionHashTable
  >({});
  const [showUpdateRole, setShowUpdateRole] = useState(false)
  const [form, setForm] = useState({ name: '' })
  const [updateRole] = useEditRoleMutation()
  const [allPermissions, setAllPermissions] = useState<PermissionHashTable>({})
  const [selectedRole, setSelectedRole] = useState<number>()
  const [assignedPermissions, setAssignedPermissions] = useState<number[]>([])
  const [deleteRole] = useDeleteRoleMutation()
  const toastSuccess = useRef(null)
  const toastError = useRef(null)

  useEffect(() => {
    setAssignedPermissions(
      Object.keys(currentPermissions)
        .map((k) => currentPermissions[k].map((cp) => cp.id))
        .flat(),
    )
  }, [currentPermissions])

  const createPermissionsHashTable = (
    id: number,
    permissions: Permission[],
    stateAction2: Dispatch<SetStateAction<PermissionHashTable>>,
  ) => {
    setSelectedRole(id)
    const hashTable2: PermissionHashTable = {};
    permissions.forEach(p => { 
      const parent = p.parent;
      const permissionName = p.name.split('-').map(n => pascalCase(n)).join(' ');
      if (!hashTable2[parent]) {
        hashTable2[parent] = [{ id: p.id, name: permissionName }];
      } else {
        hashTable2[parent].push({ id: p.id, name: permissionName });
      }
    });
    if (stateAction2) { 
      stateAction2(hashTable2);
    }
  }

  const handleChange = (e: {
    target: {
      type: string
      name: string
      id: string
      value: any
      checked: boolean
    }
  }) => {
    const current = [...assignedPermissions]
    const id = Number(e.target.value)
    const currentIndex = assignedPermissions.findIndex((ap) => ap === id)
    if (currentIndex !== -1) {
      current.splice(currentIndex, 1)
    } else {
      current.push(id)
    }
    setAssignedPermissions(current)
  }

  const handleUpdate = (e: FormEvent<HTMLInputElement>) => {
    setForm({ name: e.currentTarget.value })
  }

  const handleRoleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const { data } = await updateRole({
        variables: {
          id: selectedRole!,
          name: form.name,
        },
      })
      if (data?.editRole.role) {
        showToast(toastSuccess, 'Role has been updated')
      } else {
        showToast(toastError, data?.editRole.errors![0].message!, 'error')
      }
    } catch (error) {
      showToast(toastError, 'Server Error', 'error')
      console.log(error)
    }
    setShowUpdateRole(false)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const { data } = await assignPermissions({
        variables: { id: selectedRole!, permissions: assignedPermissions },
        refetchQueries: [{ query: RolesDocument }],
      })
      if (data?.assignPermissionsToRole.role?.id) {
        showToast(toastSuccess, 'Permissions have been changed')
      } else {
        showToast(
          toastError,
          data?.assignPermissionsToRole.errors![0].message!,
          'error',
        )
      }
    } catch (error) {
      showToast(toastError, 'Server Error', 'error')
      console.log(error)
    }

    setShowAllPermissions(false)
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
        <Dialog
          visible={showUpdateRole}
          header="Update Role"
          handleOnHide={() => setShowUpdateRole(false)}
      >
        <form onSubmit={handleRoleUpdate}>
          <Input
            icon={<AiOutlineUsergroupAdd />}
            name="name"
            value={form.name}
            placeholder="Role Name"
            change={handleUpdate}
          />
          <Button type="submit">Update</Button>
        </form>
      </Dialog>
        <Dialog
          width={60}
          visible={showCurrentPermissions}
          header="Current Permissions"
          handleOnHide={() => setShowCurrentPermissions(false)}
        >
          {Object.keys(currentPermissions).map((k) => (
            <div key={k} className="p-pl-5 p-py-3">
              <div className="p-text-uppercase p-mb-3">{k}</div>
              <div className="p-grid">
                {currentPermissions[k].map((p, i) => (
                  <div key={i} className="p-col-3">
                    <i
                      style={{ fontSize: '.9rem', color: '#02c0f8' }}
                      className="pi pi-check-circle p-mr-2"
                    ></i>
                    <span style={{ fontSize: '.9rem' }}>{p.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </Dialog>
        <Dialog
          width={60}
          visible={showAllPermissions}
          header="Modify Permissions"
          maximizable={false}
          handleOnHide={() => setShowAllPermissions(false)}
      >
          <form onSubmit={handleSubmit}>
            {Object.keys(allPermissions).map((k) => (
              <div key={k} className="p-pl-5 p-py-3">
                <div className="p-text-uppercase p-mb-3">{k}</div>
                <div className="p-grid">
                  {allPermissions[k].map((p, i) => (
                    <div
                      key={i}
                      className="p-col-3"
                    >
                      <Checkbox
                        name="permission"
                        inputId={p.id.toString()}
                        onChange={handleChange}
                        value={p.id}
                        checked={assignedPermissions.includes(p.id)}
                        style={{ marginTop: '4px' }}
                      />
                      <label
                        htmlFor={p.id.toString()}
                        className="p-checkbox-label"
                        style={{ fontSize: '.9rem' }}
                      >
                        {p.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <Button type="submit">Modify</Button>
        </form>
      </Dialog>
      <DataTable value={data?.roles}>
        <Column field="name" header="Name" />
        <Column
          header="Action"
          body={(data: Role) => (
            <>
              <Button
                tooltip="Update Role"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-pencil"
                className="p-button-text p-button-sm"
                onClick={() => {
                  setShowUpdateRole(true)
                  setForm({ name: data.name })
                  setSelectedRole(data.id)
                }}
                type="button"
              />
              <Button
                tooltip="Delete Role"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-trash"
                className="p-button-text p-button-sm"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  confirmPopup({
                    target: e.currentTarget,
                    message: 'Are you sure you want to delete the role?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: async () => {
                      try {
                        const response = await deleteRole({
                          variables: { id: data.id },
                          update(
                            cache: ApolloCache<DeleteRoleMutation>,
                            { data },
                          ) {
                            const cachedRoles = cache.readQuery<GetRolesQuery>({
                              query: GetRolesDocument,
                            })
                            if (cachedRoles && data?.deleteRole.id) {
                              const findRoleIndex = cachedRoles.roles.findIndex(
                                (r) => r.id === data.deleteRole.id,
                              )
                              if (findRoleIndex !== -1) {
                                const roles = [...cachedRoles.roles]
                                delete roles[findRoleIndex]
                                cache.writeQuery<GetRolesQuery>({
                                  query: GetRolesDocument,
                                  data: { roles },
                                })
                              }
                            }
                          },
                        })
                        if (response.data?.deleteRole.id) {
                          showToast(toastSuccess, 'Role has been deleted')
                        } else {
                          showToast(
                            toastError,
                            response.data?.deleteRole.errors![0].message!,
                            'error',
                          )
                        }
                      } catch (error) {
                        showToast(toastError, 'Server Error', 'error')
                        console.log(error)
                      }
                    },
                    reject: () => false,
                  })
                }}
                type="button"
              />
              <Button
                tooltip="Current Permissions"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-unlock"
                className="p-button-text p-button-sm"
                onClick={() => {
                  setShowCurrentPermissions(true)
                  createPermissionsHashTable(
                    data.id,
                    data.permissions!,
                    setCurrentPermissions,
                  )
                }}
                type="button"
              />
              <Button
                tooltip="Modify Permissions"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-key"
                className="p-button-text p-button-sm"
                onClick={() => {
                  setShowAllPermissions(true)
                  createPermissionsHashTable(
                    data.id,
                    data.permissions!,
                    setCurrentPermissions
                  )
                  createPermissionsHashTable(
                    data.id,
                    allPermissionsRedux,
                    setAllPermissions
                  )
                }}
                type="button"
              />
            </>
          )}
        />
      </DataTable>
    </>
  )
}

export { RoleList }
