import { FC } from 'react'
import { Dropdown as PrimeDropDown } from 'primereact/dropdown'
import { ErrorMessage } from './ErrorMessage'

interface DropdownProps {
  handleChange: (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => void
  name: string
  value: any
  options: Array<any> | undefined
  optionLabel?: string
  optionValue?: string
  placeholder: string
  label?: string
  filter?: boolean
  filterBy?: string
  style?: Record<string, string | number>
  errorMsg?: string
  icon?: JSX.Element
  columnSize?: string
}

const Dropdown: FC<DropdownProps> = ({ ...props }) => {
  const { optionLabel = 'name', optionValue = 'id', filterBy = 'name' } = props
  return (
    <div className={props.columnSize ? props.columnSize : 'p-col-12'}>
      <div style={{ ...props.style, display: 'flex' }}>
        <div
          style={{
            background: '#504E53',
            width: '54.38px',
            height: '45px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {props.icon}
          </div>
        </div>
        <PrimeDropDown
          name={props.name}
          filter={props.filter}
          filterBy={filterBy}
          style={{
            width: '100%',
            padding: '0.3rem',
            borderRadius: '0',
          }}
          value={props.value}
          options={props.options}
          onChange={props.handleChange}
          optionLabel={optionLabel}
          optionValue={optionValue}
          placeholder={props.placeholder}
          className={props.errorMsg && 'p-invalid'}
        />
      </div>
      <div style={{ marginLeft: '.6rem', marginTop: '.2rem', ...props.style }}>
        {props.errorMsg && <ErrorMessage message={props.errorMsg} />}
      </div>
    </div>
  )
}

export { Dropdown }
