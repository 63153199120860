import styled from 'styled-components';

const Main = styled.section`
  width: 98%;
  position: absolute;
  background: #25252b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 95vh;
  border-radius: 1.5rem;
  display: flex;
  @media print {
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;
export { Main };
