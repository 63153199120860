import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useState } from 'react'
import { Spinner } from '../../components'
import {
  SupplierOutstanding,
  useGetOutstandingQuery,
} from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat'
import format from 'date-fns/format'

const Outstanding = () => {
  const [outstading, setOutstanding] = useState<SupplierOutstanding[]>([])
  const { loading } = useGetOutstandingQuery({
    onCompleted: ({ getOutstanding }) => {
      setOutstanding(getOutstanding)
    },
  })
  return loading ? (
    <Spinner />
  ) : (
    <DataTable
      value={outstading}
      emptyMessage="No suppliers"
      className="p-datatable-responsive"
    >
      <Column
        header="Id"
        body={(_: any, props: any) => props.rowIndex + 1}
      ></Column>
      <Column field="name" header="Name" />
      <Column
        field="outstanding"
        header="Outstanding"
        body={(x: SupplierOutstanding) => currencyFormat(x.outstanding)}
      />
      <Column
        field="last_settled_date"
        header="Last Settled"
        body={(x: SupplierOutstanding) =>
          x.last_settled_date
            ? format(Number(x.last_settled_date), 'yyyy-MM-dd')
            : '-'
        }
      />
      <Column field="days" header="Days (After Last Settlement)" />
    </DataTable>
  )
}

export { Outstanding }
