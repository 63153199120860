import { FC, FormEvent, useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import {
  useCreateCategoryMutation,
  CategoriesDocument,
  Category,
  useUpdateCategoryMutation,
  AvaiableStockDocument,
} from '../../generated/graphql'
import { Input } from '../form/Input'
import * as Yup from 'yup'
import { ValidationError } from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import { GiCheckboxTree } from 'react-icons/gi'

interface AddCategoryProps {
  hideDialog: () => void
  toastSuccess: (message: string) => void
  toastError: (message: string) => void
  category?: Category
  edit?: boolean
}

const schema = Yup.object().shape({
  category: Yup.string().required('Category name is required').max(20),
})

const AddCategory: FC<AddCategoryProps> = (props) => {
  const [category, setCategory] = useState<string>('')
  const [createCategory] = useCreateCategoryMutation()
  const [updateCategory] = useUpdateCategoryMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setCategory(e.currentTarget.value)
  }

  useEffect(() => {
    if (props.category?.name) setCategory(props.category.name)
  }, [props.category])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors([]);
    schema
      .validate({ category }, { abortEarly: false })
      .catch((error: ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid({ category }, { abortEarly: false })) {
      if (props.edit) {
        const response = await updateCategory({
          variables: { input: { id: props.category!.id, name: category } },
          refetchQueries: [
            { query: CategoriesDocument },
            { query: AvaiableStockDocument, variables: { input: {} } },
          ],
        })
        if (!response.data?.updateCategory.errors) {
          props.toastSuccess(response.data?.updateCategory.message!)
        } else {
          props.toastError(response.data.updateCategory.errors[0].message)
        }
      } else {
        const response = await createCategory({
          variables: { input: { name: category } },
          refetchQueries: [{ query: CategoriesDocument }],
        })
        if (!response.data?.createCategory.errors) {
          props.toastSuccess(response.data?.createCategory.message!)
        } else {
          props.toastError(response.data.createCategory.errors[0].message)
        }
      }
      props.hideDialog()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Input
        icon={<GiCheckboxTree />}
        value={category}
        name="Category"
        change={handleChange}
        placeholder="Category Name"
        errorMsg={validateField('category', errors)}
      />
      <div className="p-grid" style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            {props.edit ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { AddCategory }
