import { Permission, User } from "../../generated/graphql";

export enum AuthTypes {
    FETCH_AUTH_USER = 'FETCH_AUTH_USER',
    FETCH_PERMISSIONS = 'FETCH_PERMISSIONS',
}

export interface AuthState {
    user: User | null;
    allPermissions: Permission[];
}

export interface FetchAuthUser {
    type: AuthTypes.FETCH_AUTH_USER;
    payload: User;
}


export interface FetchPermissions {
    type: AuthTypes.FETCH_PERMISSIONS;
    payload: Permission[];
}

export type AuthAction = FetchAuthUser | FetchPermissions