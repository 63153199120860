import { FC, FormEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { RouteComponentProps } from 'react-router'
import { Spinner } from '../../components'
import {
  GetOrderListDocument,
  GetOrderListQuery,
  useGetOrderListQuery,
  useVoidOrderMutation,
  useDeleteOrderMutation,
  OrderList,
} from '../../generated/graphql'
import { format } from 'date-fns'
import { currencyFormat } from '../../helpers/currencyFormat'
import { confirmPopup } from 'primereact/confirmpopup'
import { showToast } from '../../helpers/showSuccessToast'
import { Toast } from 'primereact/toast'
import { Calendar, Dropdown, Input } from '../../components/form'
import { AiOutlineNumber } from 'react-icons/ai'
import { DataTableWrapper } from '../../components/DataTableWrapper'
import { MdToday } from 'react-icons/md'

const ReceiptList: FC<RouteComponentProps> = ({ history }) => {
  const dateFilterOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'thisWeek', label: 'This Week' },
    { value: 'thisMonth', label: 'This Month' },
    { value: 'thisYear', label: 'This Year' },
  ];
  const limitOptions = [
      { value: '25', label: '25' },
      { value: '50', label: '50' },
      { value: '75', label: '75' },
      { value: '100', label: '100' },
  ];
  const [orderNumber, setOrderNumber] = useState('');
  const [cursor, setCursor] = useState('');
  const [limit, setLimit] = useState('');
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [hasMore, setHasMore] = useState(true);
  const [dateFilter, setDateFilter] = useState('');
  const { loading, data, client, fetchMore, refetch } = useGetOrderListQuery({
    variables: {
      input: {
        limit,
        from: from?.toString(),
        to: to?.toString(),
        date_filter: dateFilter
      }
    }
  });
  const [voidOrder] = useVoidOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const toastSuccess = useRef(null);
  const toastError = useRef(null);

  useEffect(() => {
    setHasMore(data?.getOrderList.hasMore!);
    if (data?.getOrderList.hasMore) {
        const orderList = data.getOrderList.orderList || []
        if (orderList.length > 0) {
          setCursor(orderList[orderList.length - 1].id.toString());
        }
    }
}, [loading, data?.getOrderList]);

  const handleVoid = async (id: number, status: boolean) => {
    try {
      const { data } = await voidOrder({
        variables: { id },
        refetchQueries: [{ query: GetOrderListDocument, variables: {input: {}} }],
      })
      if (data?.voidOrder.id) {
        if (status) {
          showToast(toastSuccess, 'Sale has been set to valid');
        } else {
          showToast(toastSuccess, 'Sale has been set to void');
        }
      } else {
        showToast(toastError, data?.voidOrder.errors![0].message!, 'error')
      }
    } catch (error) {
      showToast(toastError, 'Server Error', 'error')
      console.log(error)
    }
  }

  const handleDelete = async (id: number) => {
    try {
      const { data } = await deleteOrder({
        variables: { id },
        update: (cache, { data }) => {
          if (data?.deleteOrder.order) {
            const existing = cache.readQuery<GetOrderListQuery>({ query: GetOrderListDocument, variables: { input: {} } });
            if (existing?.getOrderList.orderList && data.deleteOrder.order) {
              const existingOrderList = [...existing.getOrderList.orderList];
              const index = existingOrderList.findIndex(eol => eol.id === data.deleteOrder.order?.id);
              if (index !== -1) {
                const id = cache.identify(existingOrderList[index]);
                cache.evict({ id });
              }
            }
          }
        }
      })
      if (data?.deleteOrder.order) {
        showToast(toastSuccess, 'Receipt has been deleted');
      } else {
        showToast(toastError, data?.deleteOrder.errors![0].message!, 'error');
      }
    } catch (error) {
      showToast(toastError, 'Something went wrong', 'error');
    }
  }

  const handleLoadMore = () => {
    fetchMore({
        variables: {
            input: {
                limit,
                cursor,
                from: from?.toString(),
                to: to?.toString(),
                date_filter: dateFilter,
                orderNumber
            },
        },
    });
  };
  
  const clearFilters = () => {
      setLimit('');
      setOrderNumber('');
      setFrom(undefined);
      setTo(undefined);
      setDateFilter('');
      refetch({ input: {  } });
      client.resetStore();
  };

  const handleRangeDateFilters = (
    e: { originalEvent: Event; value: Date },
    filter: string,
  ) => {
    if (filter === 'from') {
      setFrom(e.value);
    } else {
      setTo(e.value);
    }
    client.resetStore();
  };

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value);
    client.resetStore();
  };

  const handleDateDropdownFilters = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setDateFilter(e.value);
    client.resetStore();
  };

  const handleSearchOrderNumber = (e: FormEvent<HTMLInputElement>) => {
    setOrderNumber(e.currentTarget.value);
    refetch({ input: { orderNumber: e.currentTarget.value } });
    client.resetStore();
  }

  const tableHeader = (
    <div className="p-grid">
      <Input icon={<AiOutlineNumber color="#01c0f8" />} columnSize="p-col-3" change={handleSearchOrderNumber} name="search" value={orderNumber} placeholder="Receipt Number" />
      <Calendar
        columnSize="p-col-3"
        placeholder="From"
        value={from}
        handleSelect={(e) => handleRangeDateFilters(e, 'from')}
      />
      <Calendar
        columnSize="p-col-3"
        placeholder="To"
        value={to}
        handleSelect={(e) => handleRangeDateFilters(e, 'to')}
      />
       <Dropdown
          columnSize="p-col-3"
          icon={<MdToday color="#01c0f8" />}
          placeholder="Pick a date filter"
          value={dateFilter}
          handleChange={handleDateDropdownFilters}
          name="dateFilter"
          options={dateFilterOptions}
          optionLabel="label"
          optionValue="value"
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  );

  return loading ? (
    <Spinner />
  ) : (
      <>
        <DataTableWrapper>
        <Toast ref={toastSuccess} />
        <Toast ref={toastError} />
        <DataTable emptyMessage="No records found." value={data?.getOrderList.orderList} header={tableHeader}>
          <Column sortable field="orderNumber" header="Receipt Number"></Column>
          <Column
            sortable
            field="date"
            header="Date"
            body={(x: OrderList) => format(Number(x.date), 'Y-MM-dd')}
          ></Column>
          <Column
            field="total"
            header="Total"
            body={(x: OrderList) => currencyFormat(x.total)}
          ></Column>
          <Column field="cashier" header="Cashier"></Column>
          <Column
            body={(data: OrderList) => (data.void ? 'True' : 'False')}
            header="Void"
          ></Column>
          <Column
            header="Action"
            body={(data: OrderList) => (
              <>
                <Button
                  className="p-button-text p-button-sm"
                  tooltip="Return Sale"
                  tooltipOptions={{ position: 'bottom' }}
                  icon="pi pi-undo"
                  onClick={() =>
                    history.push(`/sales/return/${data.orderNumber}`)
                  }
                />
                {/* <Button
                  className="p-button-text p-button-sm"
                  tooltip="Void Sale"
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ marginLeft: '5px' }}
                  icon="pi pi-eject"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    confirmPopup({
                      target: e.currentTarget,
                      message: 'Are you sure you want to void the sale?',
                      icon: 'pi pi-exclamation-triangle',
                      accept: () => handleVoid(data.id, data.void),
                      reject: () => false,
                    })
                  }}
                /> */}
                <Button
                  tooltip="Delete Receipt"
                  tooltipOptions={{ position: 'bottom' }}
                  className="p-button-text p-button-sm"
                  style={{ marginLeft: '5px' }}
                  icon="pi pi-times"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    confirmPopup({
                      target: e.currentTarget,
                      message: 'Are you sure you want to delete the receipt?',
                      icon: 'pi pi-exclamation-triangle',
                      accept: () => handleDelete(data.id),
                      reject: () => false,
                    })
                  }}
                />
              </>
            )}
          ></Column>
          </DataTable>
        </DataTableWrapper>
        <div
              style={{
                  position: 'relative',
                  width: '100%',
                  marginTop: '2rem',
              }}
          >
            <div className="p-d-flex p-jc-between">
                <Button onClick={clearFilters} type="button" className="p-button-sm">
                    {loading ? 'Loading' : 'Clear Filters'}
                </Button>
                <Button
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                    className="p-button-sm"
                >
                    {loading ? 'Loading' : 'Load More'}
                </Button>
            </div>
          </div>
      </>
  )
}

export { ReceiptList }
