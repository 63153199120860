import { FC } from 'react';
import styled from 'styled-components';

interface HrProps {
  width: string;
  margin?: string;
}

const Hr = styled.div<HrProps>`
  height: 1px;
  background: #868686;
  opacity: 0.6;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

export const Line: FC<HrProps> = ({ width, margin }) => (
  <Hr width={width} margin={margin} />
);
