import { FC, useEffect, useState } from 'react';
import { SalesReport as Sale, useGetBrandsQuery, useGetProductCountriesQuery, useGetSalesQuery, useGetSupplierListQuery, useGetUniqueProductCodesQuery, useGetUniqueProductColorsQuery, useGetUniqueProductNamesQuery, useGetUniqueProductSizesQuery } from '../../generated/graphql';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { currencyFormat } from '../../helpers/currencyFormat';
import { AutoComplete, Button, Calendar, Dropdown } from '../../components/form';
import { AiOutlineNumber } from 'react-icons/ai';
import { SalesDatatableWrapper } from '../../components/utilities/SalesDatatableWrapper';
import { MdToday } from 'react-icons/md';
import { Spinner } from '../../components';
import { BiSearchAlt } from 'react-icons/bi';
import { arrayUnique } from '../../helpers/arrayUnique';
import { addHours, addMinutes, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/rootReducer';
import { AuthState } from '../../redux/auth/types';
import { can } from '../../helpers/hasPermission';

const SalesReport: FC = () => {
    const dateFilterOptions = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'thisWeek', label: 'This Week' },
        { value: 'thisMonth', label: 'This Month' },
        { value: 'thisYear', label: 'This Year' },
      ]
    const limitOptions = [
        { value: '15', label: '15' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '75', label: '75' },
        { value: '100', label: '100' },
    ]
    const [cursor, setCursor] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();
    const [limit, setLimit] = useState('');
    const [method, setMethod] = useState('');
    const [supplier, setSupplier] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const { loading: loadingSuppliers, data: suppliers } = useGetSupplierListQuery();
    const { data: names } = useGetUniqueProductNamesQuery();
    const [name, setName] = useState('');
    const [filteredNames, setFilteredNames] = useState<string[]>();
    const { data: colors } = useGetUniqueProductColorsQuery();
    const [size, setSize] = useState('');
    const [filteredColors, setFilteredColors] = useState<string[]>();
    const { data: sizes } = useGetUniqueProductSizesQuery();
    const [color, setColor] = useState('');
    const [filteredSizes, setFilteredSizes] = useState<string[]>();
    const { data: brands } = useGetBrandsQuery();
    const [filteredBrands, setFilteredBrands] = useState<string[]>();
    const [brand, setBrand] = useState('');
    const [filteredCodes, setFilteredCodes] = useState<string[]>();
    const [filteredCountries, setFilteredCountries] = useState<string[]>();
    const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>();
    const { data: codes } = useGetUniqueProductCodesQuery();
    const { data: countries } = useGetProductCountriesQuery();
    const [code, setCode] = useState('')
    const [country, setCountry] = useState('')
    const { user } = useSelector<AppState, AuthState>(state => state.auth);

    const { loading, data, client, fetchMore } = useGetSalesQuery({
        variables: {
            input: {
                limit,
                from: from ? format(from, 'Y-MM-dd') : '',
                to: to ? format(to, 'Y-MM-dd') : '',
                date_filter: dateFilter,
                method,
                supplier,
                brand,
                code,
                country,
                name,
                color,
                size
            }
        }
    });

    useEffect(() => {
        setHasMore(data?.getSales.hasMore!)
        if (data?.getSales.hasMore) {
            const sales = data.getSales.sales || [];
            if (sales.length > 0) {
                setCursor(sales[sales.length - 1].id.toString());
            }
        }
    }, [loading, data?.getSales.sales]);

    const handleRangeDateFilters = (
        e: { originalEvent: Event; value: Date },
        filter: string,
    ) => {
        if (filter === 'from') {
            setFrom(e.value);
        } else {
            setTo(e.value);
        }
        client.resetStore();
    };
    
    const handleDateDropdownFilters = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setDateFilter(e.value);
        client.resetStore();
    };

    const handleLimit = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setLimit(e.value);
        client.resetStore();
    };

    const handleMethod = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setMethod(e.value);
        client.resetStore();
    };

    const handleSupplier = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setSupplier(e.value);
        client.resetStore();
    };

    const calculateProfitPercentage = (data: Sale) => {
        const profit = (data.sellingPrice - data.discount - data.purchasePrice) * (data.totalQuantity - data.returnQuantity);
        const sale = (data.sellingPrice - data.discount) * (data.totalQuantity - data.returnQuantity);
        const percentage = (profit / sale) * 100
        if (isNaN(percentage)) {
            return 0;
       }
        return (profit / sale) * 100; 
    }

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                input: {
                    limit,
                    cursor,
                    from: from?.toString(),
                    to: to?.toString(),
                    date_filter: dateFilter,
                    method,
                    supplier,
                    brand,
                    code,
                    country,
                    color,
                    name,
                    size
                },
            },
        });
    };

    const clearFilters = () => {
        setLimit('');
        setMethod('');
        setFrom(undefined);
        setTo(undefined);
        setDateFilter('');
        setSupplier('');
        setBrand('');
        setColor('');
        setSize('');
        setName('');
        setCode('');
        setCountry('');
        client.resetStore();
    };

    useEffect(() => {
        clearFilters();
    }, []);

    const searchBrand = (e: { originalEvent: Event; query: string }) => {
        let filteredBrands: string[]
        filteredBrands =
          brands?.getBrands.map(b => b.name).filter((b) => {
            return b.toLowerCase().includes(e.query.toLowerCase())
          }) || [];
        setFilteredBrands(arrayUnique(filteredBrands))
    }

    const searchCode = (e: { originalEvent: Event; query: string }) => {
        let filteredCodes: string[]
        filteredCodes =
          codes?.getUniqueProductCodes.filter((p) => {
            return p.toLowerCase().startsWith(e.query.toLowerCase())
          }) || []
        setFilteredCodes(arrayUnique(filteredCodes))
    }

    const searchCountry = (e: { originalEvent: Event; query: string }) => {
        let filteredCountries: string[]
        filteredCountries =
          countries?.getUniqueProductCountries.filter((p) => {
            return p.toLowerCase().startsWith(e.query.toLowerCase())
          }) || []
        setFilteredCountries(arrayUnique(filteredCountries))
    }

    const searchColor = (e: { originalEvent: Event; query: string }) => {
        let filteredColors: string[]
        filteredColors =
          colors?.getUniqueProductColors.filter((p) => {
            return p.toLowerCase().startsWith(e.query.toLowerCase())
          }) || []
        setFilteredColors(arrayUnique(filteredColors))
    }

    const searchName = (e: { originalEvent: Event; query: string }) => {
        let filteredNames: string[]
        filteredNames =
          names?.getUniqueProductNames.filter((p) => {
            return p.toLowerCase().includes(e.query.toLowerCase())
          }) || []
        setFilteredNames(arrayUnique(filteredNames))
    }

    const searchSupplier = (e: { originalEvent: Event; query: string }) => {
        let filteredSuppliers: string[]
        filteredSuppliers =
          suppliers?.getSupplierList.filter((c) => {
            return c.name.toLowerCase().includes(e.query.toLowerCase())
          }).map(c => c.name) || []
        setFilteredSuppliers(arrayUnique(filteredSuppliers))
      }
    
    const searchSize = (e: { originalEvent: Event; query: string }) => {
        let filteredSizes: string[]
        filteredSizes =
          sizes?.getUniqueProductSizes.filter((p) => {
            return p.toLowerCase().includes(e.query.toLowerCase())
          }) || []
        setFilteredSizes(arrayUnique(filteredSizes))
    }
    
    const handleAutoCompleteBrand = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setBrand(e.value)
    }

    const handleAutoCompleteCode = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setCode(e.value)
    }

    const handleAutoCompleteCountry = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setCountry(e.value)
    }

    const handleAutoCompleteColor = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setColor(e.value)
    }

    const handleAutoCompleteSize = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setSize(e.value)
    }

    const handleAutoCompleteSupplier = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setSupplier(suppliers?.getSupplierList.find(s => s.name === e.value)?.id ?? '');
      }
    
    const handleAutoCompleteName = (e: {
        originalEvent: Event
        value: string
        target: {
          name: string
          id: string
          value: any
        }
      }) => {
        setName(e.value)
    }

    const handleAutoCompleteSelect = () => {
        client.resetStore()
    }

    const tableHeader = (
        <div className="p-grid p-mb-1">
            <Calendar
                columnSize="p-col-3"
                placeholder="From"
                value={from}
                handleSelect={(e) => handleRangeDateFilters(e, 'from')}
            />
            <Calendar
                columnSize="p-col-3"
                placeholder="To"
                value={to}
                handleSelect={(e) => handleRangeDateFilters(e, 'to')}
            />
            <Dropdown
                columnSize="p-col-3"
                icon={<MdToday color="#01c0f8" />}
                placeholder="Pick a date"
                value={dateFilter}
                handleChange={handleDateDropdownFilters}
                name="dateFilter"
                options={dateFilterOptions}
                optionLabel="label"
                optionValue="value"
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search supplier"
                value={suppliers?.getSupplierList.find(s => s.id === supplier)?.name ?? ''}
                handleCompleteMethod={searchSupplier}
                suggestions={filteredSuppliers}
                handleChange={handleAutoCompleteSupplier}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Brand"
                value={brand}
                handleCompleteMethod={searchBrand}
                suggestions={filteredBrands}
                handleChange={handleAutoCompleteBrand}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Name"
                value={name}
                handleCompleteMethod={searchName}
                suggestions={filteredNames}
                handleChange={handleAutoCompleteName}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Code"
                value={code}
                handleCompleteMethod={searchCode}
                suggestions={filteredCodes}
                handleChange={handleAutoCompleteCode}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Country"
                value={country}
                handleCompleteMethod={searchCountry}
                suggestions={filteredCountries}
                handleChange={handleAutoCompleteCountry}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Colour"
                value={color}
                handleCompleteMethod={searchColor}
                suggestions={filteredColors}
                handleChange={handleAutoCompleteColor}
                handleSelect={handleAutoCompleteSelect}
            />
            <AutoComplete
                columnSize="p-col-3"
                icon={<BiSearchAlt color="#02c0f8" />}
                placeholder="Search Size"
                value={size}
                handleCompleteMethod={searchSize}
                suggestions={filteredSizes}
                handleChange={handleAutoCompleteSize}
                handleSelect={handleAutoCompleteSelect}
            />
            <Dropdown
                columnSize="p-col-3"
                icon={<AiOutlineNumber color="#01c0f8" />}
                placeholder="Payment Type"
                value={method}
                handleChange={handleMethod}
                name="method"
                options={[{ label: 'Cash', value: '1' }, { label: 'Card', value: '2' }]}
                optionLabel="label"
                optionValue="value"
            />
            <Dropdown
                columnSize="p-col-3"
                icon={<AiOutlineNumber color="#01c0f8" />}
                placeholder="Record Limit"
                value={limit}
                handleChange={handleLimit}
                name="limit"
                options={limitOptions}
                optionLabel="label"
                optionValue="value"
            />
        </div>
    );

    const getSupplierName = (id: number) => !loadingSuppliers && suppliers?.getSupplierList.find(s => Number(s.id) === id)?.name;
    
    return (
        loading ? <Spinner /> : <>
            <SalesDatatableWrapper>
                <DataTable
                    value={data?.getSales.sales!} header={tableHeader}
                    rowGroupMode="rowspan"
                    groupField="orderNumber"
                    sortMode="single"
                    sortField="orderNumber"
                    emptyMessage="No records"
                >
                    <Column style={{ fontSize: '13px' }} header="Date" body={(data: Sale) => format(Number(data.date), 'Y-MM-dd')} />
                    {/* <Column style={{ fontSize: '13px' }} header="Time" body={(data: Sale) => format(Number(addMinutes(addHours(Number(data?.date_time), 5), 30)), 'hh:mm a')} /> */}
                    <Column style={{ fontSize: '13px' }} header="Time" body={(data: Sale) => format(Number(data?.date_time), 'hh:mm a')} />
                    <Column style={{ fontSize: '13px' }} field="orderNumber" header="Receipt" />
                    <Column style={{ fontSize: '13px' }} field="supplierId" header="Supplier" body={(data: Sale) => getSupplierName(data.supplierId)} />
                    <Column style={{ fontSize: '13px' }} field="brandName" header="Brand" />
                    <Column style={{ fontSize: '13px' }} field="country" header="Country" />
                    <Column style={{ fontSize: '13px' }} field="productName" header="Product" />
                    <Column style={{ fontSize: '13px' }} field="code" header="Code" />
                    <Column style={{ fontSize: '13px' }} field="color" header="Colour" />
                    <Column style={{ fontSize: '13px' }} field="size" header="Size" />
                    <Column style={{ fontSize: '13px' }} header="Type" body={(data: Sale) => data.isReturn ? <span style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        background: '#feedaf',
                        color: '#8a5340',
                        borderRadius: '2px',
                        padding: '.1em .3rem',
                        letterSpacing: '.3px',
                        textTransform: 'uppercase',
                    }}>Return</span> :
                    <span style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        background: '#c8e6c9',
                        color: '#256029',
                        borderRadius: '2px',
                        padding: '.1em .3rem',
                        letterSpacing: '.3px',
                        textTransform: 'uppercase',
                    }}>Normal</span>}
                    />
                    <Column style={{ fontSize: '13px' }} header="Price" body={(data: Sale) => currencyFormat(data.sellingPrice)} />
                    <Column style={{ fontSize: '13px' }} header="Quantity" body={(data: Sale) => data.totalQuantity} />
                    <Column style={{ fontSize: '13px' }} header="Return" body={(data: Sale) => data.returnQuantity} />
                    <Column style={{ fontSize: '13px' }} header="Discount" body={(data: Sale) => currencyFormat(data.discount)} />
                    <Column style={{ fontSize: '13px' }} header="Sale" body={(data: Sale) => currencyFormat((data.sellingPrice - data.discount) * data.totalQuantity)} />
                    {/* <Column style={{ fontSize: '13px' }} header="Net Value" body={(data: Sale) => currencyFormat((data.sellingPrice - data.discount) * (data.totalQuantity - data.returnQuantity))} /> */}
                    {can('view-profit', user?.role.permissions) && <Column style={{ fontSize: '13px' }} header="Profit" body={(data: Sale) => currencyFormat((data.sellingPrice - data.discount - data.purchasePrice) * (data.totalQuantity - data.returnQuantity))} />}
                    {can('view-profit', user?.role.permissions) && <Column style={{ fontSize: '13px' }} header="Profit (%)" body={(data: Sale) => currencyFormat(calculateProfitPercentage(data))} />}
                </DataTable>
            </SalesDatatableWrapper>
            <div className="p-d-flex p-jc-between">
                <div className="p-mt-4">Total Sales: {currencyFormat(data?.getSales.totalSale!)}</div>
                {can('view-profit', user?.role.permissions) && <div className="p-mt-4 p-ml-4">Total Profit: {currencyFormat(data?.getSales.totalProfit!)}</div>}
            </div>
            <div style={{ position: 'relative', width: '100%', marginTop: '2rem'}}>
                <div className="p-d-flex p-jc-between">
                <Button onClick={clearFilters} type="button" className="p-button-sm">
                    {loading ? 'Loading' : 'Clear Filters'}
                </Button>
                <Button
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                    className="p-button-sm"
                >
                    {loading ? 'Loading' : 'Load More'}
                </Button>
                </div>
            </div>
        </>
    )
}

export { SalesReport };