import { FC } from 'react'
import styled from 'styled-components'
import { Dashboard } from '../pages/Dashboard'
import { Line } from './utilities'
import {
  NewSupplier,
  SupplierList,
  Outstanding,
  SupplierPayment,
  ChequePayment
} from '../pages/supplier'
import {
  AddStock,
  Availability,
  BrandList,
  CategoryList,
  ProductVariationList,
  ProductVariationTypeList,
  StockQuantity,
  Variance,
} from '../pages/inventory'
import {
  CreateReceipt,
  ReceipExchangetList,
  ReceiptList,
  ReceiptPreview,
  ReturnReceipt,
  ReturnPreview,
  SalesReturn,
  ExchangeReceiptPreview,
  SalesReport,
  SalesExport,
} from '../pages/sales'
import { AddCustomer, CustomerList } from '../pages/customer'
import { AuthRoute } from './'
import { CreateUser } from '../pages/security/user/CreateUser'
import { UserList } from '../pages/security/user/UserList'
import { useSelector } from 'react-redux'
import { AppState } from '../redux/rootReducer'
import { AuthState } from '../redux/auth/types'
import { PaymentHistory } from '../pages/supplier/PaymentHistory'
import { SupplierReturn } from '../pages/supplier/SupplierReturn'
import { CreateRole } from '../pages/security/role/CreateRole'
import { RoleList } from '../pages/security/role/RoleList'
import StockSettlement from '../pages/supplier/StockSettlement'
import { SalesTarget } from '../pages/sales/SalesTarget'
import { StockValue } from '../pages/inventory/StockValue'
import { TestSales } from '../pages/sales/TestSalesReport'
import { StockInvoiceList } from '../pages/inventory/StockInvoiceList'
import { InvoicePreview } from './inventory/InvoicePreview'

const ComponentView = styled.div`
  color: #f4f4fc;
  flex: 1;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  background: rgba(45, 45, 51, 0.45);
  backdrop-filter: blur(40px);
  border: 1px solid rgba(134, 134, 134, 0.4);
  @media print {
    border: none;
  }
`

const ComponentViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const AppBar = styled.div`
  @media print {
    display: none;
  }
  width: 100%;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WelcomeMessage = styled.h1`
  font-weight: 100;
  font-size: 1.8rem;
`

const ProfileButton = styled.div`
  padding: 0.5rem 1.4rem;
  color: #f4f4fc;
`

const RouteView = styled.div`
  padding: 1.3rem 3rem 3rem 3rem;
  max-height: 85vh;
  overflow-y: scroll;
`

const View: FC = () => {
  const auth = useSelector<AppState, AuthState>((state) => state.auth)
  return (
    <ComponentView>
      <ComponentViewWrapper>
        <AppBar>
          <WelcomeMessage>Welcome, {auth.user?.firstName}</WelcomeMessage>
          <ProfileButton>{auth.user?.role.name}</ProfileButton>
        </AppBar>
        <Line width="95%" margin="0 2rem" />
        <RouteView>
          <AuthRoute path="/" exact component={Dashboard} />
          <AuthRoute path="/supplier/new" exact component={NewSupplier} />
          <AuthRoute path="/supplier/list" exact component={SupplierList} />
          <AuthRoute
            path="/supplier/payment"
            exact
            component={SupplierPayment}
          />
           <AuthRoute
            path="/supplier/cheque-payment"
            exact
            component={ChequePayment}
          />
          <AuthRoute
            path="/supplier/outstanding"
            exact
            component={Outstanding}
          />
          <AuthRoute
            path="/supplier/:id/history/payment"
            exact
            component={PaymentHistory}
          />
          <AuthRoute
            path="/supplier/:id/history/settlement"
            exact
            component={StockSettlement}
          />
          <AuthRoute
            path="/supplier/:id/return"
            exact
            component={SupplierReturn}
          />
          <AuthRoute
            path="/inventory/category/list"
            exact
            component={CategoryList}
          />
          <AuthRoute path="/inventory/brand/list" exact component={BrandList} />
          <AuthRoute
            path="/inventory/main-product/list"
            exact
            component={ProductVariationTypeList}
          />
          <AuthRoute
            path="/inventory/sub-product/list"
            exact
            component={ProductVariationList}
          />
          <AuthRoute path="/inventory/add" component={AddStock} />
          <AuthRoute
            path="/inventory/availability"
            exact
            component={Availability}
          />
           <AuthRoute
            path="/inventory/value"
            exact
            component={StockValue}
          />
          <AuthRoute
            path="/inventory/invoice"
            exact
            component={StockInvoiceList}
          />
           <AuthRoute
            path="/inventory/invoice/print/:stockId"
            exact
            component={InvoicePreview}
          />
          <AuthRoute path="/inventory/variance" component={Variance} />
          <AuthRoute path="/inventory/stock-quantity" component={StockQuantity} />
          <AuthRoute
            path="/sales/receipt/create"
            exact
            component={CreateReceipt}
          />
          <AuthRoute path="/sales/receipt/list" exact component={ReceiptList} />
          <AuthRoute
            path="/sales/exchange/list"
            exact
            component={ReceipExchangetList}
          />
          <AuthRoute
            path="/sales/exchange/receipt/preview/:id"
            exact
            component={ExchangeReceiptPreview}
          />
          <AuthRoute
            path="/sales/receipt/preview/:id"
            exact
            component={ReceiptPreview}
          />
          <AuthRoute path="/sales/return/:id" exact component={SalesReturn} />
          <AuthRoute
            path="/sales/return/receipt/create/:id"
            exact
            component={ReturnReceipt}
          />
          <AuthRoute
            path="/sales/return/receipt/preview/:id"
            exact
            component={ReturnPreview}
          />
          <AuthRoute
            path="/sales/export"
            exact
            component={SalesExport}
          />
          <AuthRoute path="/sales/report" exact component={SalesReport} />
          <AuthRoute path="/sales/test-report" exact component={TestSales} />
          <AuthRoute path="/sales/target" exact component={SalesTarget} />
          <AuthRoute path="/customer/create" exact component={AddCustomer} />
          <AuthRoute path="/customer/list" exact component={CustomerList} />
          <AuthRoute
            path="/security/user/create"
            exact
            component={CreateUser}
          />
          <AuthRoute path="/security/user/list" exact component={UserList} />
          <AuthRoute
            path="/security/role/create"
            exact
            component={CreateRole}
          />
          <AuthRoute path="/security/role/list" exact component={RoleList} />
        </RouteView>
      </ComponentViewWrapper>
    </ComponentView>
  )
}
export { View }
