import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from 'primereact/button'
import {
  GetSupplierListDocument,
  SupplierContact,
  useUpdateSupplierMutation,
} from '../../generated/graphql'
import { SupplierType } from '../../pages/supplier/SupplierList'
import { Input } from '../form'
import { FaUser } from 'react-icons/fa'
import { ImLocation2 } from 'react-icons/im'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { HiPhone } from 'react-icons/hi'

interface EditSupplierProps {
  supplier?: SupplierType
  setShowEdit?: Dispatch<SetStateAction<boolean>>
}

interface AddSupplierState {
  name: string
  address?: string
  contacts?: SupplierContact[]
  newContacts?: string[]
  creditPeriod?: string
}

const initialState: AddSupplierState = {
  name: '',
  address: '',
  contacts: [],
  newContacts: [],
  creditPeriod: '',
}

const EditSupplier: FC<EditSupplierProps> = ({ supplier, setShowEdit }) => {
  const [form, setForm] = useState<AddSupplierState>(initialState)
  const [contacts, setContacts] = useState<SupplierContact[] | undefined>([])
  const [newContacts, setNewContacts] = useState<string[]>([])
  const [updateSupplier] = useUpdateSupplierMutation()

  useEffect(() => {
    setForm((f) => ({
      ...f,
      name: supplier?.name,
      address: supplier?.address,
      contacts: supplier?.supplierContacts,
      creditPeriod: supplier?.creditPeriod,
    }))
    setContacts(supplier?.supplierContacts)
  }, [supplier, setContacts])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setForm({ ...form, [name]: value })
  }

  const handleContactChange = (
    e: FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (contacts) {
      const c = [...contacts]
      const cObject = { ...c[index] }
      cObject.contact = e.currentTarget.value
      c[index] = cObject
      setContacts(c)
    }
  }

  const removeContact = (index: number) => {
    if (contacts) {
      const c = [...contacts]
      c.splice(index, 1)
      setContacts(c)
    }
  }

  const removenewContact = (index: number) => {
    if (newContacts) {
      const c = [...newContacts]
      c.splice(index, 1)
      setNewContacts(c)
    }
  }

  const handleAddNumber = () => {
    const c = [...newContacts]
    c.push('')
    setNewContacts(c)
  }

  const handleNewContactChange = (
    e: FormEvent<HTMLInputElement>,
    i: number,
  ) => {
    const c = [...newContacts]
    c[i] = e.currentTarget.value
    setNewContacts(c)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const f = { ...form }
    f.contacts = contacts?.map((c) => ({ id: c.id, contact: c.contact }))
    await updateSupplier({
      variables: {
        input: {
          id: Number(supplier?.id),
          address: f.address,
          contacts: f.contacts,
          name: f.name,
          newContacts,
          creditPeriod: Number(f.creditPeriod),
        },
      },
      refetchQueries: [{ query: GetSupplierListDocument }],
    })
    if (setShowEdit) setShowEdit(false)
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Input
          label="Name"
          icon={<FaUser />}
          value={form.name}
          name="name"
          placeholder="Name"
          change={handleChange}
        />
        <Input
          label="Address"
          icon={<ImLocation2 />}
          value={form.address}
          name="address"
          placeholder="Address"
          change={handleChange}
        />
        <Input
          label="Credit Period"
          icon={<AiOutlineFieldTime />}
          value={form.creditPeriod}
          name="creditPeriod"
          placeholder="Credit Period"
          type="number"
          change={handleChange}
        />
        {contacts?.map((c, index: number) => (
          <div key={index}>
            <div>
              <Input
                label="Phone Number"
                icon={<HiPhone />}
                value={c.contact}
                max={13}
                name="contact"
                placeholder="Phone Number"
                change={(e) => handleContactChange(e, index)}
              />
              <Button
                tooltip="Remove"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-times"
                className="p-button-text p-button-sm"
                onClick={() => removeContact(index)}
                type="button"
              />
            </div>
          </div>
        ))}
        {newContacts.length > 0 &&
          newContacts.map((c, i) => (
            <div key={i}>
              <Input
                label="Phone Number"
                icon={<HiPhone />}
                value={c}
                max={13}
                name="newContact"
                placeholder="Phone Number"
                change={(e) => handleNewContactChange(e, i)}
              />
              <Button
                tooltip="Update Role"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-times"
                className="p-button-text p-button-sm"
                onClick={() => removenewContact(i)}
                type="button"
              />
            </div>
          ))}
        <div className="p-gird p-text-right">
          <div className="p-col-1 p-offset-11">
            <Button
              tooltip="Add"
              tooltipOptions={{ position: 'bottom' }}
              icon="pi pi-plus"
              className="p-button-text p-button-sm"
              onClick={handleAddNumber}
              type="button"
            />
          </div>
        </div>
        <div className="p-grid p-text-right p-mt-2">
          <div className="p-col-3 p-offset-9">
            <Button className="button p-text-uppercase" type="submit">
              Update
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export { EditSupplier }
