import { FC, FormEvent, useEffect, useState } from 'react'
import {
  useGetReturnReceiptNumberQuery,
  GetReturnReceiptNumberDocument,
  useGetReturnedItemsQuery,
  useGetOrderMutation,
  Item,
  useCreateExchangeReceiptMutation,
  OrderItem,
  GetReturnedItemsDocument,
  GetExchangeReceiptsDocument,
  GetOrderResponse,
} from '../../generated/graphql'
import { RouteComponentProps } from 'react-router-dom'
import { currencyFormat } from '../../helpers/currencyFormat'
import { Button, Input } from '../../components/form'
import { format } from 'date-fns'
import { pluralize } from '../../helpers/pluralize'
import { AiOutlineNumber } from 'react-icons/ai'

const SalesReturn: FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const [orderData, setOrderData] = useState<GetOrderResponse>()
  const [oldItems, setOldItems] = useState<Item[]>([])
  const [filteredOldItems, setFilteredOldItems] = useState<Item[]>([])
  const [removedItems, setRemovedItems] = useState<OrderItem[]>([])
  const [getOrder, { loading: loadingOrder }] = useGetOrderMutation()
  const [storeCredit, setStoreCredit] = useState<number>(0)
  const { data: returnReceiptNumber } = useGetReturnReceiptNumberQuery()
  const { data: returnedItems } = useGetReturnedItemsQuery({
    variables: { input: Number(match.params.id) },
  })
  const [createExchageReceipt] = useCreateExchangeReceiptMutation()
  const [returnQuantityErrors, setReturnQuantityErrors] = useState<
    { id: number; message: string }[]
  >([])
  const [returnStockErrors, setReturnStockErrors] = useState<
    { id: number; message: string }[]
  >([])

  useEffect(() => {
    async function fetchOrder() {
      const { data } = await getOrder({ variables: { input: {orderNumber: Number(match.params.id), isReturn: 1 } } });
      setOldItems(data?.getOrder.items!);
      setOrderData(data?.getOrder as GetOrderResponse)
    }
    fetchOrder();
  }, [match.params.id]);

  const handleReturnQuantity = (e: FormEvent<HTMLInputElement>, i: Item) => {
    if (i.quantity < Number(e.currentTarget.value)) {
      setReturnQuantityErrors((s) => [
        ...s,
        {
          id: i.id,
          message: `You can only return up to ${i.quantity} ${pluralize(
            i.quantity,
            'item',
          )}`,
        },
      ])
      return
    }
    const findErrorIndex = returnQuantityErrors.findIndex((e) => e.id === i.id)
    if (findErrorIndex !== -1) {
      const errorState = [...returnQuantityErrors]
      errorState.splice(findErrorIndex, 1)
      setReturnQuantityErrors(errorState)
    }
    const stateRemovedItems = [...removedItems];
    const item = { ...i }
    const findItemIndex = stateRemovedItems.length > 0 ? stateRemovedItems.findIndex(
      (item) => item.productVariationId === i.productVariationId,
    ) : -1;
    if (findItemIndex === -1) {
      item.quantity = Number(e.currentTarget.value)
      if (e.currentTarget.value === '') {
        item.quantity = 0
      }
      stateRemovedItems.push({
        id: item.id,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        discountType: item.discountType,
        addToStock: 0,
        productVariationId: item.productVariationId
      })
      setRemovedItems(stateRemovedItems)
    } else {
      if (e.currentTarget.value === '' || e.currentTarget.value === '0') {
        stateRemovedItems.splice(findItemIndex, 1);
      }
      setRemovedItems(stateRemovedItems);
    }
  }

  const isQuantityEmpty = () => {
    let isQuantityEmpty = true;
    for (let ri of removedItems) {
      if (Number(ri?.quantity) !== 0) {
        isQuantityEmpty = false;
        break;
      }
    }
    return isQuantityEmpty;
  }

  const handleStockQuantity = (e: FormEvent<HTMLInputElement>, i: Item) => {
    const stockQuantity = Number(e.currentTarget.value)
    const removedItemsState = [...removedItems]
    const findIndex = removedItemsState.findIndex((ri) => ri.id === i.id)
    if (!removedItemsState[findIndex]?.quantity) return;
    if (removedItemsState[findIndex].quantity < stockQuantity) {
      setReturnStockErrors((s) => [
        ...s,
        {
          id: i.id,
          message: `You can only return up to ${
            removedItemsState[findIndex].quantity
          } ${pluralize(removedItemsState[findIndex].quantity, 'item')}`,
        },
      ])
      return
    }
    const findErrorIndex = returnStockErrors.findIndex((e) => e.id === i.id)
    if (findErrorIndex !== -1) {
      const errorState = [...returnStockErrors]
      errorState.splice(findErrorIndex, 1)
      setReturnStockErrors(errorState)
    }
    removedItemsState[findIndex].addToStock = stockQuantity
    setRemovedItems(removedItemsState)
  }

  const handleSubmit = async () => {
    if (returnQuantityErrors.length > 0 || returnStockErrors.length > 0) return;
    const { data } = await createExchageReceipt({
      variables: {
        input: {
          date: format(Date.now(), 'Y-MM-d'),
          returnItems: removedItems,
          returnReceiptNumber: returnReceiptNumber?.getReturnReceiptNumber!,
          returnOrderId: orderData?.order?.id!,
          branchId: orderData?.order?.branch.id!,
        },
      },
      refetchQueries: [
        {
          query: GetReturnReceiptNumberDocument,
        },
        {
          query: GetReturnedItemsDocument,
          variables: { input: Number(match.params.id) },
        },
        {
          query: GetExchangeReceiptsDocument,
        },
      ],
    })
    if (data?.createExchangeReceipt?.returnReceiptNumber) {
      history.replace(
        `/sales/exchange/receipt/preview/${data.createExchangeReceipt.returnReceiptNumber}`,
      )
    }
  }

  useEffect(() => {
    const returnableItems: Item[] = []
    oldItems.forEach((o) => {
      const item = returnedItems?.getReturnedItems.find((i) => i.id === o.productVariationId)
      if (!item) {
        returnableItems.push(o)
      } else {
        if (o.quantity > item.quantity) {
          returnableItems.push({ ...o, quantity: o.quantity - item.quantity })
        }
      }
    })
    setFilteredOldItems(returnableItems)
  }, [oldItems, setFilteredOldItems, returnedItems])

  useEffect(() => {
    setStoreCredit(
      removedItems.reduce(
        (total: number, currentValue: OrderItem) =>
          total +
          (currentValue.price -
            (currentValue.discountType! === 2
              ? (currentValue.price * currentValue.discount!) / 100
              : currentValue.discount!)) *
            currentValue.quantity,
        0,
      ),
    )
  }, [removedItems])
  return (
    <>
      {loadingOrder ? (
        'Loading receipt...'
      ) : filteredOldItems.length === 0 ? (
        'No items to return'
      ) : (
        <div style={{ padding: '2rem 0' }}>
            <div className="p-grid">
              <div className="p-col p-text-center">Stock Date</div>
              <div className="p-col p-text-center">Brand</div>
              <div className="p-col p-text-center">Code</div>
              <div className="p-col p-text-center">Name</div>
              <div className="p-col p-text-center">Quantity</div>
              <div className="p-col p-text-center">Return Quantity</div>
              <div className="p-col p-text-center">Add to Stock</div>
          </div>
          {filteredOldItems.map((i) => (
            <div
              key={i.id}
              className="p-d-flex p-ai-center"
              style={{
                margin: '.8rem 0',
                textAlign: 'center',
              }}
            >
              <div className="p-col">{i.stockDate}</div>
              <div className="p-col">{i.brand}</div>
              <div className="p-col">{i.code}</div>
              <div className="p-col">{i.name}</div>
              <div className="p-col">{i.quantity}</div>
              <div className="p-col">
                <Input
                  icon={<AiOutlineNumber color="#02c0f8" />}
                  name="returnQuantity"
                  placeholder="Quantity"
                  style={{ textAlign: 'center' }}
                  minNumber={1}
                  maxNumber={i.quantity}
                  change={(e) => handleReturnQuantity(e, i)}
                  errorMsg={
                    returnQuantityErrors.find((e) => e.id === i.id)?.message
                  }
                />
              </div>
              <div className="p-col">
                  <Input
                    icon={<AiOutlineNumber color="#02c0f8" />}
                    name="stockQuantity"
                    disabled={removedItems.length === 0}
                    placeholder="Quantity"
                    style={{ textAlign: 'center' }}
                    minNumber={1}
                    maxNumber={i.quantity}
                    change={(e) => handleStockQuantity(e, i)}
                    errorMsg={
                      returnStockErrors.find((e) => e.id === i.id)?.message
                    }
                  />
              </div>
            </div>
          ))}
          <div
            className="p-grid"
            style={{
              marginTop: '4rem',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            <div className="p-col">&nbsp;</div>
            <div className="p-col">&nbsp;</div>
            <div className="p-col grid-table-header grid-table-total">
              <span style={{ marginLeft: '6rem', fontWeight: 'normal' }}>
                Return Total
              </span>
            </div>
            <div className="p-col grid-table-header grid-table-value">
              <span style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
                {currencyFormat(storeCredit)}
              </span>
            </div>
          </div>
          <Button
            type="submit"
                disabled={
                  isQuantityEmpty() ||
                  returnQuantityErrors.length > 0 ||
                  returnStockErrors.length > 0
              }
            onClick={handleSubmit}
          >
            click here
          </Button>
        </div>
      )}
    </>
  )
}

export { SalesReturn };