import { FC, FormEvent, useRef, useState } from 'react'
import { Input } from '../../components/form/Input'
import { FaUser } from 'react-icons/fa'
import { ImLocation2 } from 'react-icons/im'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { HiPhone } from 'react-icons/hi'
import { Button } from 'primereact/button'
import {
  CreateSupplierMutation,
  GetSupplierListDocument,
  GetSupplierListQuery,
  useCreateSupplierMutation,
} from '../../generated/graphql'
import { ApolloCache } from '@apollo/client'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import { Toast } from 'primereact/toast'
import { showToast } from '../../helpers/showSuccessToast'

interface AddSupplierState {
  name: string
  address?: string
  creditPeriod?: string
}

const initialState: AddSupplierState = {
  name: '',
  address: '',
  creditPeriod: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export const NewSupplier: FC = () => {
  const toastSuccess = useRef<Toast>(null)
  const toastError = useRef<Toast>(null)
  const [contacts, setContacts] = useState<string[]>([''])
  const [form, setForm] = useState<AddSupplierState>(initialState)
  const [createSupplier] = useCreateSupplierMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setForm({ ...form, [name]: value })
  }

  const handleContactChange = (
    e: FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedContacts = [...contacts]
    updatedContacts[index] = e.currentTarget.value
    setContacts(updatedContacts)
  }

  const removeContact = (index: number) => {
    const c = [...contacts]
    c.splice(index, 1)
    setContacts(c)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid(form)) {
      const { data } = await createSupplier({
        variables: {
          input: {
            name: form.name,
            address: form.address,
            creditPeriod: Number(form.creditPeriod),
            contacts,
          },
        },
        update(cache: ApolloCache<CreateSupplierMutation>, { data }) {
          const suppliers = cache.readQuery<GetSupplierListQuery>({
            query: GetSupplierListDocument,
          })
          if (suppliers && data?.createSupplier.supplier) {
            cache.writeQuery<GetSupplierListQuery>({
              query: GetSupplierListDocument,
              data: {
                getSupplierList: [
                  ...suppliers.getSupplierList,
                  data.createSupplier.supplier,
                ],
              },
            })
          }
        },
      })
      if (data?.createSupplier.supplier) {
        showToast(toastSuccess, 'Supplier has been created')
        setForm(initialState)
        setContacts([''])
      } else {
        showToast(
          toastSuccess,
          data?.createSupplier.errors![0].message!,
          'error',
        )
      }
    }
  }

  return (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <form onSubmit={handleSubmit}>
        <Input
          value={form.name}
          placeholder="Name"
          name="name"
          icon={<FaUser />}
          change={handleChange}
          errorMsg={validateField('name', errors)}
        />
        <Input
          value={form.address}
          name="address"
          placeholder="Supplier Address"
          icon={<ImLocation2 />}
          change={handleChange}
        />
        <Input
          value={form.creditPeriod}
          name="creditPeriod"
          placeholder="Credit Period"
          type="number"
          icon={<AiOutlineFieldTime />}
          change={handleChange}
        />
        {contacts.map((c, index: number) => (
          <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                value={c}
                max={13}
                name="contact"
                icon={<HiPhone />}
                placeholder="Supplier Phone Number"
                change={(e) => handleContactChange(e, index)}
              />
              {contacts.length > 1 && (
                <Button
                  tooltip="Remove"
                  tooltipOptions={{ position: 'bottom' }}
                  icon="pi pi-times"
                  className="p-button-text p-button-sm"
                  onClick={() => removeContact(index)}
                  type="button"
                />
              )}
            </div>
          </div>
        ))}
        <div className="p-col-12">
          <Button
            tooltip="Add"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-plus"
            className="p-button-text p-button-sm"
            onClick={() => setContacts((e) => [...e, ''])}
            type="button"
          />
        </div>
        <div className="p-mt-2">
          <div className="p-col-2 p-offset-10 p-text-right">
            <Button className="button p-text-uppercase" type="submit">
              Create
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}
