import { Permission } from "../generated/graphql";

export const can = (permission: string, permissions: Permission[] | null | undefined) => {
    if (permissions && permissions.length > 0) {
        const permissionIndex = permissions.findIndex(p => permission.toLocaleLowerCase() === p.name.toLocaleLowerCase());
        if (permissionIndex !== -1) {
            return true;
        }
    }
    return false;
}