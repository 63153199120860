import { useState, FC } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Spinner } from '../../components'
import {
  SupplierPayment,
  useGetSupplierListQuery,
  useSupplierPaymentHistoryQuery,
} from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat'
import { RouteComponentProps } from 'react-router-dom'
import { format } from 'date-fns'

const PaymentHistory: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [paymentHistory, setPaymentHistory] = useState<SupplierPayment[]>([])
  const { data: suppliers } = useGetSupplierListQuery()
  const { loading } = useSupplierPaymentHistoryQuery({
    variables: { input: { supplierId: Number(match.params.id) } },
    onCompleted: ({ paymentHistory }) => {
      setPaymentHistory(paymentHistory)
    },
  })

  const tableHeader = () => (
    <div className="p-d-flex p-jc-between">
      <div>{`Payment history of ${
        suppliers?.getSupplierList.find((s) => s.id === match.params.id)?.name
      }`}</div>
      <div>Date: {format(Date.now(), 'yyyy-MM-dd')}</div>
    </div>
  )

  return loading ? (
    <Spinner />
  ) : (
    <DataTable
      header={tableHeader()}
      value={paymentHistory}
      emptyMessage="No Suppliers"
      className="p-datatable-responsive"
    >
      <Column
        header="Id"
        body={(_: any, props: any) => props.rowIndex + 1}
      ></Column>
      <Column
        field="amount"
        header="Amount"
        body={(x: SupplierPayment) => currencyFormat(x.amount)}
      />
      <Column
        field="date"
        header="Date"
        body={(x: SupplierPayment) => format(Number(x.date), 'yyyy-MM-dd')}
      />
      <Column
        field="stockPaymentType.name"
        header="Payment Type"
        body={(x: SupplierPayment) =>
          x.stockPaymentType.name === 'Cash' ? (
            <span
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                background: '#b3e5fc',
                color: '#23547b',
                borderRadius: '2px',
                padding: '.25em .5rem',
                letterSpacing: '.3px',
                textTransform: 'uppercase',
              }}
            >
              Cash
            </span>
          ) : (
            <span
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                background: '#eccfff',
                color: '#694382',
                borderRadius: '2px',
                padding: '.25em .5rem',
                letterSpacing: '.3px',
                textTransform: 'uppercase',
              }}
            >
              Cheque
            </span>
          )
        }
      />
      <Column
        field="paymentInfo.cheque_number"
        header="Cheque No."
        body={(data: SupplierPayment) =>
          data.paymentInfo?.cheque_number
            ? data.paymentInfo?.cheque_number
            : '-'
        }
      />
      <Column
        field="paymentInfo.cheque_date"
        header="Cheque Date"
        body={(data: SupplierPayment) =>
          data.paymentInfo?.cheque_date ? data.paymentInfo?.cheque_date : '-'
        }
      />
      <Column
        field="paymentInfo.bank.name"
        header="Bank"
        body={(data: SupplierPayment) =>
          data.paymentInfo?.bank?.name ? data.paymentInfo?.bank?.name : '-'
        }
      />
    </DataTable>
  )
}

export { PaymentHistory }
