import { FC, FormEvent, MouseEvent, useRef, useState } from 'react'
import {
  DeleteUserMutation,
  GetUsersDocument,
  GetUsersQuery,
  UpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  User,
  useResetPasswordMutation,
  useUpdateUserMutation,
} from '../../../generated/graphql'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Spinner } from '../../../components'
import { Button } from 'primereact/button'
import { Dialog } from '../../../components/Dialog'
import { UserForm } from '../../../components/security'
import { UserState } from '../../../components/security/UserForm'
import { Toast } from 'primereact/toast'
import { showToast } from '../../../helpers/showSuccessToast'
import { ApolloCache } from '@apollo/client'
import { confirmPopup } from 'primereact/confirmpopup'
import { Input } from '../../../components/form'
import { BiKey } from 'react-icons/bi'
import * as Yup from 'yup'
import {
  ClientErrors,
  setSchemaErrors,
  validateField,
  validateSchema,
} from '../../../helpers/handleClientValidation'

const schema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
})

const UserList: FC = () => {
  const { loading, data } = useGetUsersQuery()
  const [showUpdateUser, setShowUpdateUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState<
    User & { roleId: number; branchId: number }
  >()
  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [form, setForm] = useState({ password: '' })
  const [resetPassword] = useResetPasswordMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])
  const toastSuccess = useRef(null)
  const toastError = useRef(null)

  const handleResetPasswordChange = (e: FormEvent<HTMLInputElement>) => {
    setForm({ password: e.currentTarget.value })
  }

  const handleResetPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    setSchemaErrors(schema, form, setErrors)
    if (await validateSchema(schema, form)) {
      const { data } = await resetPassword({
        variables: { id: selectedUser!.id, password: form.password },
      })
      if (data?.resetPassword.message) {
        showToast(toastSuccess, data?.resetPassword.message)
      } else {
        showToast(toastError, data?.resetPassword.errors![0].message!, 'error')
      }
    }
    setShowResetPassword(false)
    setForm({ password: '' })
  }

  const handleUpdateUser = async (form: UserState) => {
    try {
      const { data } = await updateUser({
        variables: {
          id: selectedUser?.id!,
          input: {
            username: form.username,
            firstName: form.firstName,
            lastName: form.lastName,
            roleId: Number(form.roleId),
          },
        },
        update(cache: ApolloCache<UpdateUserMutation>, { data }) {
          const cachedUsers = cache.readQuery<GetUsersQuery>({
            query: GetUsersDocument,
          })
          if (cachedUsers && data?.updateUser.user) {
            const findUserIndex = cachedUsers.getUsers.findIndex(
              (cu) => cu.id === data.updateUser.user!.id,
            )
            if (findUserIndex !== -1) {
              const usersList = [...cachedUsers.getUsers]
              usersList[findUserIndex] = data?.updateUser.user
              usersList[findUserIndex].role = data?.updateUser.user.role
              usersList[findUserIndex].branch = data?.updateUser.user.branch
              cache.writeQuery<GetUsersQuery>({
                query: GetUsersDocument,
                data: { getUsers: usersList },
              })
            }
          }
        },
      })
      if (data?.updateUser.user) {
        showToast(toastSuccess, 'User has been updated')
      } else {
        showToast(toastError, data?.updateUser.errors![0].message!, 'error')
      }
    } catch (error) {
      showToast(toastError, 'Server Error', 'error')
      console.log(error)
    }
    setShowUpdateUser(false)
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Dialog
        visible={showUpdateUser}
        header="Update User"
        handleOnHide={() => {
          setShowUpdateUser(false)
          setForm({ password: '' })
          setErrors([])
        }}
      >
        <UserForm
          buttonText="Update"
          handleSubmit={handleUpdateUser}
          user={selectedUser}
        />
      </Dialog>
      <Dialog
        visible={showResetPassword}
        header="Reset User Password"
        handleOnHide={() => setShowResetPassword(false)}
      >
        <form onSubmit={handleResetPasswordSubmit}>
          <Input
            value={form.password}
            icon={<BiKey />}
            placeholder="Enter reset password"
            name="password"
            type="password"
            change={handleResetPasswordChange}
            errorMsg={validateField('password', errors)}
          />
          <Button type="submit">Reset</Button>
        </form>
      </Dialog>
      <DataTable
        value={data?.getUsers.map((x) => ({
          id: x.id,
          username: x.username,
          firstName: x.firstName,
          lastName: x.lastName,
          approved: x.approved,
          role: x.role.name,
          branch: x.branch.name,
          roleId: x.role.id,
        }))}
        emptyMessage="No Users"
        className="p-datatable-responsive"
      >
        <Column
          header="Id"
          body={(_: any, props: any) => props.rowIndex + 1}
        ></Column>
        <Column field="username" header="Username" />
        <Column field="firstName" header="First Name" />
        <Column field="lastName" header="Last Name" />
        <Column
          field="approved"
          header="Confirmed"
          body={(data: User) => (data.approved ? 'Confirmed' : 'Unconfirmed')}
        />
        <Column field="role" header="Role" />
        <Column
          header="Action"
          body={(data: User & { roleId: number; branchId: number }) => (
            <>
              <Button
                tooltip="Update User"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-user-edit"
                className="p-button-text p-button-sm"
                type="button"
                onClick={() => {
                  setShowUpdateUser(true)
                  setSelectedUser({
                    ...data,
                    roleId: data.roleId,
                    branchId: data.branchId,
                  })
                }}
              />
              <Button
                tooltip="Delete User"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-trash"
                className="p-button-text p-button-sm"
                type="button"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  confirmPopup({
                    target: e.currentTarget,
                    message: 'Are you sure you want to delete the user?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: async () => {
                      try {
                        const response = await deleteUser({
                          variables: { id: data.id },
                          update(
                            cache: ApolloCache<DeleteUserMutation>,
                            { data },
                          ) {
                            const cachedUsers = cache.readQuery<GetUsersQuery>({
                              query: GetUsersDocument,
                            })
                            if (cachedUsers && data?.deleteUser.id) {
                              const findUserIndex = cachedUsers?.getUsers.findIndex(
                                (u) => u.id === data?.deleteUser.id,
                              )
                              if (findUserIndex !== -1) {
                                const usersList = [...cachedUsers.getUsers]
                                delete usersList[findUserIndex]
                                cache.writeQuery<GetUsersQuery>({
                                  query: GetUsersDocument,
                                  data: { getUsers: usersList },
                                })
                              }
                            }
                          },
                        })
                        if (response.data?.deleteUser.id) {
                          showToast(toastSuccess, 'User has been deleted')
                        } else {
                          showToast(
                            toastError,
                            response.data?.deleteUser.errors![0].message!,
                            'error',
                          )
                        }
                      } catch (error) {
                        showToast(toastError, 'Server Error', 'error')
                        console.log(error)
                      }
                    },
                    reject: () => false,
                  })
                }}
              />
              <Button
                tooltip="Reset Password"
                tooltipOptions={{ position: 'bottom' }}
                icon="pi pi-key"
                className="p-button-text p-button-sm"
                type="button"
                onClick={() => {
                  setShowResetPassword(true)
                  setSelectedUser(data)
                }}
              />
            </>
          )}
        />
      </DataTable>
    </>
  )
}

export { UserList }
