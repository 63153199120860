import { Button as PrimeButton } from 'primereact/button';
import styled from 'styled-components';

const Button = styled(PrimeButton)`
  background: #02c0f8;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  transition: transform 0.3s ease;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  &:hover {
    background: #02c0f8 !important;
    transform: translateY(-0.1rem);
  }
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;
export { Button };
