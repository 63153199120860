import { FC } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { InputWrapper, InputGroup, InputGroupAddon, InputLabel } from './Input';
import { ErrorMessage } from './ErrorMessage';

interface DatePickerProps {
  label?: string;
  columnSize?: string;
  icon?: JSX.Element;
  placeHolder: string;
  errorMsg?: string;
  value: string | Date | undefined;
  onDayChange: (
    day: Date,
    DayModifiers: DayModifiers,
    dayPickerInput: DayPickerInput
  ) => void;
}

const DatePicker: FC<DatePickerProps> = (props) => {
  const columnSize = props.columnSize ? props.columnSize : 'p-col-12';
  return (
    <InputWrapper className={columnSize}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <InputGroup className="p-inputgroup">
        <InputGroupAddon className="p-inputgroup-addon">
          {props.icon}
        </InputGroupAddon>
        <DayPickerInput
          onDayChange={props.onDayChange}
          value={props.value}
          placeholder={props.placeHolder}
          style={{ width: '100%', color: '#495057', borderRadius: 0 }}
        />
      </InputGroup>
      {props.errorMsg && (
        <div className="p-col-12">
          <ErrorMessage message={props.errorMsg} />
        </div>
      )}
    </InputWrapper>
  );
};

export { DatePicker };
