import { useRef, useState } from 'react'
import { FormEvent } from 'react'
import { Button, Input } from '../../../components/form'
import {
  CreateRoleMutation,
  GetRolesDocument,
  GetRolesQuery,
  useCreateRoleMutation,
} from '../../../generated/graphql'
import { Toast } from 'primereact/toast'
import * as Yup from 'yup'
import {
  ClientErrors,
  setSchemaErrors,
  validateField,
  validateSchema,
} from '../../../helpers/handleClientValidation'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { ApolloCache } from '@apollo/client'

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const CreateRole = () => {
  const [form, setForm] = useState({ name: '' })
  const [creteRole] = useCreateRoleMutation()
  const toastSuccess = useRef<Toast>(null)
  const toastError = useRef<Toast>(null)
  const [errors, setErrors] = useState<ClientErrors[]>([])

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 3000,
      })
    }
  }

  const showError = (message: string) => {
    if (toastError && toastError.current) {
      toastError.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 3000,
      })
    }
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setForm({ name: e.currentTarget.value })
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    setSchemaErrors(schema, form, setErrors)
    if (await validateSchema(schema, form)) {
      try {
        const { data } = await creteRole({
          variables: { input: { name: form.name } },
          update(cache: ApolloCache<CreateRoleMutation>, { data }) {
            const cachedRoles = cache.readQuery<GetRolesQuery>({
              query: GetRolesDocument,
            })
            if (cachedRoles?.roles && data?.createRole.role) {
              cache.writeQuery<GetRolesQuery>({
                query: GetRolesDocument,
                data: { roles: [...cachedRoles.roles, data.createRole.role] },
              })
            }
          },
        })
        setForm({ name: '' })
        if (data?.createRole.role) {
          showSuccess('Role has been created')
          return
        }
        if (data?.createRole.errors) {
          showError(data?.createRole.errors[0].message)
        }
      } catch (error) {
        showError('Server Error')
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Input
        icon={<AiOutlineUsergroupAdd />}
        name="name"
        value={form.name}
        errorMsg={validateField('name', errors)}
        placeholder="Role Name"
        change={handleChange}
      />
      <Button type="submit">Create</Button>
    </form>
  )
}

export { CreateRole }
