import { useMemo, useState } from 'react'
import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Spinner } from '../../components'
import {
  StockSettlement as Payment,
  useStockSettlementQuery,
} from '../../generated/graphql'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { addDays, format } from 'date-fns'
import { currencyFormat } from '../../helpers/currencyFormat'
import { differenceInDays } from 'date-fns/esm'

interface StockSettlementInfo {
  id: number
  stockDate: string
  creditPeriod: number
  payable: number
  paid: number
  paymentDate: string
  settledDate: string
  isSettled: boolean
}

interface StockSettlementHashTable {
  [stockId: string]: Array<StockSettlementInfo>
}

const StockSettlement: FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const [payments, setPayments] = useState<Payment[]>()
  const [paymentHashTable, setPaymentHashTable] = useState<
    StockSettlementHashTable
  >()
  const { loading } = useStockSettlementQuery({
    variables: { id: Number(match.params.id) },
    onCompleted: ({ stockSettlement }) => {
      setPayments(stockSettlement)
    },
  })

  useMemo(() => {
    const paymentHashTable: StockSettlementHashTable = {}
    payments?.forEach((x) => {
      paymentHashTable[x.stock_id] = payments
        .filter((p) => p.stock_id === x.stock_id)
        .map((y) => ({
          id: y.id,
          stockDate: y.stock_date,
          creditPeriod: y.creditPeriod,
          payable: y.payable,
          paid: y.paid,
          paymentDate: y.payment_date,
          settledDate: y.settled_date,
          isSettled: y.is_settled,
        })) as StockSettlementInfo[]
    })
    setPaymentHashTable(paymentHashTable)
  }, [payments])

  const tableHeader = (
    paymentHashTable: StockSettlementHashTable,
    ht: string,
  ) => (
    <div className="p-d-flex p-jc-between">
      <div>
        Stock Date:&nbsp;
        {format(Number(paymentHashTable[ht][0].stockDate), 'yyyy-MM-dd')}
      </div>
      <div>Credit Period: {paymentHashTable[ht][0].creditPeriod} Days</div>
      {paymentHashTable[ht][0].creditPeriod > 0 &&
        !paymentHashTable[ht][0].settledDate && (
          <div>
            Days Left:{' '}
            {differenceInDays(
              Date.now(),
              addDays(
                Number(paymentHashTable[ht][0].stockDate),
                paymentHashTable[ht][0].creditPeriod + 1,
              ),
            ) < 0
              ? Math.abs(
                  differenceInDays(
                    Date.now(),
                    addDays(
                      Number(paymentHashTable[ht][0].stockDate),
                      paymentHashTable[ht][0].creditPeriod + 1,
                    ),
                  ),
                )
              : 0}
          </div>
        )}
      <div>
        Settled Date:{' '}
        {paymentHashTable[ht][0].settledDate
          ? format(Number(paymentHashTable[ht][0].settledDate), 'yyyy-MM-dd')
          : 'None'}
      </div>
    </div>
  )

  return loading ? (
    <Spinner />
  ) : (
    <>
      {payments && payments.length > 0
        ? Object.keys(paymentHashTable!).map((ht) => (
            <div key={ht} className="p-mb-3">
              <DataTable
                header={tableHeader(paymentHashTable!, ht)}
                value={paymentHashTable![ht]}
                emptyMessage="No Payments"
                rowGroupMode="rowspan"
                groupField="isSettled"
                sortMode="single"
                sortField="isSettled"
                sortOrder={1}
              >
                <Column
                  field="id"
                  header="Id"
                  body={(_: StockSettlementInfo, props: any) =>
                    props.rowIndex + 1
                  }
                />
                <Column
                  field="payable"
                  header="Payable"
                  body={(data: StockSettlementInfo) =>
                    currencyFormat(data.payable)
                  }
                />
                <Column
                  field="paid"
                  header="Paid"
                  body={(data: StockSettlementInfo) =>
                    currencyFormat(data.paid)
                  }
                />
                <Column
                  field="paymentDate"
                  header="Date"
                  body={(data: StockSettlementInfo) =>
                    data.paymentDate
                      ? format(Number(data.paymentDate), 'yyyy-MM-dd')
                      : '-'
                  }
                />
                <Column
                  field="isSettled"
                  header="Status"
                  body={(data: StockSettlementInfo) =>
                    data.isSettled ? (
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          background: '#c8e6c9',
                          color: '#256029',
                          borderRadius: '2px',
                          padding: '.25em .5rem',
                          letterSpacing: '.3px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Paid
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          background: '#feedaf',
                          color: '#8a5340',
                          borderRadius: '2px',
                          padding: '.25em .5rem',
                          letterSpacing: '.3px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Pending
                      </span>
                    )
                  }
                />
              </DataTable>
            </div>
          ))
        : 'No Payments'}
    </>
  )
}

export default StockSettlement
