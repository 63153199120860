import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Logo from '../../assets/receipt_logo.png'
import { Item, useGetExchangeReceiptInfoQuery } from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat'
import {
  ReceiptWrapper,
  ReceiptContainer,
  Address,
  LogoWrapper,
  ReceiptNumber,
  ReceiptInfo,
  ReceiptTable,
  ReceiptTotal,
  TBody,
  TD,
  THead,
  TR,
  ThankYou,
} from '../../components/receipt'
import { addHours, addMinutes, format } from 'date-fns'

const ExchangeReceiptPreview: FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const { data } = useGetExchangeReceiptInfoQuery({
    variables: { input: Number(match.params.id) },
  });
  const [items, setItems] = useState<Omit<Item, 'addToStock'>[]>([]);

  useEffect(() => {
    const returnedItems = data?.getExchangeReceiptInfo?.items;
    if (returnedItems && returnedItems.length > 0) {
      const groupedItems: Omit<Item, 'addToStock'>[] = [];
      let currentId = returnedItems[0].id;
      for (let [index, i] of returnedItems.entries()) {
        if (index === 0) {
          groupedItems.push(i);
        } else {
          if (currentId !== i.id) {
            groupedItems.push(i);
            currentId = i.id;
          } else {
            const itemIndex = groupedItems.findIndex(i => i.id === currentId);
            const quantity = groupedItems[itemIndex].quantity;
            groupedItems[itemIndex] = { ...groupedItems[itemIndex], quantity: quantity + i.quantity }
          }
        }
      }
      setItems(groupedItems);
    }
  }, [data?.getExchangeReceiptInfo]);

  useEffect(() => {
    setTimeout(() => {
      window.print()
      history.replace('/sales/receipt/create')
    }, 1000)
  }, [history])

  return (
    <ReceiptWrapper>
      <ReceiptContainer>
        <LogoWrapper>
          <img src={Logo} alt="Receipt Logo" />
          <Address>
          { data?.getExchangeReceiptInfo?.branch.toLowerCase() === 'ogf' ? 'M10/11, Level 05, One Galle Face Mall. Tel: 0767302422' : 'New Shopping Complex, Bandaragama. Tel: 0767302422'}
          </Address>
        </LogoWrapper>
        <ReceiptNumber style={{ marginTop: '3rem', textAlign: 'right' }}>Exchange Receipt No: {match.params.id}</ReceiptNumber>
        <ReceiptInfo>
          <div>
            Date: {data?.getExchangeReceiptInfo && format(Number(data?.getExchangeReceiptInfo?.date), 'dd/MM/Y')}
          </div>
          <div>
            {/* Time: {data?.getExchangeReceiptInfo && format(Number(addMinutes(addHours(Number(data.getExchangeReceiptInfo.date), 5), 30)), 'hh:mm a')} */}
            Time: {data?.getExchangeReceiptInfo && format(Number(data.getExchangeReceiptInfo.date), 'hh:mm a')}
          </div>
        </ReceiptInfo>
        <ReceiptTable>
          <THead>
            <TR>
              <TD>No.</TD>
              <TD>Item</TD>
              <TD>Qty</TD>
              <TD>Price</TD>
              <TD>Disc.</TD>
              <TD>Amount</TD>
            </TR>
          </THead>
          <TBody>
            {items.map((o, i: number) => (
              <TR key={o.id}>
                <TD>{i + 1}</TD>
                <TD>{o.name}</TD>
                <TD>{o.quantity}</TD>
                <TD>{currencyFormat(o.price)}</TD>
                <TD>
                  {' '}
                  {o.discount && o.discountType === 2
                    ? `${o.discount}%`
                    : currencyFormat(o.discount!)}
                </TD>
                <TD>
                  {o.discountType === 2
                    ? currencyFormat(
                        (o.price - (o.price * Number(o.discount)) / 100) *
                          o.quantity,
                      )
                    : currencyFormat(
                        (o.price - Number(o.discount)) * o.quantity,
                      )}
                </TD>
              </TR>
            ))}
          </TBody>
        </ReceiptTable>
        <ReceiptTotal>
          <strong>Store Credit</strong>
          <strong>
            {currencyFormat(data?.getExchangeReceiptInfo?.storeCredit!)}
          </strong>
        </ReceiptTotal>
        <ReceiptNumber style={{ paddingTop: '.5rem' }}>
          Invoice Number: {data?.getExchangeReceiptInfo?.orderNumber}
        </ReceiptNumber>
        <ThankYou>Thank you for shopping Saheli Shoes.</ThankYou>
      </ReceiptContainer>
    </ReceiptWrapper>
  )
}

export { ExchangeReceiptPreview }
