import styled from 'styled-components';

const Navigation = styled.div`
  @media print {
    display: none;
  }
  position: relative;
  width: 350px;
  background: #2d2d33;
  height: inherit;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  overflow: hidden;
`;
export { Navigation };
