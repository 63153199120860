import { render } from 'react-dom';
import App from './App';
import './Index.scss';
import PrimeReact from 'primereact/api';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { GlobalStyles } from './GlobalStyles';
import {
  ChequePaymentResponse,
  OrderListResponse,
  ProductVariationResponse,
  ProductVariationTypeResponse,
  SalesResponse,
  SalesTargetResponse,
  StockAvailableResponse,
  StockInvoiceResponse,
  StockValueResponse,
  StockVarianceResponse,
  UpdateStockQuantityResponse,
} from './generated/graphql';
import {
  availableStockMerge,
  chequePaymentsMerge,
  getSalesMerge,
  orderListMerge,
  productTypesMerge,
  productVariationMerge,
  salesTargetsMerge,
  stockInvoiceMerge,
  stockValueMerge,
  stockVarianceMerge,
  updateStockQuantityMerge,
} from './helpers/apolloCache';

PrimeReact.ripple = true;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL + '/graphql',
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, next) => {
  if (localStorage.getItem('atci')) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('atci')}`,
      },
    })
  }
  return next(operation);
});

export const cache = new InMemoryCache({
  typePolicies: {
    getReturnableStocks: {
      keyFields: ['stock_id'],
    },
    Query: {
      fields: {
        getSalesAchieved: {
          keyArgs: false,
          merge: (
            existing: SalesTargetResponse,
            incoming: SalesTargetResponse,
            { readField },
          ): SalesTargetResponse => salesTargetsMerge(existing, incoming, readField)
        },
        chequePayments: {
          keyArgs: false,
          merge: (
            existing: ChequePaymentResponse,
            incoming: ChequePaymentResponse,
            { readField },
          ): ChequePaymentResponse => chequePaymentsMerge(existing, incoming, readField),
        },
        getStockVariance: {
          keyArgs: false,
          merge: (
            existing: StockVarianceResponse,
            incoming: StockVarianceResponse,
            { readField },
          ): StockVarianceResponse => stockVarianceMerge(existing, incoming, readField),
        },
        getProductTypes: {
          keyArgs: false,
          merge: (
            existing: ProductVariationTypeResponse,
            incoming: ProductVariationTypeResponse,
            { readField },
          ): ProductVariationTypeResponse => productTypesMerge(existing, incoming, readField),
        },
        getProductVariationList: {
          keyArgs: false,
          merge: (
            existing: ProductVariationResponse,
            incoming: ProductVariationResponse,
            { readField },
          ): ProductVariationResponse => productVariationMerge(existing, incoming, readField),
        },
        getAvaiableStock: {
          keyArgs: false,
          merge: (
            existing: StockAvailableResponse,
            incoming: StockAvailableResponse,
            { readField },
          ): StockAvailableResponse => availableStockMerge(existing, incoming, readField),
        },
        getStockValue: {
          keyArgs: false,
          merge: (
            existing: StockValueResponse,
            incoming: StockValueResponse,
            { readField },
          ): StockValueResponse => stockValueMerge(existing, incoming, readField),
        },
        getUpdateStockQuantity: {
          keyArgs: false,
          merge: (
            existing: UpdateStockQuantityResponse,
            incoming: UpdateStockQuantityResponse,
            { readField },
          ): UpdateStockQuantityResponse => updateStockQuantityMerge(existing, incoming, readField),
        },
        getOrderList: {
          keyArgs: false,
          merge: (existing: OrderListResponse, incoming: OrderListResponse, { readField }) => orderListMerge(existing, incoming, readField)
        },
        getSales: {
          keyArgs: false,
          merge: (existing: SalesResponse, incoming: SalesResponse, { readField }) => getSalesMerge(existing, incoming, readField)
        },
        getStockInvoices: {
          keyArgs: false,
          merge: (existing: StockInvoiceResponse, incoming: StockInvoiceResponse, { readField }) => stockInvoiceMerge(existing, incoming, readField)
        }
      },
    },
  },
});

export const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  credentials: 'include',
  cache,
});

render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <GlobalStyles />
      <App />
    </Provider>
  </ApolloProvider>,
  document.querySelector('#app'),
);