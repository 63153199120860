import styled from 'styled-components';

const Title = styled.h1`
  color: #02c0f8;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.18rem;
  letter-spacing: 0.1rem;
  padding: 1.5rem;
`;

const Header = () => <Title>Saheli Shoes</Title>;
export { Header };
