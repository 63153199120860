import { getYear } from "date-fns"

const years: string[] = [];

const setYears = () => {
    let currentYear = getYear(Date.now()) - 1;
    for (let i = 0; i < 10; i++) {
        currentYear += 1;
        years.push(currentYear.toString());
    }
}
setYears();
export const getYears = () => years;