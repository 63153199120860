import { FC, useEffect, useState } from 'react'
import Routes from './Routes'
import { Spinner } from './components'
import { useSendPingQuery } from './generated/graphql';

const App: FC = () => {
  const [loading, setLoading] = useState(true);
  useSendPingQuery({ pollInterval: 1000 * 60 * 5 });

  useEffect(() => {
    setLoading(false);
  }, []);
  
  return (
    <div className="app" data-test="component-app">
      {loading ? <Spinner /> : <Routes />}
    </div>
  )
}

export default App;