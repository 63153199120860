import {
  FC,
  FormEvent,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { currencyFormat } from '../helpers/currencyFormat'
import {
  useGetproductsForReceiptQuery,
  ProductListResponse,
  useGetOrderNumberQuery,
} from '../generated/graphql'
import { AutoComplete, Calendar, Input } from '../components/form'
import { checkAvailability } from '../helpers/checkAvailability'
import { AiOutlineNumber, AiOutlinePercentage } from 'react-icons/ai'
import { GiReceiveMoney, GiPayMoney } from 'react-icons/gi'
import { VscSymbolNumeric } from 'react-icons/vsc'
import { BiSearchAlt, BiBarcode } from 'react-icons/bi'
import { arrayUnique } from '../helpers/arrayUnique'
import { RadioButton } from 'primereact/radiobutton'
import { Dialog } from '../components'
import { useLocation } from 'react-router-dom'

interface BarcodeModuleProps {
  showInvoiceNumber: boolean
  dateName: string
  headers: {
    discount?: boolean
    priceType: string
  }
  getItems: Dispatch<SetStateAction<ProductListResponse[]>>
  receiptTotal?: Dispatch<SetStateAction<number>>
  hcmLoyality?: string;
  checkAvailability?: boolean
  getAvailable?: Dispatch<SetStateAction<boolean>>
  getDate: Dispatch<SetStateAction<Date>>
  balance?: number
  type: string
  setGetSizes?: Dispatch<SetStateAction<{ code: string; size: number; quantity: number; color: string; name: string }[]>>
}

const BarcodeModule: FC<BarcodeModuleProps> = (props) => {
  const { pathname } = useLocation();
  const { getItems, receiptTotal, getAvailable, getDate } = props;
  const [products, setProducts] = useState<ProductListResponse[]>([]);
  const [barcode, setBarcode] = useState<string>('');
  const [items, setItems] = useState<ProductListResponse[]>([]);
  const [available, setAvailable] = useState<boolean>(true);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredProducts, setFilteredProducts] = useState<string[]>();
  const [subTotal, setSubTotal] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [discountTypes, setDiscountTypes] = useState<number[]>([1]);
  const [sizes, setSizes] = useState<{ code: string; size: number; quantity: number; color: string, name: string }[]>([]);
  const {
    data: orderNumber,
    loading: loadingOrderNumber,
  } = useGetOrderNumberQuery();
  const [show, setShow] = useState(false);
  const [selectedProductSizeRange, setSelectedProductSizeRange] = useState<{size: string, name: string, code: string, color: string}[]>([]);
  const [selectedSizeWithName, setSelectedSizeWithName] = useState<string>();
  const [selctedCodeForBarCode, setSelctedCodeForBarcode] = useState('');
  const [selectedColorForBarcode, setSelectedColorForBarcode] = useState('');
  const [selectedSizeForBarcode, setSelectedSizeForBarcode] = useState('');

  useEffect(() => {
    calculateSubTotal(items);
   }, [props.hcmLoyality, items]);

  useEffect(() => {
    document.addEventListener('keypress', async function (e) { 
      if (e.location === 0) {
        const div = document.querySelector('#barcode_module');
        const tempInput = document.createElement('input');
        tempInput.setAttribute('id', 'capture');
        tempInput.style.position = 'absolute';
        tempInput.style.top = '0';
        tempInput.style.left = '50%';
        tempInput.style.opacity = '0';
        if (!document.querySelector('#capture')) {
          div?.appendChild(tempInput);
          tempInput.focus();
          const textValue = document.querySelector<HTMLInputElement>('#capture')
          if (textValue) {
            setTimeout(() => {
              setBarcode(textValue.value);
              div?.removeChild(tempInput);
            }, 100);
          }
        }
      }
    });
  }, []);

  useGetproductsForReceiptQuery({
    variables: {
      input: props.type
    },
    onCompleted: (data) => {
      if (data) {
        if (props.type === 'stock') {
          const formedProducts: ProductListResponse[] = [];
          for (let i of data.getProductsForReceipt) {
            if (!formedProducts.find(fp => fp.name === i.name && fp.code === i.code)) {
              formedProducts.push(i);
            }
          }
          setProducts(formedProducts);
        } else {
          setProducts(data.getProductsForReceipt)
        }
      }
    },
  });

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setBarcode(e.currentTarget.value);
    addItem(e.currentTarget.value, true);
    calculateSubTotal(items);
  }

  const addItem = (query: string, barcode: boolean = false) => {
    if (query === '') {
      return
    }
    let product: ProductListResponse | undefined;
    if (barcode) {
      const filteredProducts = products.filter(p => p.barcode === query);
      if (filteredProducts.length === 0) return;
      if (filteredProducts.length === 1) {
        product = filteredProducts[0];
      } else {
        setSelectedProductSizeRange(filteredProducts.map(p => ({ size: p.product_size, name: p.name, code: p.code, color: p.color })).sort((a, b) => a.name.localeCompare(b.name)));
        setShow(true);
      }
    } else {
      if (selctedCodeForBarCode && selectedColorForBarcode && selectedSizeForBarcode) {
        product = products.find((p) => p.code === selctedCodeForBarCode && p.color === selectedColorForBarcode && p.product_size === selectedSizeForBarcode);
        setSelctedCodeForBarcode('');
        setSelectedColorForBarcode('');
        setSelectedSizeForBarcode('');
      } else { 
        product = products.find((p) => p.name === query || (p.name === query.split('(')[0].trim() && p.code === query.split('(')[1].split(')')[0]))
      }
    }
    if (product) {
      if (props.headers.priceType === 'Purchase') {
        product = { ...product }
        product.price = 0
      }
      addProductToItems(product)
      setDiscountTypes((s) => [...s, 1])
    }
    document.getElementById('barcode')?.focus();
  }

  useEffect(() => addItem(selectedSizeWithName ?? ''), [selectedSizeWithName]);

  const addProductToItems = (p: ProductListResponse) => {
    const existingItems = [...items];
    const index = existingItems.findIndex(i => i.name === p.name && i.code === p.code);
    if (index === -1) {
      existingItems.push({...p, quantity: 1});
    } else { 
      existingItems[index].quantity += 1;
    }
    setItems(existingItems);
    if (p.quantity > p.available! || !p.in_stock) {
      setAvailable(false)
    } else {
      setAvailable(true)
    }
    setBarcode('')
  }

  const removeItem = (index: number) => {
    const newItems = items.filter((_p, i) => i !== index)
    setItems(newItems)
  }

  const searchProduct = (e: { originalEvent: Event; query: string }) => {
    let filteredProducts: string[]
    filteredProducts = products
      .filter((p) => (selectedCode !== '' ? p.code === selectedCode : p.code))
      .map((p) => `${p.name} (${p.code})`)
      .filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      })
    setFilteredProducts(filteredProducts)
  }

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredProducts: string[]
    filteredProducts = products
      .map((p) => p.code)
      .filter((p) => {
        return p.toLowerCase().startsWith(e.query.toLowerCase())
      })
    setFilteredCodes(arrayUnique(filteredProducts))
  }

  const handleAutoCompleteProduct = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSelectedProduct(e.value)
    addItem(e.value)
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSelectedCode(e.value)
  }

  const handleAutoCompleteSelect = () => {
    setSelectedProduct('')
  }

  const calculateItemTotal = (
    index: number,
    e: FormEvent<HTMLInputElement>,
    p: ProductListResponse,
  ) => {
    const i = [...items]
    if (e.currentTarget.value === '') {
      i[index].quantity = 1
      setItems(i)
      return
    }
    i[index].quantity = Number(e.currentTarget.value)
    i[index].sub_total = calculateItemSubTotal(index)
    setItems(i)
    calculateSubTotal(i)
    if (!available) {
      return
    }
    if (Number(e.currentTarget.value) > p.available! || !p.in_stock) {
      setAvailable(false)
    } else {
      setAvailable(true)
    }
  }

  const changeItemDiscount = (
    index: number,
    e: FormEvent<HTMLInputElement>,
  ) => {
    const i = [...items]
    if (e.currentTarget.value === '') {
      i[index].discount = 0
      i[index].sub_total = i[index].price * i[index].quantity
      setItems(i)
      return
    }
    const subTotal = calculateItemSubTotal(index, Number(e.currentTarget.value))
    i[index].sub_total = subTotal
    i[index].discount = Number(e.currentTarget.value)
    setItems(i)
    calculateSubTotal(i)
  }

  const handleUnitPrice = (index: number, e: FormEvent<HTMLInputElement>) => {
    const i = [...items]
    i[index].price = Number(e.currentTarget.value)
    i[index].sub_total = calculateItemSubTotal(index)
    setItems(i)
    calculateSubTotal(i)
  }

  const calculateItemSubTotal = (index: number, discount?: number): number => {
    if (discount) {
      if (discountTypes[index] === 1) {
        items[index].discount_type = 1
        return (items[index].price - discount) * items[index].quantity
      } else {
        items[index].discount_type = 2
        return (
          (items[index].price - (items[index].price * discount) / 100) *
          items[index].quantity
        )
      }
    }
    if (discountTypes[index] === 1) {
      return (
        (items[index].price - items[index].discount) * items[index].quantity
      )
    } else {
      return (
        (items[index].price -
          items[index].price * (items[index].discount / 100)) *
        items[index].quantity
      )
    }
  }

  const changeDiscoutType = (e: { value: any }, index: number) => {
    const existingItems = [...items];
    const types = [...discountTypes];
    existingItems[index].discount_type = e.value;
    setItems(existingItems);
    types[index] = e.value;
    setDiscountTypes(types);
    calculateSubTotal(existingItems);
  }

  const calculateSubTotal = (receiptItems: ProductListResponse[]) => {
    const total = receiptItems.reduce(
      (total: number, currentValue: ProductListResponse) => {
        const discount = currentValue.discount_type === 1 ? currentValue.discount : currentValue.price * currentValue.discount / 100;
        return total + (currentValue.price - discount) * currentValue.quantity;
      },
      0,
    )
    let hcmLoyality = 0;
    if (props.hcmLoyality) {
      hcmLoyality = parseFloat(props.hcmLoyality);
    }
    setSubTotal(total - hcmLoyality);
  }

  useEffect(() => calculateSubTotal(items), [items]);
  useEffect(() => {
    setItems(items)
    getItems(items)
  }, [items, getItems])

  useEffect(() => {
    if (receiptTotal) { 
      receiptTotal(subTotal)
    }
  }, [
    subTotal,
    receiptTotal
  ])
  useEffect(() => getAvailable && getAvailable(available), [
    available,
    getAvailable,
  ])
  useEffect(() => {
    const i = items.find((i) => i.quantity > i.available! || !i.in_stock)
    if (i) {
      setAvailable(false)
    } else {
      setAvailable(true)
    }
  }, [items, setAvailable])
  useEffect(() => getDate && getDate(date), [date, getDate])

  const generateSizeArray = (size: string) => {
    const start = Number(size.split('-')[0]);
    const end = Number(size.split('-')[1]);
    const sizeArray: number[] = [];
    for (let i = start; i <= end; i++) {
      sizeArray.push(i);
    }
    return sizeArray;
  }

  const handleChangeSize = (e: FormEvent<HTMLInputElement>, size: number, code: string, color: string, name: string) => {
    let currentSizes = [...sizes];
    const index = currentSizes.findIndex(currentSize => currentSize.color === color && currentSize.size === size && currentSize.code === code);
    if (index !== -1) {
      currentSizes[index].quantity = Number(e.currentTarget.value);
    } else {
      currentSizes.push({ code, size, quantity: Number(e.currentTarget.value), color, name });
    }
    currentSizes = currentSizes.filter(size => size.quantity !== 0);
    setSizes(currentSizes);
    props.setGetSizes && props.setGetSizes(currentSizes);
  }

  const handleSelectedSize = (e: { value: string }, code: string, color: string, size: string) => {
    setSelctedCodeForBarcode(code);
    setSelectedColorForBarcode(color);
    setSelectedSizeForBarcode(size);
    setSelectedSizeWithName(e.value);
    setShow(false);
  }

  return (
    <div id="barcode_module">
      <Dialog
          header="Pick product size"
          visible={show}
          handleOnHide={() => setShow(false)}
      >
        <div className="p-d-flex p-jc-around">
          {selectedProductSizeRange.map(p => <div key={p.size}>
            <RadioButton
              inputId={p.size}
              value={p.name}
              name="selectedSize"
              onChange={(e) => handleSelectedSize(e, p.code, p.color, p.size)}
              checked={p.size === selectedSizeWithName}
            />
            <label style={{ marginLeft: '10px' }} htmlFor={p.size}>
              {p.size}
            </label>
          </div>)}
        </div>
      </Dialog>
        {props.showInvoiceNumber && (
          <div
            style={{
              marginBottom: '.8rem',
              fontWeight: 'normal',
              textAlign: 'right',
              textTransform: 'uppercase',
            }}
          >
            Receipt{' '}
            {!loadingOrderNumber &&
              orderNumber?.getOrderNumber &&
              orderNumber.getOrderNumber}
          </div>
        )}
        <div className="p-grid">
          <Calendar
            columnSize="p-col-3"
            handleSelect={(e: { originalEvent: Event; value: Date }) =>
              setDate(e.value)
            }
            value={date}
          />
        <Input
            id="barcode"
            columnSize="p-col-3 barcode"
            autoFocus={true}
            icon={<BiBarcode />}
            name="barcode"
            value={barcode}
            change={handleChange}
            placeholder="Barcode"
          />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search Code"
            value={selectedCode}
            handleCompleteMethod={searchCode}
            suggestions={filteredCodes}
            handleChange={handleAutoCompleteCode}
            handleSelect={handleAutoCompleteSelect}
          />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search Product"
            value={selectedProduct}
            handleCompleteMethod={searchProduct}
            suggestions={filteredProducts}
            handleChange={handleAutoCompleteProduct}
            handleSelect={handleAutoCompleteSelect}
          />
        </div>
        <div style={{ padding: '2rem 0', textAlign: 'center' }}>
          <div
            className="p-grid grid-table-header"
            style={{ fontWeight: 'bold' }}
          >
            <div className="p-col">Code</div>
            <div className="p-col">Name</div>
            {props.type !== 'stock' && <div className="p-col">Quantity</div>}
            {props.headers.discount && <div className="p-col">Disc. (Rs.)</div>}
            <div className="p-col">{props.headers.priceType} Price (Rs.)</div>
            {props.type !== 'stock' && <div className="p-col">Total (Rs.)</div>}
          </div>
          {items.map((i, index) => (
              <div key={i.id}>
              <div
              className="p-grid p-align-center"
              style={{
                fontSize: '.9rem',
                marginTop: '1rem',
              }}
            >
              <div className="p-col">{i.code}</div>
              <div className="p-col">{i.name}</div>
                {props.type !== 'stock' && <div
                  className="p-col"
                  style={{
                    marginTop:
                      checkAvailability(i.quantity, i.available!, i.in_stock!) !==
                        '' && window.location.pathname === '/sales/receipt/create'
                        ? '2rem'
                        : '',
                  }}
                >
                  <Input
                    icon={<AiOutlineNumber />}
                    errorMsg={
                      props.checkAvailability
                        ? checkAvailability(i.quantity, i.available!, i.in_stock!)
                        : ''
                    }
                    placeholder="quantity"
                    value={i.quantity}
                    type="number"
                    name="quantity"
                    change={(e) => calculateItemTotal(index, e, i)}
                  />
                </div>}
              {props.headers.discount && (
                <div className="p-col" style={{ marginTop: '1.25rem' }}>
                  <Input
                    icon={<GiReceiveMoney />}
                    placeholder="Discount"
                    type="number"
                    name="discount"
                    change={(e) => changeItemDiscount(index, e)}
                    keyUp={(e) => changeItemDiscount(index, e)}
                  />
                  <div className="p-d-flex p-jc-around">
                    <div>
                      <RadioButton
                        tooltip="Discount in percentage"
                        tooltipOptions={{ position: 'bottom' }}
                        inputId="percentage"
                        value={2}
                        name="discountType"
                        onChange={(e) => changeDiscoutType(e, index)}
                        checked={discountTypes[index] === 2}
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="percentage">
                        <AiOutlinePercentage />
                      </label>
                    </div>
                    <div>
                      <RadioButton
                        tooltip="Discount in Rs"
                        tooltipOptions={{ position: 'bottom' }}
                        value={1}
                        name="discountType"
                        onChange={(e) => changeDiscoutType(e, index)}
                        checked={discountTypes[index] === 1}
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="percentage">
                        <VscSymbolNumeric />
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="p-col">
                {props.headers.priceType !== 'Purchase' && (
                    <Input
                      disabled
                      icon={<GiPayMoney />}
                      placeholder="Unit Price"
                      name="unit_price"
                      value={i.price}
                      change={(e) => handleUnitPrice(index, e)}
                  />
                )}
                {props.headers.priceType === 'Purchase' && (
                  <Input
                    icon={<GiPayMoney />}
                    placeholder="Purchase Price"
                    name="purchase_price"
                    change={(e) => handleUnitPrice(index, e)}
                  />
                )}
              </div>
              {props.type !== 'stock' && <div className="p-col" style={{ position: 'relative' }}>
                {currencyFormat(calculateItemSubTotal(index))}
                <div
                  style={{ position: 'absolute', right: '1rem', top: '.5rem', cursor: 'pointer', color: '#2196F3' }}
                  className="pi pi-times"
                  onClick={() => removeItem(index)}
                />
              </div>}
              </div>
              {props.type === 'stock' && <div className="p-grid p-align-center">
                {generateSizeArray(i.size).map((size, sizeIndex) => <div className="p-col-2" key={sizeIndex + i.id}>
                  <Input
                    icon={<AiOutlineNumber />}
                    placeholder={`Size ${size}`}
                    type="number"
                    name="size"
                    change={(e) => handleChangeSize(e, size, i.code, i.color, i.name)}
                  />
                </div>)}
              </div>}
            </div>
          ))}
          <div
            className="p-grid"
            style={{
              marginTop: '4rem',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            <div className="p-col">&nbsp;</div>
            <div className="p-col">&nbsp;</div>
            <div className="p-col">&nbsp;</div>
            <div className="p-col">&nbsp;</div>
            <div className="p-col grid-table-header grid-table-total">
              <span style={{ marginLeft: '6rem', fontWeight: 'normal' }}>
                Total
              </span>
            </div>
            <div className="p-col grid-table-header grid-table-value">
              <span style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
                {pathname.includes('return') ? currencyFormat(subTotal + Number(props.hcmLoyality)) : currencyFormat(subTotal)}
              </span>
            </div>
          </div>
          {props.balance !== undefined && (
            <div
              className="p-grid"
              style={{
                marginTop: '1rem',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              <div className="p-col">&nbsp;</div>
              <div className="p-col">&nbsp;</div>
              <div className="p-col">&nbsp;</div>
              <div className="p-col">&nbsp;</div>
              <div className="p-col grid-table-header grid-table-total">
                <span style={{ marginLeft: '6rem', fontWeight: 'normal' }}>
                  Balance
                </span>
              </div>
              <div className="p-col grid-table-header grid-table-value">
                <span style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
                  {props.balance !== undefined && currencyFormat(props.balance)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
  )
}

export { BarcodeModule }
