import { FC, useEffect, useState, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { subDays, format } from 'date-fns';
import { useGetLastSevenDaysSalesQuery, useGetSalesAchievedQuery, BgSaleAchieved, OgfSaleAchieved, HcSaleAchieved, useGetSalesQuery } from '../generated/graphql';
import { Card } from '../components/dashboard';
import { BiDollar, BiHash } from 'react-icons/bi';
import { currencyFormat } from '../helpers/currencyFormat';

const Dashboard: FC = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [labels, setLabels] = useState<string[]>([]);
  const [dataset, setDataset] = useState<string[]>([]);
  const [daily, setDaily] = useState(0);
  const [itemsSold, setItemsSold] = useState<number | undefined>(0);
  const [monthlyAchieved, setMonthlyAchieved] = useState(0);
  const [salesTarget, setSalesTarget] = useState(0);

  const { data } = useGetLastSevenDaysSalesQuery({ fetchPolicy: 'network-only', variables: { input: { from, to } } });

  const { data: today } = useGetSalesQuery({ fetchPolicy: 'cache-and-network', pollInterval: 60000, variables: { input: { from: format(Date.now(), 'yyyy/MM/dd'), to: format(Date.now(), 'yyyy/MM/dd'), itemsSold: true } } });

  const { data: achieved } = useGetSalesAchievedQuery({ variables: { input: {} } });
  
  useEffect(() => {
    setDaily(today?.getSales.totalSale!);
    setItemsSold(today?.getSales.itemsSold!);
  }, [today])
  
  useEffect(() => {
    const month = Number(format(Date.now(), 'M'));
    const year = Number(format(Date.now(), 'yyyy'));
    let salesAchieved: BgSaleAchieved | OgfSaleAchieved | HcSaleAchieved | undefined;
    if (achieved?.getSalesAchieved.bgSaleAchieved && achieved?.getSalesAchieved.bgSaleAchieved.length > 0) {
      salesAchieved = achieved?.getSalesAchieved.bgSaleAchieved.find(sale => sale.year === year && sale.month === month);
    } else if(achieved?.getSalesAchieved.ogfSaleAchieved && achieved?.getSalesAchieved.ogfSaleAchieved.length > 0) {
      salesAchieved = achieved?.getSalesAchieved.ogfSaleAchieved!.find(sale => sale.year === year && sale.month === month);
    } else {
      salesAchieved = achieved?.getSalesAchieved.hcSaleAchieved!.find(sale => sale.year === year && sale.month === month);
    }
    if (salesAchieved) {
      setMonthlyAchieved(salesAchieved.achieved);
      setSalesTarget(salesAchieved.target);
    }
   }, [achieved])

  const chartRef = useRef<any>(null);

  useEffect(() => { 
    setTo(format(Date.now(), 'yyyy/MM/dd'));
    setFrom(format(subDays(Date.now(), 10), 'yyyy/MM/dd'));
  }, []);

  useEffect(() => { 
    const salesData = data?.getLastSevenDaysSales;
    if (salesData) {
      const dates = salesData.labels.map(date => format(new Date(date), 'do')) as string[];
      const sales = salesData.data.map(sale => String(sale)) as string[];
      setLabels(dates);
      setDataset(sales);
    }
  }, [data, chartRef]);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Sales Progress',
        labelColor: '#fff',
        backgroundColor: '#09B3EB',
        fill: false,
        data: dataset,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
  }
  };

  const calculateSalesTargetRemaining = () => { 
    const achieved = monthlyAchieved.toFixed(2);
    const remaining = salesTarget - Number(achieved);
    if (remaining <= 0) { 
      return 0;
    } else {
      return remaining;
    }
  }
  
  const doughnutData = {
    labels: ['Achieved', `Target (${currencyFormat(salesTarget)})`,],
    datasets: [
        {
            data: [monthlyAchieved.toFixed(2), calculateSalesTargetRemaining()],
            backgroundColor: ['#50f080', '#f357eb'],
        }
    ]
};

  return <>
      <Chart type="bar" data={chartData} ref={chartRef} options={options} height='450px' />
      <div className="p-d-flex">
        <Card style={{ width: '30%' }} title="Daily Sale" count={String(currencyFormat(daily))} icon={<BiDollar />} />
        <Card style={{ width: '30%' }} title="Daily items sold" count={String(itemsSold)} icon={<BiHash />} />
        <Chart style={{ width: '40%' }} type="pie" data={doughnutData} options={{ maintainAspectRatio: false, }} />
      </div>
  </>
};

export { Dashboard };
