import styled from 'styled-components';

export const ReceiptBalance = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
`;
