import { ApolloCache } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { FC, useRef } from 'react'
import { UserForm } from '../../../components/security'
import { UserState } from '../../../components/security/UserForm'
import {
  CreateUserMutation,
  GetUsersDocument,
  GetUsersQuery,
  useCreateUserMutation,
} from '../../../generated/graphql'
import { showToast } from '../../../helpers/showSuccessToast'

const CreateUser: FC = () => {
  const toastSuccess = useRef(null)
  const toastError = useRef(null)
  const [createUser] = useCreateUserMutation()
  const handleSubmit = async (form: UserState) => {
    try {
      const { data } = await createUser({
        variables: {
          input: {
            firstName: form.firstName,
            lastName: form.lastName,
            username: form.username,
            password: form.password,
            roleId: Number(form.roleId),
          },
        },
        update: (cache: ApolloCache<CreateUserMutation>, { data }) => {
          const cachedUsers = cache.readQuery<GetUsersQuery>({
            query: GetUsersDocument,
          })
          if (cachedUsers && data?.createUser.user) {
            cache.writeQuery<GetUsersQuery>({
              query: GetUsersDocument,
              data: {
                getUsers: [...cachedUsers.getUsers, data.createUser.user],
              },
            })
          }
        },
      })
      if (data?.createUser.user) {
        showToast(toastSuccess, 'User has been created')
      } else {
        showToast(toastError, data?.createUser.errors![0].message!, 'error')
      }
    } catch (error) {
      showToast(toastError, 'Server Error', 'error')
      console.log(error)
    }
  }
  return (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <UserForm buttonText="Create" handleSubmit={handleSubmit} />
    </>
  )
}
export { CreateUser }
