import { Calendar as PrimeCalendar } from 'primereact/calendar'
import { FC } from 'react'
import { MdDateRange } from 'react-icons/md'
import styled from 'styled-components'

interface CalendarProps {
  handleSelect: (e: { originalEvent: Event; value: Date }) => void;
  value?: Date;
  showIcon?: boolean;
  errorMsg?: string;
  required?: boolean;
  columnSize?: string;
  placeholder?: string;
}

const CalendarWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))``

const Calendar: FC<CalendarProps> = (props) => {
  const { columnSize = 'p-col-12' } = props
  return (
    <>
      <CalendarWrapper className={`${columnSize} p-d-flex`}>
        <div
          style={{
            background: 'rgba(241, 228, 228, 0.2)',
            color: '#02c0f8',
            padding: '12.8px 19.2px',
            height: '45px',
          }}
        >
          <MdDateRange />
        </div>
        <PrimeCalendar
          appendTo={document.querySelector('body')}
          placeholder={props.placeholder}
          dateFormat="yy/mm/dd"
          showButtonBar
          onSelect={props.handleSelect}
          id="icon"
          showIcon={props.showIcon}
          style={{ width: '100%', borderRadius: 0 }}
          value={props.value}
          className={props.errorMsg && 'p-invalid'}
        />
        {props.errorMsg && (
          <small className="p-invalid p-d-block">{props.errorMsg}</small>
        )}
      </CalendarWrapper>
    </>
  )
}

export { Calendar }
