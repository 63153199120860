import { FC } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner: FC = () => {
  return (
    <ProgressSpinner
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
};

export { Spinner };
