import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AutoComplete, Dropdown } from '../../components/form'
import {
  useGetBrandsQuery,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductColorsQuery,
  useGetUniqueProductNamesQuery,
  useGetStockValueQuery,
  useGetProductCountriesQuery,
  useGetSupplierListQuery,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { Button } from 'primereact/button'
import { AvailabilityWrapper } from '../../components/AvailabilityWrapper'
import { arrayUnique } from '../../helpers/arrayUnique'
import { BiSearchAlt } from 'react-icons/bi'
import { AiOutlineNumber } from 'react-icons/ai'
import { currencyFormat } from '../../helpers/currencyFormat'

const StockValue = () => {
  const limitOptions = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ]

  const [cursor, setCursor] = useState('');
  const [limit, setLimit] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const { data: codes } = useGetUniqueProductCodesQuery();
  const { data: names } = useGetUniqueProductNamesQuery();
  const { data: colors } = useGetUniqueProductColorsQuery();
  const { data: brands } = useGetBrandsQuery();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [supplier, setSupplier] = useState('');
  const [brand, setBrand] = useState('');
  const [color, setColor] = useState('');
  const [category, setCategory] = useState('');
  const [filteredNames, setFilteredNames] = useState<string[]>();
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>();
  const [filteredBrands, setFilteredBrands] = useState<string[]>();
  const { loading, data, client, fetchMore } = useGetStockValueQuery({
    variables: { input: { limit, code, country, supplier, name, brand, category, color } },
    notifyOnNetworkStatusChange: true,
  });
  const { data: countries } = useGetProductCountriesQuery();
  const { loading: loadingSuppliers, data: suppliers } = useGetSupplierListQuery();
  const [filteredCountries, setFilteredCountries] = useState<string[]>();

  const dataDatableData = () => { 
    if (data?.getStockValue.bgValue) {
      return data.getStockValue.bgValue;
    } else if (data?.getStockValue.ogfValue) { 
      return data.getStockValue.ogfValue;
    } else if (data?.getStockValue.ogffValue) { 
      return data.getStockValue.ogffValue;
    } else {
      return data?.getStockValue.hcValue;
    }
  }

  useEffect(() => {
    setHasMore(data?.getStockValue.hasMore!)
    setTotal(data?.getStockValue?.total ?? 0);
    setTotalQuantity(data?.getStockValue?.quantity ?? 0);
    if (data?.getStockValue.hasMore) {
      const stockValue = dataDatableData()!;
      if (stockValue.length > 0) {
        setCursor(stockValue[stockValue.length - 1].id.toString())
      }
    }
  }, [loading]);

  const handleColor = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value);
    client.resetStore();
  };

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value);
    client.resetStore();
  };

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchName = (e: { originalEvent: Event; query: string }) => {
    let filteredNames: string[]
    filteredNames =
      names?.getUniqueProductNames.filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredNames(arrayUnique(filteredNames))
  }

  const searchBrand = (e: { originalEvent: Event; query: string }) => {
    let filteredBrands: string[]
    filteredBrands =
      brands?.getBrands
        .filter((b) => {
          return b.name.toLowerCase().includes(e.query.toLowerCase())
        })
        .map((fb) => fb.name) || []
    setFilteredBrands(arrayUnique(filteredBrands))
  }

  const handleSupplier = (e: {
    originalEvent: Event
    value: any
    target: {
        name: string
        id: string
        value: any
    }
  }) => {
    setSupplier(e.value);
    client.resetStore();
};

  const searchCountry = (e: { originalEvent: Event; query: string }) => {
    let filteredCountries: string[]
    filteredCountries =
      countries?.getUniqueProductCountries.filter((p) => {
        return p.toLowerCase().startsWith(e.query.toLowerCase())
      }) || []
    setFilteredCountries(arrayUnique(filteredCountries));
  }
  
  const searchSupplier = (e: { originalEvent: Event; query: string }) => {
    let filteredSuppliers: string[]
    filteredSuppliers =
      suppliers?.getSupplierList.filter((c) => {
        return c.name.toLowerCase().includes(e.query.toLowerCase())
      }).map(c => c.name) || []
    setFilteredSuppliers(arrayUnique(filteredSuppliers))
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleAutoCompleteName = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setName(e.value)
  }

  const handleAutoCompleteCountry = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCountry(e.value)
  }

  const getSupplierName = (id: string) => !loadingSuppliers && suppliers?.getSupplierList.find(s => s.id === id)?.name;

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setBrand(e.value)
  }

  const handleAutoCompleteSupplier = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSupplier(suppliers?.getSupplierList.find(s => s.name === e.value)?.id ?? '');
  }

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          code,
          country,
          supplier,
          name,
          brand,
          category,
          color
        },
      },
    })
  }

  const clearFilters = () => {
    setLimit('')
    setCode('')
    setName('')
    setCountry('');
    setSupplier('');
    setBrand('')
    setCategory('')
    setColor('');
    client.resetStore()
  }

  useEffect(() => {
    clearFilters()
  }, [])

  const tableHeader = (
    <div className="p-grid">
      <AutoComplete
          columnSize="p-col-3"
          icon={<BiSearchAlt color="#02c0f8" />}
          placeholder="Search Country"
          value={country}
          handleCompleteMethod={searchCountry}
          suggestions={filteredCountries}
          handleChange={handleAutoCompleteCountry}
          handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search supplier"
        value={suppliers?.getSupplierList.find(s => s.id === supplier)?.name ?? ''}
        handleCompleteMethod={searchSupplier}
        suggestions={filteredSuppliers}
        handleChange={handleAutoCompleteSupplier}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Brand"
        value={brand}
        handleCompleteMethod={searchBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteBrand}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Name"
        value={name}
        handleCompleteMethod={searchName}
        suggestions={filteredNames}
        handleChange={handleAutoCompleteName}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Color"
        value={color}
        handleChange={handleColor}
        name="color"
        options={colors?.getUniqueProductColors.map((s) => ({
          label: s.toString(),
          value: s.toString(),
        }))}
        optionLabel="label"
        optionValue="value"
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  );

  return loading ? (
    <Spinner />
  ) : (
    <>
      <AvailabilityWrapper>
        <DataTable
          header={tableHeader}
          value={dataDatableData()!}
          rowGroupMode="rowspan"
          groupField="code"
          sortMode="single"
          sortField="code"
          sortOrder={1}
          emptyMessage="No records"
          >
            <Column field="country" header="Country"></Column>
            <Column header="Supplier" body={(data: any) => getSupplierName(data.supplier)} />
            <Column field="brand" header="Brand"></Column>
            <Column field="code" header="Code"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="color" header="Color"></Column>
            <Column header="Price" body={(data: any) => <>{currencyFormat(data.purchase_price)}</>}></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column header="Total" body={(data: any) => <>{currencyFormat(data.total)}</>}></Column>
          </DataTable>
        </AvailabilityWrapper>
        <div className="p-d-flex p-jc-between p-ai-center">
            <div className="p-mt-4">Total quantity: {totalQuantity}</div>
            <div className="p-mt-4">Total: {currencyFormat(total) }</div>
          </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} className="p-button-sm">
            Clear Filters
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            Load More
          </Button>
        </div>
      </div>
    </>
  )
}

export { StockValue }
