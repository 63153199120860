import { FC, FormEvent, useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Dropdown, Input } from '../form'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import {
  useCreateProductVariationMutation,
  useGetBrandsQuery,
  GetproductsForReceiptDocument,
  useUpdateProductVariationMutation,
  GetProductVariationListDocument,
  AvaiableStockDocument,
  ProductVariationResponseType,
  useSearchProductTypesQuery,
} from '../../generated/graphql'
import { SiBrandfolder } from 'react-icons/si'
import { GrProductHunt } from 'react-icons/gr'
import { BiBarcode } from 'react-icons/bi'
import { GiCheckboxTree, GiPriceTag } from 'react-icons/gi'
import { IoIosColorPalette } from 'react-icons/io'
import { CgSize } from 'react-icons/cg'

interface AddSubProductProps {
  hideDialog: () => void
  toastSuccess: (message: string) => void
  toastError: (message: string) => void
  subProduct?: ProductVariationResponseType
  edit?: boolean
}

interface AddSubProductState {
  barcode?: string;
  price?: string;
  productId: number | undefined;
  categoryId: number | undefined;
  productVariationTypeId: number | undefined;
  size?: string | undefined;
  colors: string[];
}

const schema = Yup.object().shape({
  productId: Yup.number().required('Brand is required').nullable(),
  categoryId: Yup.number().required('Category is required').nullable(),
  productVariationTypeId: Yup.number().required('Product is required'),
  price: Yup.string().required('Price is required')
})

const AddSubProduct: FC<AddSubProductProps> = (props) => {
  const { loading: loadingBrands, data: brands } = useGetBrandsQuery();
  const [productTypes, setProductTypes] = useState<
    Array<{ code: string; name: string; id: number }>
  >()
  const [form, setForm] = useState<AddSubProductState>({
    price: '',
    barcode: '',
    productId: undefined,
    categoryId: undefined,
    productVariationTypeId: undefined,
    size: undefined,
    colors: ['']
  });
  const { loading: loadingProductVariations, data: productVariations } = useSearchProductTypesQuery({ variables: { input: form.productId ? Number(form.productId) : 0 } });

  useEffect(() => {
    if (props.subProduct) {
      setForm((s) => ({
        ...s,
        ...props.subProduct,
        price: String(props.subProduct?.price),
        productId: props.subProduct?.product_id,
        categoryId: props.subProduct?.category_id,
        productVariationTypeId: props.subProduct?.type_id,
        size: props.subProduct?.size
      }));
      setForm(f => ({ ...f, colors: [props.subProduct!.color] }));
    }
  }, [props.subProduct]);

  useEffect(() => {
    if (productVariations?.searchProductTypes && productVariations?.searchProductTypes.length > 0) {
      setProductTypes(productVariations.searchProductTypes);
    }
  }, [form.productId, loadingProductVariations, brands?.getBrands]);

  const [createProductVariation] = useCreateProductVariationMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])
  const [updateProductVariation] = useUpdateProductVariationMutation()

  const handleDropdownChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value })
  }

  const handleColorChange = (e: FormEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const state = [...form.colors];
    state[index] = e.currentTarget.value;
    setForm(f => ({ ...f, colors: state }));
  }

  const removeColor = (index: number) => {
    const state = [...form.colors];
    state.splice(index, 1);
    setForm(f => ({ ...f, colors: state }));
  }

  const handleBarcodeChange = (e: FormEvent<HTMLInputElement>) => { 
    const f = { ...form };
    f.barcode = e.currentTarget.value;
    setForm(f);
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid(form, { abortEarly: false })) {
      if (props.edit) {
        const { data } = await updateProductVariation({
          variables: {
            input: {
              id: props.subProduct!.id,
              productId: form.productId!,
              categoryId: form.categoryId!,
              productVariationTypeId: form.productVariationTypeId!,
              price: Number(form.price),
              color: form.colors[0],
              size: form.size!,
              barcode: form.barcode,
            },
          },
          refetchQueries: [
            {
              query: GetProductVariationListDocument,
              variables: { input: {} },
            },
            { query: GetproductsForReceiptDocument, variables: { input: 'stock' } },
            {
              query: AvaiableStockDocument,
              variables: { input: { limit: '25', cursor: '' } },
            },
          ],
        })
        if (!data?.updateProductVariation.errors) {
          props.toastSuccess('Sub product has been updated')
        } else {
          props.toastError(data.updateProductVariation.errors[0].message)
        }
      } else {
        const response = await createProductVariation({
          variables: {
            input: {
              barcode: form.barcode,
              categoryId: form.categoryId!,
              price: Number(form.price),
              productId: form.productId!,
              productVariationTypeId: form.productVariationTypeId!,
              colors: form.colors
            },
          },
          refetchQueries: [
            { query: GetproductsForReceiptDocument, variables: { input: 'stock' } },
            {
              query: GetProductVariationListDocument,
              variables: {
                input: {},
              },
            },
            {
              query: AvaiableStockDocument,
              variables: { input: { limit: '25', cursor: '' } },
            },
          ],
        })
        if (!response.data?.createProductVariance.errors) {
          props.toastSuccess(response.data?.createProductVariance.message!)
        } else {
          props.toastError(
            response.data.createProductVariance.errors[0].message,
          )
        }
      }
      props.hideDialog()
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
    >
      {loadingBrands ? (
        'Loading brands'
      ) : (
        <Dropdown
          name="productId"
          filterBy="name"
          icon={<SiBrandfolder color="#02c0f8" />}
          label="Brand"
          options={brands?.getBrands}
          optionLabel="name"
          optionValue="id"
          placeholder="Pick a Brand"
          handleChange={handleDropdownChange}
          value={form.productId}
          errorMsg={validateField('productId', errors)}
        />
      )}
      <Dropdown
        name="categoryId"
        filterBy="name"
        icon={<GiCheckboxTree color="#02c0f8" />}
        label="Category"
        options={
          brands?.getBrands.find((b) => b.id === form.productId)?.categories ||
          []
        }
        optionLabel="name"
        optionValue="id"
        placeholder="Pick a Category"
        handleChange={handleDropdownChange}
        value={form.categoryId}
        errorMsg={validateField('categoryId', errors)}
      />
      {loadingProductVariations ? (
        'Loading products'
      ) : (
        <Dropdown
          name="productVariationTypeId"
          filterBy="name"
          label="Product"
          icon={<GrProductHunt color="#02c0f8" />}
          options={productTypes}
          optionLabel="code"
          optionValue="id"
          placeholder="Pick a Product"
          handleChange={handleDropdownChange}
          value={form.productVariationTypeId}
          errorMsg={validateField('productVariationTypeId', errors)}
        />
      )}
      <Input
        icon={<BiBarcode />}
        name="barcode"
        placeholder="Barcode"
        change={handleBarcodeChange}
      />
      <Input
        change={handleInputChange}
        name="price"
        icon={<GiPriceTag />}
        placeholder="Selling Price"
        type="number"
        value={form.price}
        step={0.01}
        errorMsg={validateField('price', errors)}
      />
      {props.subProduct && <Input
        change={handleInputChange}
        name="size"
        icon={<CgSize />}
        placeholder="Size"
        value={form.size ? form.size : ''}
      />}
      {form.colors.map((c, index: number) => (
          <div key={index}>
            <Input
              value={c}
              name="colors"
              icon={<IoIosColorPalette />}
              placeholder="Colour"
              change={(e) => handleColorChange(e, index)}
              errorMsg={validateField('colors', errors)}
            />
            {form.colors.length > 1 && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  tooltip="Remove"
                  tooltipOptions={{ position: 'bottom' }}
                  icon="pi pi-times"
                  className="p-button-text p-button-sm"
                  onClick={() => removeColor(index)}
                  type="button"
                />
              </div>
            )}
          </div>
      ))}
      {!props.subProduct && <div className="p-col-12">
        <Button
          tooltip="Add"
          tooltipOptions={{ position: 'bottom' }}
          icon="pi pi-plus"
          className="p-button-text p-button-sm"
          onClick={() => { setForm(f => ({...f, colors: [...f.colors, '']})) }}
          type="button"
        />
      </div>}
      <div className="p-grid" style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            <i
              className={`pi ${props.edit ? 'pi-pencil' : 'pi-plus'} p-mr-2`}
            />{' '}
            {props.edit ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { AddSubProduct };