import { FetchResult } from '@apollo/client'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Logo from '../../assets/receipt_logo.png'
import { Spinner } from '../../components'
import { GetOrderMutation, Item, useGetOrderMutation, User } from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat'
import {
  ReceiptWrapper,
  ReceiptContainer,
  Address,
  LogoWrapper,
  ReceiptNumber,
  NoOfItems,
  PaymentMethods,
  ReceiptBalance,
  ReceiptInfo,
  ReceiptTable,
  ReceiptTotal,
  TBody,
  TD,
  THead,
  TR,
  ThankYou,
} from '../../components/receipt'
import { addHours, addMinutes, format } from 'date-fns'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/rootReducer'

const ReceiptPreview: FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const user = useSelector<AppState, User | null>(state => state.auth.user);
  const [order, setOrder] = useState<FetchResult<GetOrderMutation>>();
  const [items, setItems] = useState<Item[]>([]);
  const [getOrder, { loading }] = useGetOrderMutation();
  useEffect(() => {
    getOrder({
      variables: { input: { orderNumber: Number(match.params.id) } },
    }).then((response) => {
      setOrder(response)
      setTimeout(() => {
        window.print()
        history.replace('/sales/receipt/create')
      }, 1000)
    })
  }, [getOrder, match.params.id, setOrder, history]);

  useEffect(() => {
    if (order?.data?.getOrder.items && order?.data?.getOrder.items.length > 0) {
      const items = order.data.getOrder.items;
      const groupedItems: Item[] = [];
      let currentId = items[0].productVariationId;
      for (let [index, i] of items.entries()) {
        if (index === 0) {
          groupedItems.push(i);
        } else {
          if (currentId !== i.productVariationId) {
            groupedItems.push(i);
            currentId = i.productVariationId;
          } else {
            const itemIndex = groupedItems.findIndex(i => i.productVariationId === currentId);
            if (itemIndex !== -1) {
              groupedItems[itemIndex].quantity += i.quantity;
            }
          }
        }
      }
      setItems(groupedItems);
    }
  }, [order?.data?.getOrder.items]);
  
  const totalItems = () => {
    return items.reduce(
      (total: number, i) => i.quantity + total,
      0,
    )
  }

  const netTotal = () => {
    return items.reduce(
      (total: number, i) =>
        (i.price -
          (i.discountType === 2
            ? (i.price * Number(i.discount)) / 100
            : Number(i.discount))) *
          i.quantity +
        total,
      0,
    )
  }

  const totalPaid = () => {
    if (!order?.data?.getOrder.paymentMethods) return 0;
    if (order?.data?.getOrder.paymentMethods?.length === 1 && order?.data?.getOrder.paymentMethods[0].type === 'Cash') {
      return order.data.getOrder.orderPayment?.amount_paid!;
    } else {
      return order?.data?.getOrder.paymentMethods.reduce(
        (total: number, m) => m.amount + total,
        0,
      );
    }
  }

  const hasBalance = () => totalPaid() - netTotal();

  return (
    <ReceiptWrapper>
      {loading || !order?.data?.getOrder ? (
        <Spinner />
      ) : (
        <ReceiptContainer>
          {
            <>
              <LogoWrapper>
                <img src={Logo} alt="Receipt Logo" />
                  <Address>
                    { user?.branch.name.toLowerCase() === 'ogf' ? 'M10/11, Level 05, One Galle Face Mall. Tel: 0767302422' : 'New Shopping Complex, Bandaragama. Tel: 0767302422'}
                  </Address>
              </LogoWrapper>
                <ReceiptNumber style={{ marginTop: '3rem', textAlign: 'right' }}>Invoice No: {match.params.id}</ReceiptNumber>
                {user?.branch.id === 3 && order.data.getOrder.hcmOrderNumber && <ReceiptNumber style={{ marginTop: '.5rem', textAlign: 'right' }}>HCM Invoice No: {order.data.getOrder.hcmOrderNumber}</ReceiptNumber>}
              <ReceiptInfo>
                <div>
                  Date:{' '}
                  {format(Number(order.data.getOrder.order?.date), 'dd/MM/Y')}
                </div>
                <div>
                    Time:{' '}
                    {/* {format(Number(addMinutes(addHours(Number(order.data.getOrder.order?.date), 5), 30)), 'hh:mm a')} */}
                  {format(Number(order.data.getOrder.order?.date), 'hh:mm a')}
                </div>
                <div>Cashier: {order.data.getOrder?.user}</div>
              </ReceiptInfo>
              <ReceiptTable>
                <THead>
                  <TR>
                    <TD>No.</TD>
                    <TD>Item</TD>
                    <TD>Qty</TD>
                    <TD>Price</TD>
                    <TD>Disc.</TD>
                    <TD>Amount</TD>
                  </TR>
                </THead>
                <TBody>
                  {items.map((o, i: number) => (
                    <TR key={i}>
                      <TD>{i + 1}</TD>
                      <TD>{o.name}</TD>
                      <TD>{o.quantity}</TD>
                      <TD>{currencyFormat(o.price)}</TD>
                      <TD>
                        {o.discount && o.discountType === 2
                          ? `${o.discount}%`
                          : currencyFormat(o.discount!)}
                      </TD>
                      <TD>
                        {o.discountType === 2
                          ? currencyFormat(
                              (o.price - (o.price * Number(o.discount)) / 100) *
                                o.quantity,
                            )
                          : currencyFormat(
                              (o.price - Number(o.discount)) * o.quantity,
                            )}
                      </TD>
                    </TR>
                  ))}
                </TBody>
              </ReceiptTable>
              <ReceiptTotal>
                <strong>Net Total</strong>
                <strong>{currencyFormat(netTotal())}</strong>
              </ReceiptTotal>
                {order?.data.getOrder.paymentMethods?.length === 1 && order?.data.getOrder.paymentMethods[0].type === 'Cash' ?
                  <PaymentMethods>
                    <div>Cash</div>
                    <div>{currencyFormat(order?.data.getOrder.orderPayment?.amount_paid!)}</div>
                  </PaymentMethods> : order?.data.getOrder.paymentMethods?.map((x) => (
                  <PaymentMethods key={x.type}>
                    <div>{x.type}</div>
                    <div>{currencyFormat(x.amount)}</div>
                  </PaymentMethods>
                  ))}
                {user?.branch.id === 3 && order.data.getOrder.hcmLoyality && order.data.getOrder.hcmLoyality !== 0 && <ReceiptBalance>
                  <div>HCM Voucher</div>
                  <div>{ currencyFormat(order.data.getOrder.hcmLoyality) }</div>
                </ReceiptBalance>}
              {order.data.getOrder.points && (
                <ReceiptBalance>
                  <div>Points</div>
                  <div>{order.data.getOrder.points}</div>
                </ReceiptBalance>
              )}
              <ReceiptBalance>
                <div>Balance</div>
                <div>{hasBalance() > 0 ? currencyFormat(hasBalance()) : 0}</div>
              </ReceiptBalance>
              <NoOfItems>
                <div>No. of Items</div>
                <div>{totalItems()}</div>
              </NoOfItems>
              <ThankYou>Thank you for shopping Saheli Shoes.</ThankYou>
            </>
          }
        </ReceiptContainer>
      )}
    </ReceiptWrapper>
  )
}

export { ReceiptPreview }
