import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Logo from '../../assets/receipt_logo.png'
import { Spinner } from '../../components'
import {
  GetReturnedOrderQuery,
  Item,
  useGetReturnedOrderQuery,
  User,
} from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat';
import {
  ReceiptWrapper,
  ReceiptContainer,
  Address,
  LogoWrapper,
  ReceiptNumber,
  NoOfItems,
  PaymentMethods,
  ReceiptBalance,
  ReceiptInfo,
  ReceiptTable,
  ReceiptTotal,
  TBody,
  TD,
  THead,
  TR,
  ThankYou,
} from '../../components/receipt'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/rootReducer'
import { format, addMinutes, addHours } from 'date-fns'

const ReturnPreview: FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const user = useSelector<AppState, User | null>(state => state.auth.user);
  const [order, setOrder] = useState<GetReturnedOrderQuery>()
  const [items, setItems] = useState<Item[]>([]);
  const { loading } = useGetReturnedOrderQuery({
    variables: { input: Number(match.params.id) },
    onCompleted: (data) => {
      setOrder(data)
      setTimeout(() => {
        window.print()
        history.replace('/sales/exchange/list')
      }, 1000)
    },
  })

  useEffect(() => {
    if (order?.getReturnedOrder?.items && order.getReturnedOrder.items.length > 0) {
      const items = order.getReturnedOrder.items;
      const groupedItems: Item[] = [];
      let currentId = items[0].productVariationId;
      for (let [index, i] of items.entries()) {
        if (index === 0) {
          groupedItems.push(i);
        } else {
          if (currentId !== i.productVariationId) {
            groupedItems.push(i);
            currentId = i.productVariationId;
          } else {
            const itemIndex = groupedItems.findIndex(i => i.productVariationId === currentId);
            const quantity = groupedItems[itemIndex].quantity;
            groupedItems[itemIndex] = {...groupedItems[itemIndex], quantity: quantity + i.quantity}
          }
        }
      }
      setItems(groupedItems);
    }
  }, [order?.getReturnedOrder]);

  const netTotal = () => {
    console.log(items);
    return items.reduce((total, item) => {
      let discount = 0;
      if (item.discount) {
        if (item.discountType === 1) {
          discount = item.discount;
        } else {
          discount = item.price * item.discount / 100;
        }
      }
      return total + (item.price - discount) * item.quantity;
    }, 0);
  }

  const totalPaid = () => {
    if (!order?.getReturnedOrder?.paymentMethods) return 0
    if (order?.getReturnedOrder.paymentMethods?.length === 1 && order?.getReturnedOrder.paymentMethods[0].type === 'Cash') {
      return order?.getReturnedOrder.orderPayment?.amount_paid!;
    } else {
      return order.getReturnedOrder.paymentMethods.reduce(
        (_, m, total: number) => m.amount + total,
        0,
      );
    }
  }

  const hasBalance = () => totalPaid() - (netTotal() - order?.getReturnedOrder?.returnedReceiptAmount!);

  const totalItems = () => items.reduce((total, item) => item.quantity + total, 0);

  const getDate = (timestamp: string) => {
    const date = new Date(new Date(Number(timestamp)).toLocaleDateString()).getDate();
    const month = new Date(new Date(Number(timestamp)).toLocaleDateString()).getMonth() + 1;
    const year = new Date(new Date(Number(timestamp)).toLocaleDateString()).getFullYear();
    return `${date}/${month}/${year}`;
  }

  const getTime = (timestamp: string) => (new Date(Number(timestamp)).toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' }));

  return (
    <ReceiptWrapper>
      {loading ? (
        <Spinner />
      ) : (
        <ReceiptContainer>
          {
            <>
              <LogoWrapper>
                <img src={Logo} alt="Receipt Logo" />
                  <Address>
                  { user?.branch.name.toLowerCase() === 'ogf' ? 'M10/11, Level 05, One Galle Face Mall. Tel: 0767302422' : 'New Shopping Complex, Bandaragama. Tel: 0767302422'}
                </Address>
              </LogoWrapper>
              <ReceiptNumber style={{ marginTop: '3rem', textAlign: 'right' }}>Receipt No: {match.params.id}</ReceiptNumber>
              <ReceiptInfo>
                <div>
                    Date:{' '}
                  { getDate(order?.getReturnedOrder?.order?.date!) }
                </div>
                <div>Time: {order?.getReturnedOrder?.order?.date && format(Number(addMinutes(addHours(Number(order.getReturnedOrder.order.date), 5), 30)), 'hh:mm a')}</div>
                <div>Cashier: {order?.getReturnedOrder?.user}</div>
              </ReceiptInfo>
              <ReceiptTable>
                <THead>
                  <TR
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    <TD>No.</TD>
                    <TD>Item</TD>
                    <TD>Qty</TD>
                    <TD>Price</TD>
                    <TD>Disc.</TD>
                    <TD>Amount</TD>
                  </TR>
                </THead>
                <TBody>
                  {items.map((o, i: number) => (
                    <TR key={o.id}>
                      <TD>{i + 1}</TD>
                      <TD>{o.name}</TD>
                      <TD>{o.quantity}</TD>
                      <TD>{currencyFormat(o.price)}</TD>
                      <TD>{o.discount && currencyFormat(o.discount)}</TD>
                      <TD>
                        {currencyFormat(
                          (o.price -
                            (o.discountType === 2
                              ? (o.price * Number(o.discount)) / 100
                              : Number(o.discount))) *
                            o.quantity,
                        )}
                      </TD>
                    </TR>
                  ))}
                </TBody>
              </ReceiptTable>
              <ReceiptTotal>
                <strong>Net Total</strong>
                <strong>{currencyFormat(netTotal())}</strong>
              </ReceiptTotal>
              <PaymentMethods>
                <div>
                  Returned Receipt -{' '}
                  {order?.getReturnedOrder?.returnedOrderNumber}
                </div>
                {currencyFormat(order?.getReturnedOrder?.returnedReceiptAmount!)}
              </PaymentMethods>
              { order?.getReturnedOrder?.paymentMethods?.length === 1 && order?.getReturnedOrder?.paymentMethods[0].type === 'Cash' ?
                  <PaymentMethods>
                    <div>Cash</div>
                    <div>{currencyFormat(order?.getReturnedOrder.orderPayment?.amount_paid!)}</div>
                  </PaymentMethods> : order?.getReturnedOrder?.paymentMethods?.map((x) => (
                  <PaymentMethods key={x.type}>
                    <div>{x.type}</div>
                    <div>{currencyFormat(x.amount)}</div>
                  </PaymentMethods>
                  ))}
                {user?.branch.id === 3 && order?.getReturnedOrder?.hcmLoyality && order.getReturnedOrder.hcmLoyality !== 0 && <ReceiptBalance>
                  <div>HCM Voucher</div>
                  <div>{ currencyFormat(order?.getReturnedOrder?.hcmLoyality) }</div>
                </ReceiptBalance>}
                {order?.getReturnedOrder?.orderPayment?.points && (
                  <ReceiptBalance>
                    <div>Points</div>
                    <div>{order.getReturnedOrder.orderPayment.points}</div>
                  </ReceiptBalance>
                )}
              <ReceiptBalance>
                <div>Balance</div>
                <div>{hasBalance() > 0 ? currencyFormat(hasBalance()) : 0}</div>
              </ReceiptBalance>
              <NoOfItems>
                <div>No. of Items</div>
                <div>{totalItems()}</div>
              </NoOfItems>
              <ThankYou>Thank you for shopping Saheli Shoes.</ThankYou>
            </>
          }
        </ReceiptContainer>
      )}
    </ReceiptWrapper>
  )
}

export { ReturnPreview }
