import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { FormEvent, Fragment, useRef, useState } from 'react'
import { Calendar, Dropdown } from '../../components/form'
import { Input } from '../../components/form/Input'
import { Spinner } from '../../components'
import {
  useGetSupplierListQuery,
  useGetPaymentTypesQuery,
  useGetBanksQuery,
  usePaymentMutation,
  GetOutstandingDocument,
  StockSettlementDocument,
} from '../../generated/graphql'
import { showToast } from '../../helpers/showSuccessToast'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import { IoIosCash } from 'react-icons/io'
import { FaMoneyCheck, FaUserTie } from 'react-icons/fa'
import { MdPayment } from 'react-icons/md'
import { RiBankFill } from 'react-icons/ri'

interface InitialValues {
  supplierId: string
  date: Date
  amount: string
  paymentType: string
  cheque_number?: string
  cheque_date?: Date
  bank?: string
}

const schema = Yup.object().shape({
  supplierId: Yup.string().required('Supplier is required'),
  date: Yup.date().required('Payment date is required'),
  amount: Yup.string().required('Amount is required'),
  paymentType: Yup.string().required('Payment type is required'),
})

const SupplierPayment = () => {
  const toastSuccess = useRef(null)
  const toastError = useRef(null)
  const {
    loading: paymentTypesLoading,
    data: paymentTypes,
  } = useGetPaymentTypesQuery()
  const { loading: loadingBanks, data: getBanks } = useGetBanksQuery()
  const [form, setForm] = useState<InitialValues>({
    date: new Date(),
    supplierId: '',
    amount: '',
    paymentType: '',
    cheque_number: '',
    cheque_date: new Date(),
    bank: '',
  })
  const [errors, setErrors] = useState<ClientErrors[]>([])

  const {
    loading: supplierLoading,
    data: supplierData,
  } = useGetSupplierListQuery()

  const [makePayment, { loading: paying }] = usePaymentMutation()

  const onDropdownChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const f = { ...form, [name]: value }
    setForm(f)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid(form)) {
      const f = { ...form }
      if (Number(f.paymentType) === 1) {
        f.cheque_date = undefined
        f.cheque_number = undefined
        f.bank = undefined
      }
      try {
        const { data } = await makePayment({
          variables: {
            input: {
              amount: Number(f.amount),
              date: f.date.toISOString(),
              supplierId: Number(f.supplierId),
              paymentType: Number(f.paymentType),
              bank: f.bank ? Number(f.bank) : null,
              cheque_date: f.cheque_date ? f.cheque_date.toISOString() : null,
              cheque_number: f.cheque_number ? f.cheque_number : null,
            },
          },
          refetchQueries: [
            { query: GetOutstandingDocument },
            {
              query: StockSettlementDocument,
              variables: { id: Number(form.supplierId) },
            },
          ],
        })
        if (data?.payment.message) {
          showToast(toastSuccess, data?.payment.message)
        } else {
          showToast(toastSuccess, data?.payment.errors![0].message!, 'error')
        }
      } catch (error) {
        showToast(toastSuccess, 'Server Error', 'error')
        console.log(error)
      }
    }
  }

  return (
    <div>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      {paying ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <Calendar
              handleSelect={(e: { originalEvent: Event; value: Date }) =>
                setForm({
                  ...form,
                  date: e.value,
                })
              }
              value={form.date}
              errorMsg={validateField('date', errors)}
            />
            {supplierLoading ? (
              'Loading Suppliers'
            ) : (
              <Dropdown
                icon={<FaUserTie color="#02c0f8" />}
                name="supplierId"
                value={form.supplierId}
                handleChange={onDropdownChange}
                options={supplierData?.getSupplierList}
                label="Supplier"
                filterBy="name"
                placeholder="Pick a supplier"
                errorMsg={validateField('supplierId', errors)}
              />
            )}
            <Input
              name="amount"
              icon={<IoIosCash />}
              placeholder="Amount"
              change={handleChange}
              errorMsg={validateField('amount', errors)}
            />
            {paymentTypesLoading ? (
              'Loading Suppliers'
            ) : (
              <Dropdown
                icon={<MdPayment color="#02c0f8" />}
                name="paymentType"
                value={form.paymentType}
                handleChange={onDropdownChange}
                options={paymentTypes?.getPaymentTypes}
                label="Payment Type"
                placeholder="Pick a payment type"
                errorMsg={validateField('paymentType', errors)}
              />
            )}
            {Number(form.paymentType) === 2 && (
              <Fragment>
                <Input
                  icon={<FaMoneyCheck />}
                  name="cheque_number"
                  placeholder="Cheque Number"
                  change={handleChange}
                />
                <Calendar
                  handleSelect={(e: { originalEvent: Event; value: Date }) =>
                    setForm({
                      ...form,
                      cheque_date: e.value,
                    })
                  }
                  value={form.cheque_date!}
                />
                {loadingBanks ? (
                  'Loading banks...'
                ) : (
                  <Dropdown
                    icon={<RiBankFill color="#02c0f8" />}
                    name="bank"
                    value={form.bank}
                    handleChange={onDropdownChange}
                    options={getBanks?.getBanks}
                    label="Bank"
                    placeholder="Pick a bank"
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className="p-mt-2">
            <div className="p-col-2 p-offset-10 p-text-right">
              <Button className="button p-text-uppercase" type="submit">
                Add Payment
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export { SupplierPayment }
