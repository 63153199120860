import { FormEvent, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AutoComplete, Dropdown, Input } from '../../components/form'
import {
  useAvaiableStockQuery,
  useCategoriesQuery,
  useGetBrandsQuery,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductColorsQuery,
  useGetUniqueProductNamesQuery,
  useGetUniqueProductSizesQuery,
  useAdjustStockMutation,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { Button } from 'primereact/button'
import { AvailabilityWrapper } from '../../components/AvailabilityWrapper'
import { arrayUnique } from '../../helpers/arrayUnique'
import { BiSearchAlt } from 'react-icons/bi'
import { AiOutlineNumber } from 'react-icons/ai'
import { RiNumbersLine } from 'react-icons/ri'
import { can } from '../../helpers/hasPermission'
import { useSelector } from 'react-redux'
import { AuthState } from '../../redux/auth/types'
import { AppState } from '../../redux/rootReducer'

const Availability = () => {
  const limitOptions = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ]

  const [cursor, setCursor] = useState('');
  const [limit, setLimit] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { data: codes } = useGetUniqueProductCodesQuery();
  const { data: names } = useGetUniqueProductNamesQuery();
  const { data: colors } = useGetUniqueProductColorsQuery();
  const { data: sizes } = useGetUniqueProductSizesQuery();
  const { data: brands } = useGetBrandsQuery();
  const { data: categories } = useCategoriesQuery();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [category, setCategory] = useState('');
  const [filteredNames, setFilteredNames] = useState<string[]>();
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredBrands, setFilteredBrands] = useState<string[]>();
  const [filteredCategories, setFilteredCategories] = useState<string[]>();
  const [filteredColors, setFilteredColors] = useState<string[]>();
  const { loading, data, client, fetchMore } = useAvaiableStockQuery({
    variables: { input: { limit, code, name, brand, category, size, color } },
    notifyOnNetworkStatusChange: true,
  });
  const [adjustStock] = useAdjustStockMutation();
  const [activePV, setActivePV] = useState(0);
  const [activeCategory, setActiveCategory] = useState('');
  const [activeBrand, setActiveBrand] = useState('');
  const [activeName, setActiveName] = useState('');
  const [activeCode, setActiveCode] = useState('');
  const [currentAdjustedStock, setCurrentAdjustedStock] = useState(0);
  const [error, setError] = useState('');
  const { user } = useSelector<AppState, AuthState>(state => state.auth);

  const dataDatableData = () => { 
    if (data?.getAvaiableStock.bgAvailable) {
      return data.getAvaiableStock.bgAvailable;
    } else if (data?.getAvaiableStock.ogfAvailable) { 
      return data.getAvaiableStock.ogfAvailable;
    } else if (data?.getAvaiableStock.ogffAvailable) {
      return data.getAvaiableStock.ogffAvailable;
     }
    else {
      return data?.getAvaiableStock.hcAvailable;
    }
  }

  useEffect(() => {
    setHasMore(data?.getAvaiableStock.hasMore!)
    if (data?.getAvaiableStock.hasMore) {
      const availableStock = dataDatableData()!;
      if (availableStock.length > 0) {
        setCursor(availableStock[availableStock.length - 1].id.toString())
      }
    }
  }, [loading]);

  const handleSize = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSize(e.value);
    client.resetStore();
  };

  const handleColor = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value);
    client.resetStore();
  };

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value);
    client.resetStore();
  };

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchName = (e: { originalEvent: Event; query: string }) => {
    let filteredNames: string[]
    filteredNames =
      names?.getUniqueProductNames.filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredNames(arrayUnique(filteredNames))
  }

  const searchColor = (e: { originalEvent: Event; query: string }) => {
    let filteredColors: string[]
    filteredColors =
      colors?.getUniqueProductColors.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredColors(arrayUnique(filteredColors))
  }

  const searchBrand = (e: { originalEvent: Event; query: string }) => {
    let filteredBrands: string[]
    filteredBrands =
      brands?.getBrands
        .filter((b) => {
          return b.name.toLowerCase().includes(e.query.toLowerCase())
        })
        .map((fb) => fb.name) || []
    setFilteredBrands(arrayUnique(filteredBrands))
  }

  const searchCategory = (e: { originalEvent: Event; query: string }) => {
    let filteredCategories: string[]
    if (categories && categories.categories.length > 0) {
      filteredCategories = categories.categories
        .filter((c) => {
          return c.name!.toLowerCase().includes(e.query.toLowerCase())
        })
        .map((fc) => fc.name) as string[]
      setFilteredCategories(arrayUnique(filteredCategories))
    }
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleAutoCompleteColor = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value)
  }

  const handleAutoCompleteName = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setName(e.value)
  }

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setBrand(e.value)
  }

  const handleAutoCompleteCategory = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCategory(e.value)
  }

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          code,
          name,
          brand,
          category,
          size,
          color
        },
      },
    })
  }

  const clearFilters = () => {
    setLimit('')
    setCode('')
    setName('')
    setBrand('')
    setCategory('')
    setColor('');
    setSize('');
    client.resetStore()
  }

  useEffect(() => {
    clearFilters()
  }, [])

  const tableHeader = (
    <div className="p-grid">
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Category"
        value={category}
        handleCompleteMethod={searchCategory}
        suggestions={filteredCategories}
        handleChange={handleAutoCompleteCategory}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Brand"
        value={brand}
        handleCompleteMethod={searchBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteBrand}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Name"
        value={name}
        handleCompleteMethod={searchName}
        suggestions={filteredNames}
        handleChange={handleAutoCompleteName}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Size"
        value={size}
        handleChange={handleSize}
        name="size"
        options={sizes?.getUniqueProductSizes.map((s) => ({
          label: s.toString(),
          value: s.toString(),
        }))}
        optionLabel="label"
        optionValue="value"
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search color"
        value={color}
        handleCompleteMethod={searchColor}
        suggestions={filteredColors}
        handleChange={handleAutoCompleteColor}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  );

  const handleAdjustStock = async (data: any) => {
    setActivePV(data.pv_id);
    setActiveBrand(data.brand);
    setActiveCategory(data.category);
    setActiveName(data.name);
    setActiveCode(data.code)
  }

  const saveAdjustStock = async () => {
    if (activePV !== 0)
      await adjustStock({ variables: { input: { 
        pv_id: activePV, 
        amount: currentAdjustedStock,
        brand: activeBrand,
        category: activeCategory,
        name: activeName,
        code: activeCode,
      } } });
    window.location.href = "/inventory/availability";
  }

  const handleStockChange = (e: FormEvent<HTMLInputElement>, data: any) => {
    setCurrentAdjustedStock(Number(e.currentTarget.value));
    if (Number(e.currentTarget.value) > Number(data.available)) {
      setError(`Amount can't be greater than ${Number(data.available)}`);
    } else { 
      setError('');
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <AvailabilityWrapper>
        <DataTable
          header={tableHeader}
          value={dataDatableData()!}
          rowGroupMode="rowspan"
          groupField="code"
          sortMode="single"
          sortField="code"
          sortOrder={1}
          emptyMessage="No records"
        >
          <Column field="category" header="Category"></Column>
          <Column field="brand" header="Brand"></Column>
          <Column field="code" header="Code"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="available" header="Quantity"></Column>
          <Column
            field="in_stock"
            header="Availability"
            body={(getAvaiableStock: any) =>
              getAvaiableStock.in_stock ? (
                <span
                  style={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#c8e6c9',
                    color: '#256029',
                    borderRadius: '2px',
                    padding: '.1em .3rem',
                    letterSpacing: '.3px',
                    textTransform: 'uppercase',
                  }}
                >
                  In stock
                </span>
              ) : (
                <span
                  style={{
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#feedaf',
                    color: '#8a5340',
                    borderRadius: '2px',
                    padding: '.1em .3rem',
                    letterSpacing: '.3px',
                    textTransform: 'uppercase',
                  }}
                >
                  Out of stock
                </span>
              )
            }
            ></Column>
            {can('view-update-stock', user?.role.permissions) && <Column header="Adjust Stock" body={(data: any) => {
              return <div className="p-d-flex">
                {data.pv_id === activePV && <Input
                  type="number"
                  columnSize="p-col-6"
                  icon={<RiNumbersLine />}
                  name="stockAmount"
                  placeholder="Adjust stock amount"
                  change={(e) => handleStockChange(e, data)}
                  errorMsg={error}
                />
                }
                {activePV === 0 ? <Button
                  onClick={() => handleAdjustStock(data)}
                  className="p-button-sm"
                  disabled={!!error}
                >
                  Update
                </Button> : <Button
                  onClick={saveAdjustStock}
                  className="p-button-sm"
                  disabled={!!error}
                >
                  Save
                </Button>}
              </div>
            }}></Column>}
        </DataTable>
      </AvailabilityWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} className="p-button-sm">
            Clear Filters
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            Load More
          </Button>
        </div>
      </div>
    </>
  )
}

export { Availability }
