import { SlideMenu } from 'primereact/slidemenu'
import { MdDashboard } from 'react-icons/md'
import { RiTruckFill } from 'react-icons/ri'
import {
  AiOutlineStock,
  AiOutlinePlus,
  AiOutlineUserAdd,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai'
import { BiDollar } from 'react-icons/bi'
import { IoCreate } from 'react-icons/io5'
import { BsListTask } from 'react-icons/bs'
import { MdPeople, MdSecurity } from 'react-icons/md'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { GiPayMoney, GiTakeMyMoney } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { HiDocumentReport } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { can } from '../helpers/hasPermission'
import { ImTarget } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { AppState } from '../redux/rootReducer'
import { AuthState } from '../redux/auth/types'

const Svg = styled.span.attrs((props) => ({
  className: props.className,
}))`
  margin-right: 4rem;
  font-size: 1.3rem;
`

const Label = styled.span`
  font-weight: 500;
  font-family: 'Roboto';
`

const Nav = styled(NavLink)`
  position: 'relative';
  width: 100%;
  display: block;
  text-decoration: none;
  margin-left: 50px;
  padding: 1.5rem;
  color: #f4f4fc;
`

const template = (
  item: any,
  options: any,
  icon: JSX.Element,
  path: string,
  hasChildren?: boolean,
): JSX.Element => {
  return (
    <Nav
      to={path}
      exact
      className={options.className}
      onClick={hasChildren && options.onClick}
    >
      <Svg className={options.iconClassName}>{icon}</Svg>
      <Label>{item.label}</Label>
      {hasChildren && (
        <MdKeyboardArrowRight
          style={{
            position: 'absolute',
            top: '49%',
            left: '13rem',
            transform: 'translateY(-50%)',
          }}
        />
      )}
    </Nav>
  )
}

const NavList = () => {
  const [items, setItems] = useState<any[]>();
  const { user } = useSelector<AppState, AuthState>(state => state.auth);
  useEffect(() => {
    const permissions = user?.role.permissions;
    const role = user?.role.name;
    setItems([
      {
        label: 'Dashboard',
        template: (item: any, options: any) =>
          template(item, options, <MdDashboard />, '/'),
      },
      {
        label: 'Supplier',
        style: {
          display: can(
            'view-supplier',
            permissions,
          )
            ? 'block'
            : 'none',
        },
        template: (item: any, options: any) =>
          template(item, options, <RiTruckFill />, '/supplier', true),
        items: [
          {
            label: 'New Supplier',
            style: {
              display: can(
                'create-supplier',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <IoCreate />, '/supplier/new'),
          },
          {
            label: 'Supplier List',
            style: {
              display: can(
                'view-supplier-list',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/supplier/list'),
          },
          {
            label: 'Outstanding',
            style: {
              display: can(
                'view-supplier-outstanding',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/supplier/outstanding',
              ),
          },
          {
            label: 'Settle Payments',
            style: {
              display: can(
                'view-supplier-settlement',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <GiTakeMyMoney />, '/supplier/payment'),
          },
          {
            label: 'Cheque Payments',
            style: {
              display: can(
                'view-supplier-cheque-payment',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <GiPayMoney />, '/supplier/cheque-payment'),
          },
        ],
      },
      {
        label: 'Inventory',
        style: {
          display: can(
            'view-inventory',
            permissions,
          )
            ? 'block'
            : 'none',
        },
        template: (item: any, options: any) =>
          template(item, options, <AiOutlineStock />, '/inventory', true),
        items: [
          {
            label: 'Product List',
            style: {
              display: can(
                'view-product-list',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <AiOutlinePlus />, '/', true),
            items: [
              {
                label: 'Category List',
                style: {
                  display: can(
                    'view-category-list',
                    permissions,
                  )
                    ? 'block'
                    : 'none',
                },
                template: (item: any, options: any) =>
                  template(
                    item,
                    options,
                    <HiDocumentReport />,
                    '/inventory/category/list',
                  ),
              },
              {
                label: 'Brand List',
                style: {
                  display: can(
                    'view-brand-list',
                    permissions,
                  )
                    ? 'block'
                    : 'none',
                },
                template: (item: any, options: any) =>
                  template(
                    item,
                    options,
                    <HiDocumentReport />,
                    '/inventory/brand/list',
                  ),
              },
              {
                label: 'Product List',
                style: {
                  display: can(
                    'view-main-product-list',
                    permissions,
                  )
                    ? 'block'
                    : 'none',
                },
                template: (item: any, options: any) =>
                  template(
                    item,
                    options,
                    <HiDocumentReport />,
                    '/inventory/main-product/list',
                  ),
              },
              {
                label: 'Specification List',
                style: {
                  display: can(
                    'view-sub-product-list',
                    permissions,
                  )
                    ? 'block'
                    : 'none',
                },
                template: (item: any, options: any) =>
                  template(
                    item,
                    options,
                    <HiDocumentReport />,
                    '/inventory/sub-product/list',
                  ),
              },
            ],
          },
          {
            label: 'Add Stock',
            style: {
              display: can(
                'view-add-stock',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <AiOutlinePlus />, '/inventory/add'),
          },
          {
            label: 'Purchase History',
            style: {
              display: can(
                'view-purchase-history',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/inventory/variance',
              ),
          },
          {
            label: 'Stock Availability',
            style: {
              display: can(
                'view-stock-availability',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/inventory/availability',
              ),
          },
          {
            label: 'Stock Value',
            style: {
              display: can(
                'view-stock-value',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/inventory/value',
              ),
          },
          {
            label: 'Stock Invoice',
            style: {
              display: can(
                'view-stock-invoice',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/inventory/invoice',
              ),
          },
          {
            label: 'Stock Quantity',
            style: {
              display: can(
                'view-stock-quantity',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <HiDocumentReport />,
                '/inventory/stock-quantity',
              ),
          },
        ],
      },
      {
        label: 'Sales',
        style: {
          display: can(
            'view-sales',
            permissions,
          )
            ? 'block'
            : 'none',
        },
        template: (item: any, options: any) =>
          template(item, options, <BiDollar />, '/sales', true),
        items: [
          {
            label: 'Create Receipt',
            style: {
              display: can(
                'view-create-receipt',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <IoCreate />, '/sales/receipt/create'),
          },
          {
            label: 'Receipt List',
            style: {
              display: can(
                'view-receipt-list',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/sales/receipt/list'),
          },
          {
            label: 'Exchange Receipt List',
            style: {
              display: can(
                'view-exchange-receipt-list',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/sales/exchange/list'),
          },
          {
            label: 'Sales Report',
            style: {
              display: can(
                'view-sales-report',
                permissions,
              )
               ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <HiDocumentReport />, '/sales/report'),
          }, 
          {
            label: 'Test Sales Report',
            style: {
              display: can(
                'view-sales-report',
                permissions,
              )
               ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <HiDocumentReport />, '/sales/test-report'),
          }, 
          {
            label: 'Sales Target',
            style: {
              display: can(
                'view-sales-target',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <ImTarget />, '/sales/target'),
          },
          {
            label: 'Export Sales',
            style: {
              display: can(
                'view-sales-target',
                permissions,
              ) && user?.branch?.id === 3
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <ImTarget />, '/sales/export'),
          },
        ],
      },
      {
        label: 'Customer',
        style: {
          display: can(
            'view-customer',
            permissions,
          )
            ? 'block'
            : 'none',
        },
        template: (item: any, options: any) =>
          template(item, options, <MdPeople />, '/customer', true),
        items: [
          {
            label: 'Create Customer',
            style: {
              display: can(
                'create-customer',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <IoCreate />, '/customer/create'),
          },
          {
            label: 'Customer List',
            style: {
              display: can(
                'view-customer-list',
                permissions,
              )
                ? 'block'
                : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/customer/list'),
          },
        ],
      },
      {
        label: 'Security',
        style: {
          display: role === 'Admin' ? 'block' : 'none',
        },
        template: (item: any, options: any) =>
          template(item, options, <MdSecurity />, '/security', true),
        items: [
          {
            label: 'Create User',
            style: {
              display: role === 'Admin' ? 'block' : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <AiOutlineUserAdd />,
                '/security/user/create',
              ),
          },
          {
            label: 'User List',
            style: {
              display: role === 'Admin' ? 'block' : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/security/user/list'),
          },
          {
            label: 'Crete Role',
            style: {
              display: role === 'Admin' ? 'block' : 'none',
            },
            template: (item: any, options: any) =>
              template(
                item,
                options,
                <AiOutlineUsergroupAdd />,
                '/security/role/create',
              ),
          },
          {
            label: 'Role List',
            style: {
              display: role === 'Admin' ? 'block' : 'none',
            },
            template: (item: any, options: any) =>
              template(item, options, <BsListTask />, '/security/role/list'),
          },
        ],
      },
    ])
  }, [user])
  return <SlideMenu menuWidth={350} model={items} />
}
export { NavList }
