import { ObjectSchema, ValidationError } from "yup"

export interface ClientErrors {
    field: string;
    message: string;
}

export const setSchemaErrors = (schema: ObjectSchema<any>, form: any, setErrors: React.Dispatch<React.SetStateAction<ClientErrors[]>>) => {
    schema
        .validate(form, { abortEarly: false })
        .catch((error: ValidationError) => {
            setErrors(validateErrors(error.inner));
        });
}

export const validateErrors = (errors: ValidationError[]): Array<ClientErrors> => {
    return errors.map(e => ({
        field: e.path!,
        message: e.message
    }));
}

export const validateField = (field: string, errors: ClientErrors[]) => {
    return errors.find(e => e.field === field)?.message
}

export const validateSchema = async (schema: ObjectSchema<any>, form: any): Promise<boolean> => {
    return await schema.isValid(form, { abortEarly: false });
}