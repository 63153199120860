import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import {
  Customer,
  useGetCustomersQuery,
  useDeleteCustomerMutation,
  GetCustomersQuery,
  GetCustomersDocument,
} from '../../generated/graphql';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from '../../components/form';
import { Dialog, Spinner } from '../../components';
import { EditCustomer } from '../../components/customer/EditCustomer';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import { confirmPopup } from 'primereact/confirmpopup';
import { showToast } from '../../helpers/showSuccessToast';

const CustomerList: FC = () => {
  const { loading, data } = useGetCustomersQuery();
  const toastSuccess = useRef<Toast>(null);
  const toastError = useRef<Toast>(null);
  const [deleteCustomer, { loading: deleteingCustomer }] =
    useDeleteCustomerMutation();
  const [selected, setSelected] = useState<Customer>();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    if (data?.getCustomers.customers) {
      setCustomers(data.getCustomers.customers);
    }
  }, [data, setCustomers]);

  const handleOnClickAction = async (
    e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: Customer
  ) => {
    if (action === 'edit') {
      setShowEdit(true);
      setSelected(data);
    } else {
      confirmPopup({
        target: e.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteCustomer({
            variables: {
              input: data.id,
            },
            update(cache, { data }) {
              try {
                const customers = cache.readQuery<GetCustomersQuery>({
                  query: GetCustomersDocument,
                });
                if (customers?.getCustomers.customers) {
                  let exisitngCustomers = [
                    ...customers?.getCustomers.customers,
                  ];
                  for (let c of exisitngCustomers) {
                    if (c.id === data?.deleteCustomer.customer?.id) {
                      c = { ...data.deleteCustomer.customer };
                    }
                    break;
                  }
                  cache.writeQuery<GetCustomersQuery>({
                    query: GetCustomersDocument,
                    data: {
                      getCustomers: {
                        customers: exisitngCustomers,
                      },
                    },
                  });
                }
              } catch (error) {}
            },
          });
          if (response.data?.deleteCustomer.customer) {
            showToast(toastSuccess, 'Customer has been deleted');
          } else {
            showToast(
              toastError,
              response.data?.deleteCustomer.errors![0].message!,
              'error'
            );
          }
        },
        reject: () => false,
      });
    }
  };

  return (
    <div className="datatable-responsive">
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Dialog
        visible={showEdit}
        header="Edit Customer"
        handleOnHide={() => setShowEdit(false)}
      >
        <EditCustomer
          success={toastSuccess}
          fail={toastError}
          customer={selected!}
          setShowEdit={setShowEdit}
        />
      </Dialog>
      <div className="form">
        {loading || deleteingCustomer ? (
          <Spinner />
        ) : (
          <div>
            {customers && (
              <DataTable
                value={customers}
                emptyMessage="No Customers"
                className="p-datatable-responsive"
              >
                <Column
                  header="Id"
                  body={(_: Customer, props: any) => props.rowIndex + 1}
                ></Column>
                <Column
                  sortable
                  sortField="first_name"
                  body={({ title, first_name, last_name }: Customer) => (
                    <>
                      {title}.{first_name} {last_name}
                    </>
                  )}
                  header="Name"
                ></Column>
                <Column header="Phone" field="phone" />
                <Column header="Points" field="points" />
                <Column
                  header="Joined Date"
                  field="joined"
                  body={(x: Customer) => format(Number(x.joined), 'Y-MM-dd')}
                />
                <Column header="NIC" field="nic" />
                <Column
                  header="Birth Date"
                  field="dob"
                  body={(x: Customer) => format(Number(x.dob), 'Y-MM-dd')}
                />
                <Column
                  header="Action"
                  body={(data: any) => (
                    <>
                      <Button
                        onClick={(e: any) =>
                          handleOnClickAction(e, 'edit', data)
                        }
                        type="button"
                        icon="pi pi-pencil"
                      />
                      <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={(e: any) =>
                          handleOnClickAction(e, 'delete', data)
                        }
                        type="button"
                        icon="pi pi-trash"
                      />
                    </>
                  )}
                  headerStyle={{ width: '8em', textAlign: 'center' }}
                  bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                />
              </DataTable>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export { CustomerList };
