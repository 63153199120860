export const arrayUnique = (array: Array<string>) => {
    let distinctArray: Array<string> = [];
    for (let v of array) {
        if (!distinctArray.includes(v)) {
            distinctArray.push(v);
        }
    }
    return distinctArray;
}

export const arrayObjectUnique = (array: Array<any> | undefined) => {
    if (!array) {
        return;
    }
    let distinctArray: Array<any> = [];
    for (let v of array) {
        if (!distinctArray.includes(v)) {
            distinctArray.push(v);
        }
    }
    return distinctArray;
}