import styled from 'styled-components';

export const ReceiptWrapper = styled.div`
  display: none;
  @media print {
    border: none;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
  }
`;
