import { FC } from 'react'
import { Dialog as PrimeDialog } from 'primereact/dialog'

interface DialogProps {
  header: string
  visible: boolean
  handleFooter?: JSX.Element
  width?: number
  handleOnHide: () => void
  maximized?: boolean
  maximizable?: boolean;
}

const Dialog: FC<DialogProps> = (props) => {
  const { width = 50, maximizable = true } = props
  return (
    <PrimeDialog
      header={props.header}
      maximized={props.maximized}
      maximizable={maximizable}
      modal
      closeOnEscape
      closable
      draggable={false}
      visible={props.visible}
      footer={props.handleFooter}
      style={{ width: `${width}vw`, maxHeight: '75vh', overflowY: 'scroll' }}
      onHide={props.handleOnHide}
    >
      {props.children}
    </PrimeDialog>
  )
}

export { Dialog }
