import { FC, useEffect, useState } from 'react';
import { useGetStockInvoiceMutation } from '../../generated/graphql';
import { useSelector } from 'react-redux';
import { AuthState } from '../../redux/auth/types';
import { AppState } from '../../redux/rootReducer';
import { format } from 'date-fns';
import { Spinner } from '../Spinner';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface InvoicePreviewProps {
    stockId: number | null;
}

const InvoicePreview: FC<InvoicePreviewProps> = ({ stockId }) => {
    const { user } = useSelector<AppState, AuthState>(state => state.auth);
    const [getInvoice] = useGetStockInvoiceMutation();
    const [invoice, setInvoice] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation<{ stockId: string }>();
    const params = useParams<{ stockId: string }>();
    const history = useHistory();

    useEffect(() => {
        getInvoice({ variables: { input: stockId ? stockId : parseFloat(params.stockId) } }).then(({ data }) => { 
            if (user && data?.getStockInvoice) { 
            const branchId = user.branch.id;
            if (branchId === 1) {
                setInvoice(data.getStockInvoice.bgInvoice!);
            } else if (branchId === 2) {
                setInvoice(data.getStockInvoice.ogfInvoice!);
            } else if (branchId === 3) { 
                setInvoice(data.getStockInvoice.hcInvoice!);
            } else {
                setInvoice(data.getStockInvoice.ogffInvoice!);
            }
            setLoading(false);    
        }
        });
    }, [stockId, user, getInvoice, params]);

    useEffect(() => { 
        if (invoice.length > 0 && location.pathname.includes('/inventory/invoice/print')) { 
            setLoading(false);
            setTimeout(() => {
                window.print();
                history.push('/inventory/invoice');
             }, 1000);
        }
    }, [invoice, location, history]);
    
    return (
        loading ? <Spinner /> :
        <div className={location.pathname.includes('/inventory/invoice/print') ? 'invoice-print' : 'invoice-view'}>
            <div className="invoice-header">
            <h1>INVOICE</h1>
            <p>Saheli Shoes (Pvt) Ltd.</p>
        </div>
        <div className="invoice-details">
            <div>
                <p>Branch: {user?.branch.name}</p>
                <p>Date: {format(Number(invoice[0]?.date), 'Y-MM-dd')}</p>
            </div>
            <div>
                <p>Inv. NO: {invoice[0]?.stock_id}</p>
            </div>
        </div>
        <table className="invoice-table">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Supplier</th>
                    <th>Category</th>
                    <th>Code</th>
                    <th>Colour</th>
                    <th>Qty</th>
                </tr>
            </thead>
            <tbody>          
                {invoice.map((i, index) => <tr key={i.id}>
                    <td>{index+1}</td>
                    <td>{i.supplier}</td>
                    <td>{i.category}</td>
                    <td>{i.code}</td>
                    <td>{i.color}</td>
                    <td>{i.quantity}</td>
                </tr>)}
                <tr>
                    <td colSpan={5}>Total</td>
                    <td>{invoice.reduce((t, i) => t + i.quantity, 0)}</td>
                </tr>
            </tbody>    
        </table>
        <div className="signature-section">
            <div>
                <p>Prepared by : ____________________________</p>
                <p>Received by : ____________________________</p>
            </div>
            <div>
                <p>Issued by : ______________________________</p>
                <p>Checked by : ____________________________</p>
            </div>
        </div>
    </div>
    );
};

export { InvoicePreview };