import { FC } from 'react';
import { Main, Navigation, Header, NavList } from '../components';
import { Line } from '../components/utilities';
import { View } from '../components/View';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { useLogoutMutation } from '../generated/graphql';

const Home: FC = () => {
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    const response = await logout();
    if (response.data?.logout) {
      localStorage.removeItem('atci');
      window.location.href = '/login';
    }
  };

  return (
    <Main>
      <Navigation>
        <Header />
        <NavList />
        <Line width="80%" margin="25rem 0 0 2rem" />
        <div
          onClick={handleLogout}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 4.7rem',
            marginTop: '2.5rem',
            color: '#f4f4fc',
            cursor: 'pointer',
          }}
        >
          <RiLogoutBoxRLine fontSize="1.3rem" />
          <div style={{ marginLeft: '4rem' }}>Logout</div>
        </div>
      </Navigation>
      <View />
    </Main>
  );
};
export { Home };
