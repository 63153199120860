import styled from 'styled-components';

export const ReceiptInfo = styled.div`
  @media print {
    display: flex;
    justify-content: space-between;
    margin: 0.75rem 0;
    text-transform: uppercase;
  }
`;
