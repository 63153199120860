import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { BgStockAvailable, ChequePayment, ChequePaymentResponse, OgfStockAvailable, OrderList, OrderListResponse, ProductVariationResponse, ProductVariationResponseType, ProductVariationType, ProductVariationTypeResponse, SalesReport, SalesResponse, SalesTargetResponse, StockAvailableResponse, StockVariance, StockVarianceResponse, StockValueResponse, OgfStockValue, BgStockValue, BgSaleAchieved, OgfSaleAchieved, TestSalesResponse, TestSalesReport, HcSaleAchieved, HcStockAvailable, HcStockValue, StockInvoiceResponse, OgfInvoice, BgInvoice, HcmInvoice, UpdateStockQuantity, UpdateStockQuantityResponse, OgffStockAvailable, OgffStockValue, OgffInvoice, OgffSaleAchieved } from "../generated/graphql";

export const productTypesMerge = (existing: ProductVariationTypeResponse, incoming: ProductVariationTypeResponse, readField: ReadFieldFunction): ProductVariationTypeResponse => {
    let merged: ProductVariationType[] = []
    if (existing && existing.productVariationTypes.length > 0) {
        merged = [...existing.productVariationTypes]
    }
    incoming.productVariationTypes.forEach((pvt) => {
        const index = merged.findIndex(
            (m) => readField('id', m) === readField('id', pvt),
        )
        if (index === -1) {
            merged.push(pvt)
        } else {
            merged[index] = pvt
        }
    })
    return {
        hasMore: incoming.hasMore,
        productVariationTypes: merged,
    }
}

export const productVariationMerge = (existing: ProductVariationResponse, incoming: ProductVariationResponse, readField: ReadFieldFunction): ProductVariationResponse => {
    let merged: ProductVariationResponseType[] = []
    if (existing && existing.productVariaitons.length > 0) {
        merged = [...existing.productVariaitons]
    }
    incoming.productVariaitons.forEach((pv) => {
        const index = merged.findIndex(
            (m) => readField('id', m) === readField('id', pv),
        )
        if (index === -1) {
            merged.push(pv)
        } else {
            merged[index] = pv
        }
    })
    return {
        hasMore: incoming.hasMore,
        productVariaitons: merged,
    }
}

export const stockVarianceMerge = (existing: StockVarianceResponse, incoming: StockVarianceResponse, readField: ReadFieldFunction): StockVarianceResponse => {
    let merged: StockVariance[] = []
    if (existing && existing.stockVariance.length > 0) {
        merged = [...existing.stockVariance]
    }
    incoming.stockVariance.forEach((sv) => {
        const index = merged.findIndex(
            (m) => readField('spv_id', m) === readField('spv_id', sv)
        )
        if (index === -1) {
            merged.push(sv)
        } else {
            merged[index] = sv
        }
    })
    return {
        hasMore: incoming.hasMore,
        stockVariance: merged,
    }
}

export const availableStockMerge = (existing: StockAvailableResponse, incoming: StockAvailableResponse, readField: ReadFieldFunction): StockAvailableResponse => {
    let mergedOgf: OgfStockAvailable[] = []
    let mergedOgff: OgffStockAvailable[] = []
    let mergedBg: BgStockAvailable[] = []
    let mergedHc: HcStockAvailable[] = []
    if (existing && existing.ogfAvailable) {
        mergedOgf = [...existing.ogfAvailable]
    }

    if (existing && existing.ogffAvailable) {
        mergedOgff = [...existing.ogffAvailable]
    }
    if (existing && existing.bgAvailable) {
        mergedBg = [...existing.bgAvailable]
    }
    if (existing && existing.hcAvailable) {
        mergedHc = [...existing.hcAvailable]
    }
    incoming.ogfAvailable && incoming.ogfAvailable!.forEach(ogf => {
        const index = mergedOgf.findIndex(m => readField('id', m) === readField('id', ogf))
        if (index === -1) {
            mergedOgf.push(ogf)
        } else {
            mergedOgf[index] = ogf
        }
    })
    incoming.ogffAvailable && incoming.ogffAvailable!.forEach(ogff => {
        const index = mergedOgff.findIndex(m => readField('id', m) === readField('id', ogff))
        if (index === -1) {
            mergedOgff.push(ogff)
        } else {
            mergedOgff[index] = ogff
        }
    })
    incoming.bgAvailable && incoming.bgAvailable!.forEach(bg => {
        const index = mergedBg.findIndex(m => readField('id', m) === readField('id', bg))
        if (index === -1) {
            mergedBg.push(bg)
        } else {
            mergedBg[index] = bg
        }
    })
    incoming.hcAvailable && incoming.hcAvailable!.forEach(hc => {
        const index = mergedHc.findIndex(m => readField('id', m) === readField('id', hc))
        if (index === -1) {
            mergedHc.push(hc)
        } else {
            mergedHc[index] = hc
        }
    })
    return { hasMore: incoming.hasMore, ogfAvailable: mergedOgf.length > 0 ? mergedOgf : null, ogffAvailable: mergedOgff.length > 0 ? mergedOgff : null, bgAvailable: mergedBg.length > 0 ? mergedBg : null, hcAvailable: mergedHc.length > 0 ? mergedHc : null }
}

export const stockValueMerge = (existing: StockValueResponse, incoming: StockValueResponse, readField: ReadFieldFunction): StockValueResponse => {
    let mergedOgf: OgfStockValue[] = []
    let mergedOgff: OgffStockValue[] = []
    let mergedBg: BgStockValue[] = []
    let mergedHc: HcStockValue[] = []
    if (existing && existing.ogfValue) {
        mergedOgf = [...existing.ogfValue]
    }
    if (existing && existing.ogffValue) {
        mergedOgff = [...existing.ogffValue]
    }
    if (existing && existing.bgValue) {
        mergedBg = [...existing.bgValue]
    }
    if (existing && existing.hcValue) {
        mergedHc = [...existing.hcValue]
    }
    incoming.ogfValue && incoming.ogfValue!.forEach(ogf => {
        const index = mergedOgf.findIndex(m => readField('id', m) === readField('id', ogf))
        if (index === -1) {
            mergedOgf.push(ogf)
        } else {
            mergedOgf[index] = ogf
        }
    })
    incoming.ogffValue && incoming.ogffValue!.forEach(ogff => {
        const index = mergedOgff.findIndex(m => readField('id', m) === readField('id', ogff))
        if (index === -1) {
            mergedOgff.push(ogff)
        } else {
            mergedOgff[index] = ogff
        }
    })
    incoming.bgValue && incoming.bgValue!.forEach(bg => {
        const index = mergedBg.findIndex(m => readField('id', m) === readField('id', bg))
        if (index === -1) {
            mergedBg.push(bg)
        } else {
            mergedBg[index] = bg
        }
    })
    incoming.hcValue && incoming.hcValue!.forEach(hc => {
        const index = mergedHc.findIndex(m => readField('id', m) === readField('id', hc))
        if (index === -1) {
            mergedHc.push(hc)
        } else {
            mergedHc[index] = hc
        }
    })
    return { hasMore: incoming.hasMore, ogfValue: mergedOgf.length > 0 ? mergedOgf : null, ogffValue: mergedOgff.length > 0 ? mergedOgff : null, bgValue: mergedBg.length > 0 ? mergedBg : null, hcValue: mergedHc.length > 0 ? mergedHc : null, total: incoming.total, quantity: incoming.quantity }
}

export const updateStockQuantityMerge = (existing: UpdateStockQuantityResponse, incoming: UpdateStockQuantityResponse, readField: ReadFieldFunction): UpdateStockQuantityResponse => {
    let mergedStock: UpdateStockQuantity[] = []

    if (existing && existing.stock) {
        mergedStock = [...existing.stock]
    }
    incoming.stock && incoming.stock!.forEach(stock => {
        const index = mergedStock.findIndex(m => readField('id', m) === readField('id', stock))
        if (index === -1) {
            mergedStock.push(stock)
        } else {
            mergedStock[index] = stock
        }
    })
    return { hasMore: incoming.hasMore, stock: mergedStock }
}

export const stockInvoiceMerge = (existing: StockInvoiceResponse, incoming: StockInvoiceResponse, readField: ReadFieldFunction): StockInvoiceResponse => {
    let mergedOgf: OgfInvoice[] = []
    let mergedOgff: OgffInvoice[] = []
    let mergedBg: BgInvoice[] = []
    let mergedHc: HcmInvoice[] = []
    if (existing && existing.ogfInvoice) {
        mergedOgf = [...existing.ogfInvoice]
    }
    if (existing && existing.ogffInvoice) {
        mergedOgff = [...existing.ogffInvoice]
    }
    if (existing && existing.bgInvoice) {
        mergedBg = [...existing.bgInvoice]
    }
    if (existing && existing.hcInvoice) {
        mergedHc = [...existing.hcInvoice]
    }
    incoming.ogfInvoice && incoming.ogfInvoice!.forEach(ogf => {
        const index = mergedOgf.findIndex(m => readField('id', m) === readField('id', ogf))
        if (index === -1) {
            mergedOgf.push(ogf)
        } else {
            mergedOgf[index] = ogf
        }
    })
    incoming.ogffInvoice && incoming.ogffInvoice!.forEach(ogff => {
        const index = mergedOgff.findIndex(m => readField('id', m) === readField('id', ogff))
        if (index === -1) {
            mergedOgff.push(ogff)
        } else {
            mergedOgff[index] = ogff
        }
    })
    incoming.bgInvoice && incoming.bgInvoice!.forEach(bg => {
        const index = mergedBg.findIndex(m => readField('id', m) === readField('id', bg))
        if (index === -1) {
            mergedBg.push(bg)
        } else {
            mergedBg[index] = bg
        }
    })
    incoming.hcInvoice && incoming.hcInvoice!.forEach(hc => {
        const index = mergedHc.findIndex(m => readField('id', m) === readField('id', hc))
        if (index === -1) {
            mergedHc.push(hc)
        } else {
            mergedHc[index] = hc
        }
    })
    return { hasMore: incoming.hasMore, ogfInvoice: mergedOgf.length > 0 ? mergedOgf : null, ogffInvoice: mergedOgff.length > 0 ? mergedOgff : null, bgInvoice: mergedBg.length > 0 ? mergedBg : null, hcInvoice: mergedHc.length > 0 ? mergedHc : null }
}

export const chequePaymentsMerge = (existing: ChequePaymentResponse, incoming: ChequePaymentResponse, readField: ReadFieldFunction): ChequePaymentResponse => {
    let merged: ChequePayment[] = []
    if (existing && existing.chequePayments.length > 0) {
        merged = [...existing.chequePayments];
    }
    incoming.chequePayments.forEach((cp) => {
        const index = merged.findIndex(
            (m) => readField('id', m) === readField('id', cp),
        )
        if (index === -1) {
            merged.push(cp)
        } else {
            merged[index] = cp
        }
    })
    return {
        hasMore: incoming.hasMore,
        totalAmount: incoming.totalAmount,
        chequePayments: merged,
    }
}

export const salesTargetsMerge = (existing: SalesTargetResponse, incoming: SalesTargetResponse, readField: ReadFieldFunction): SalesTargetResponse => {
    let bgMerged: BgSaleAchieved[] = []
    let ofgMerged: OgfSaleAchieved[] = [];
    let ogffMerged: OgffSaleAchieved[] = [];
    let hcMerged: HcSaleAchieved[] = [];
    if (existing && existing.bgSaleAchieved!.length > 0) {
        bgMerged = [...existing.bgSaleAchieved!];
    }
    if (existing && existing.ogfSaleAchieved!.length > 0) {
        ofgMerged = [...existing.ogfSaleAchieved!];
    }
    if (existing && existing.ogffSaleAchieved!.length > 0) {
        ogffMerged = [...existing.ogffSaleAchieved!];
    }
    if (existing && existing.hcSaleAchieved!.length > 0) {
        hcMerged = [...existing.hcSaleAchieved!];
    }
    incoming.bgSaleAchieved && incoming.bgSaleAchieved.forEach((sa) => {
        const index = bgMerged.findIndex(
            (m) => readField('id', m) === readField('id', sa),
        )
        if (index === -1) {
            bgMerged.push(sa)
        } else {
            bgMerged[index] = sa
        }
    })
    incoming.ogfSaleAchieved && incoming.ogfSaleAchieved.forEach((sa) => {
        const index = ofgMerged.findIndex(
            (m) => readField('id', m) === readField('id', sa),
        )
        if (index === -1) {
            ofgMerged.push(sa)
        } else {
            ofgMerged[index] = sa
        }
    })
    incoming.ogffSaleAchieved && incoming.ogffSaleAchieved.forEach((sa) => {
        const index = ogffMerged.findIndex(
            (m) => readField('id', m) === readField('id', sa),
        )
        if (index === -1) {
            ogffMerged.push(sa)
        } else {
            ogffMerged[index] = sa
        }
    })
    incoming.hcSaleAchieved && incoming.hcSaleAchieved.forEach((sa) => {
        const index = hcMerged.findIndex(
            (m) => readField('id', m) === readField('id', sa),
        )
        if (index === -1) {
            hcMerged.push(sa)
        } else {
            hcMerged[index] = sa
        }
    })
    return {
        hasMore: incoming.hasMore,
        errors: incoming.errors,
        ogfSaleAchieved: ofgMerged,
        ogffSaleAchieved: ogffMerged,
        bgSaleAchieved: bgMerged,
        hcSaleAchieved: hcMerged
    }
}

export const orderListMerge = (existing: OrderListResponse, incoming: OrderListResponse, readField: ReadFieldFunction): OrderListResponse => {
    let merged: OrderList[] = []
    if (existing && existing.orderList.length > 0) {
        merged = [...existing.orderList];
    }
    if (incoming && incoming.orderList.length > 0) {
        incoming.orderList.forEach(ol => {
            const index = merged.findIndex(m => readField('id', ol) === readField('id', m));
            if (index !== -1) {
                merged[index] = ol;
            } else {
                merged.push(ol);
            }
        });
    }
    return {
        hasMore: incoming.hasMore,
        orderList: merged
    }
}

export const getSalesMerge = (existing: SalesResponse, incoming: SalesResponse, readField: ReadFieldFunction): SalesResponse => {
    let merged: SalesReport[] = [];
    if (existing && existing.sales && existing.sales.length > 0) {
        merged = [...existing.sales];
    }
    if (incoming && incoming.sales && incoming.sales.length > 0) {
        incoming.sales.forEach(s => {
            const index = merged.findIndex(m => readField('id', s) === readField('id', m));
            if (index !== -1) {
                merged[index] = s;
            } else {
                merged.push(s);
            }
        });
    }
    return {
        totalSale: incoming.totalSale,
        totalProfit: incoming.totalProfit,
        itemsSold: incoming.itemsSold,
        errors: incoming.errors,
        hasMore: incoming.hasMore,
        sales: merged
    };
}

export const getTestSalesMerge = (existing: TestSalesResponse, incoming: TestSalesResponse, readField: ReadFieldFunction): TestSalesResponse => {
    let merged: TestSalesReport[] = [];
    if (existing && existing.sales && existing.sales.length > 0) {
        merged = [...existing.sales];
    }
    if (incoming && incoming.sales && incoming.sales.length > 0) {
        incoming.sales.forEach(s => {
            const index = merged.findIndex(m => readField('id', s) === readField('id', m));
            if (index !== -1) {
                merged[index] = s;
            } else {
                merged.push(s);
            }
        });
    }
    return {
        totalSale: incoming.totalSale,
        totalProfit: incoming.totalProfit,
        errors: incoming.errors,
        hasMore: incoming.hasMore,
        sales: merged
    };
}