import { ComponentType, FC } from 'react'
import {
  Redirect,
  RouteProps,
  Route,
  RouteComponentProps,
} from 'react-router-dom'
import { useMeQuery } from '../generated/graphql'
import { Spinner } from './Spinner'

interface AuthRouteProps {
  component: ComponentType<any>
}

const AuthRoute: FC<AuthRouteProps & RouteProps> = (props) => {
  const { loading, data } = useMeQuery();
  if (loading) {
    return <Spinner />
  }
  if ((data?.me.errors && data.me.errors.length > 0) || !data?.me.user) {
    return <Redirect to="/login" />
  }
  const { component: _, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps: RouteComponentProps<{}>) => {
        const { component: Component } = props
        return <Component {...routeProps} />
      }}
    />
  )
}
export { AuthRoute };