import { FC, useState, MouseEvent } from 'react'
import {
  DeleteSupplierMutation,
  GetSupplierListDocument,
  GetSupplierListQuery,
  Supplier,
  SupplierAdvance,
  SupplierContact,
  useDeleteSupplierMutation,
  useGetSupplierListQuery,
} from '../../generated/graphql'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog, Spinner } from '../../components'
import { EditSupplier } from '../../components/supplier'
import { ApolloCache } from '@apollo/client'
import { confirmPopup } from 'primereact/confirmpopup'
import { RouteComponentProps } from 'react-router-dom'
import { BiSearchAlt } from 'react-icons/bi'
import { AutoComplete } from '../../components/form'

export interface SupplierType {
  id: number
  name: string
  address?: string
  creditPeriod?: number
  supplierAdvance?: SupplierAdvance[] | undefined
  supplierContacts?: SupplierContact[]
}

const SupplierList: FC<RouteComponentProps> = ({ history }) => {
  const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const { loading, data } = useGetSupplierListQuery({
    onCompleted: ({ getSupplierList }) => {
      setSuppliers(getSupplierList)
      setFilteredSuppliers(getSupplierList.map((s) => s.name));
    },
  });
  const [selected, setSelected] = useState<SupplierType>();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [deleteSupplier] = useDeleteSupplierMutation();
  const [search, setSearch] = useState('');

  const handleOnClickAction = (
    e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: SupplierType,
  ) => {
    if (action === 'edit') {
      setShowEdit(true)
      setSelected(data)
    }
    if (action === 'delete') {
      confirmPopup({
        target: e.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          deleteSupplier({
            variables: { id: Number(data.id) },
            update(cache: ApolloCache<DeleteSupplierMutation>) {
              const suppliers = cache.readQuery<GetSupplierListQuery>({
                query: GetSupplierListDocument,
              })
              if (suppliers) {
                const updateSuppliers = suppliers.getSupplierList.filter(
                  (s) => Number(s.id) !== data.id,
                )
                cache.writeQuery<GetSupplierListQuery>({
                  query: GetSupplierListDocument,
                  data: {
                    getSupplierList: updateSuppliers,
                  },
                })
              }
            },
          })
        },
        reject: () => false,
      })
    }
  }

  const handleAutoCompleteChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    if (e.target.value === '') {
      setSuppliers(data?.getSupplierList || [])
    }
    setSearch(e.target.value)
  }

  const handleAutoCompleteMethod = (e: {
    originalEvent: Event
    query: string
  }) => {
    const filterSuppliers = data?.getSupplierList.map((s) => s.name) || []
    setFilteredSuppliers(
      filterSuppliers.filter((s) =>
        s.toLowerCase().includes(e.query.toLowerCase()),
      ),
    )
  }

  const handleAutoCompleteSelect = (e: {
    originalEvent: Event
    value: any
  }) => {
    const suppliers = data?.getSupplierList || []
    setSuppliers(suppliers.filter((s) => s.name === e.value))
  }

  const tableHeader = () => (
    <div className="p-grid">
      <AutoComplete
        icon={<BiSearchAlt color="#02c0f8" />}
        value={search}
        dropdown={false}
        placeholder="Search by name"
        handleCompleteMethod={handleAutoCompleteMethod}
        handleChange={handleAutoCompleteChange}
        handleSelect={handleAutoCompleteSelect}
        suggestions={filteredSuppliers}
      />
    </div>
  )

  return (
    <div>
      <Dialog
        visible={showEdit}
        header="Edit Supplier"
        handleOnHide={() => setShowEdit(false)}
      >
        <EditSupplier supplier={selected} setShowEdit={setShowEdit} />
      </Dialog>
      <div className="form">
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            header={tableHeader()}
            value={suppliers}
            emptyMessage="No suppliers"
            className="p-datatable-responsive"
          >
            <Column
              header="Id"
              body={(_: any, props: any) => props.rowIndex + 1}
            ></Column>
            <Column field="name" header="Name"></Column>
            <Column field="address" header="Address"></Column>
            <Column
              header="Contact"
              body={(data: Supplier) =>
                data.supplierContacts!.map((c) => c.contact).join(' / ')
              }
            ></Column>
            <Column field="creditPeriod" header="Credit Period"></Column>
            <Column
              header="Action"
              body={(data: SupplierType) => (
                <>
                  <Button
                    tooltip="Update Supplier"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-pencil"
                    className="p-button-text p-button-sm"
                    onClick={(e) => handleOnClickAction(e, 'edit', data)}
                    type="button"
                  />
                  <Button
                    tooltip="Delete Supplier"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-trash"
                    className="p-button-text p-button-sm"
                    onClick={(e) => handleOnClickAction(e, 'delete', data)}
                    type="button"
                  />
                  <Button
                    tooltip="Supplier Return"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-replay"
                    className="p-button-text p-button-sm"
                    onClick={() => history.push(`/supplier/${data.id}/return`)}
                    type="button"
                  />
                  <Button
                    tooltip="Payment History"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-wallet"
                    className="p-button-text p-button-sm"
                    onClick={() =>
                      history.push(`/supplier/${data.id}/history/payment`)
                    }
                    type="button"
                  />
                  {/* <Button
                    tooltip="Settlements"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-check"
                    className="p-button-text p-button-sm"
                    onClick={() =>
                      history.push(`/supplier/${data.id}/history/settlement`)
                    }
                    type="button"
                  /> */}
                </>
              )}
            />
          </DataTable>
        )}
      </div>
    </div>
  )
}

export { SupplierList }
