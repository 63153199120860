import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  AvaiableStockDocument,
  GetProductTypesDocument,
  ProductVariationType,
  useDeleteProductTypeMutation,
  useGetBrandsQuery,
  useGetProductTypesQuery,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductNamesQuery,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { Button } from 'primereact/button'
import { useRef, useState, MouseEvent } from 'react'
import { Toast } from 'primereact/toast'
import { Dialog } from '../../components'
import { AddMainProduct } from '../../components/inventory'
import { confirmPopup } from 'primereact/confirmpopup'
import { useEffect } from 'react'
import { AutoComplete, Dropdown } from '../../components/form'
import { DataTableWrapper } from '../../components/DataTableWrapper'
import { BiSearchAlt, BiSortDown } from 'react-icons/bi'
import { arrayUnique } from '../../helpers/arrayUnique'
import { AiOutlineNumber } from 'react-icons/ai'

const ProductVariationTypeList = () => {
  const limitOptions = [
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '75', value: '75' },
    { label: '100', value: '100' },
  ]
  const [brand, setBrand] = useState('')
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [limit, setLimit] = useState('')
  const [cursor, setCursor] = useState('')
  const [order, setOrder] = useState('DESC')
  const [addMainProduct, setAddMainProduct] = useState(false)
  const { loading, data, fetchMore, client } = useGetProductTypesQuery({
    variables: { input: { limit, code, name, order, brand } },
    notifyOnNetworkStatusChange: true,
  })
  const [filteredBrands, setFilteredBrands] = useState<string[]>()
  const [filteredCodes, setFilteredCodes] = useState<string[]>()
  const [filteredNames, setFilteredNames] = useState<string[]>()
  const [productType, setProductType] = useState<ProductVariationType>()
  const { data: codes } = useGetUniqueProductCodesQuery()
  const { data: names } = useGetUniqueProductNamesQuery()
  const { data: brands } = useGetBrandsQuery();
  const [show, setShow] = useState(false)
  const toastSuccess = useRef<Toast>(null)
  const toastError = useRef<Toast>(null)
  const [deleteProductType] = useDeleteProductTypeMutation()

  useEffect(() => {
    setHasMore(data?.getProductTypes.hasMore!)
    if (data?.getProductTypes.hasMore) {
      const productTypes = data?.getProductTypes.productVariationTypes || []
      if (productTypes.length > 0) {
        setCursor(productTypes[productTypes.length - 1].id.toString())
      }
    }
  }, [loading])

  const handleOnClickAction = (
    e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: ProductVariationType,
  ) => {
    if (action === 'edit') {
      setProductType(data)
      setShow(true)
    }

    if (action === 'delete') {
      confirmPopup({
        target: e.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteProductType({
            variables: { input: data.id! },
            update: (cache, { data }) => {
              if (data?.deleteProductType.productVairationType) {
                const id = cache.identify(
                  data?.deleteProductType.productVairationType,
                )
                cache.evict({ id })
              }
            },
            refetchQueries: [
              {
                query: GetProductTypesDocument,
                variables: { input: { limit, code, name, order } },
              },
              {
                query: AvaiableStockDocument,
                variables: { input: { limit: '25' } },
              },
            ],
          })
          if (response.data?.deleteProductType.productVairationType) {
            showSuccess('Main product has been deleted')
          } else {
            showError(response.data?.deleteProductType.errors![0].message!)
          }
        },
        reject: () => false,
      })
    }
  }

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 5000,
      })
    }
  }

  const showError = (message: string) => {
    if (toastError && toastError.current) {
      toastError.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 5000,
      })
    }
  }

  const searchBrand = (e: { originalEvent: Event; query: string }) => {
    let filteredBrands: string[]
    filteredBrands =
      brands?.getBrands.map(b => b.name).filter((b) => {
        return b.toLowerCase().includes(e.query.toLowerCase())
      }) || [];
    setFilteredBrands(arrayUnique(filteredBrands))
  }

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((p) => {
        return p.toLowerCase().startsWith(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchName = (e: { originalEvent: Event; query: string }) => {
    let filteredNames: string[]
    filteredNames =
      names?.getUniqueProductNames.filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredNames(arrayUnique(filteredNames))
  }

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value)
    setCursor('')
    client.resetStore()
  }

  const handleOrder = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setOrder(e.value)
    client.resetStore()
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          code,
          name,
          order,
          brand
        },
      },
    })
  }

  const clearFilters = () => {
    setLimit('')
    setCode('')
    setName('')
    setBrand('');
    setOrder('DESC')
    client.resetStore()
  }

  useEffect(() => {
    clearFilters()
  }, [])

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setBrand(e.value)
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleAutoCompleteName = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setName(e.value)
  }

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const tableHeader = (
    <div className="p-grid">
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Brand"
        value={brand}
        handleCompleteMethod={searchBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteBrand}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Name"
        value={name}
        handleCompleteMethod={searchName}
        suggestions={filteredNames}
        handleChange={handleAutoCompleteName}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        placeholder="Record Limit"
        icon={<AiOutlineNumber color="#01c0f8" />}
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
      {/* <Dropdown
        columnSize="p-col-3"
        placeholder="Sort Records"
        icon={<BiSortDown color="#02c0f8" />}
        value={order}
        handleChange={handleOrder}
        name="order"
        options={[
          { value: 'ASC', label: 'Oldest First' },
          { value: 'DESC', label: 'Latest First' },
        ]}
        optionLabel="label"
        optionValue="value"
      /> */}
    </div>
  )

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Dialog
        header="Add New Category"
        visible={show}
        handleOnHide={() => setShow(false)}
      >
        <AddMainProduct
          hideDialog={() => setShow(false)}
          toastSuccess={showSuccess}
          toastError={showError}
          productType={productType}
          edit
        />
      </Dialog>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <Button
          onClick={() => setAddMainProduct(true)}
          className="p-mb-3 p-button-sm"
          type="button"
        >
          <i className="pi pi-plus p-mr-2" /> New
        </Button>
      </div>
      <Dialog
        header="Add Main Product"
        visible={addMainProduct}
        handleOnHide={() => setAddMainProduct(false)}
      >
        <AddMainProduct
          hideDialog={() => setAddMainProduct(false)}
          toastSuccess={showSuccess}
          toastError={showError}
        />
      </Dialog>
      <DataTableWrapper>
        <DataTable
          header={tableHeader}
          value={data?.getProductTypes.productVariationTypes}
          emptyMessage="No Main Products"
          className="p-datatable-responsive"
        >
          <Column
            header="Id"
            body={(_: any, props: any) => props.rowIndex + 1}
          />
          <Column header="Brand" field="product.name" sortable />
          <Column header="Name" field="name" sortable />
          <Column header="Code" field="code" />
          <Column header="Size" field="size" />
          <Column
            header="Action"
            body={(data: ProductVariationType) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  tooltip={`Edit ${data.name}`}
                  tooltipOptions={{ position: 'bottom' }}
                  className="p-button-text p-button-sm"
                  onClick={(e: any) => handleOnClickAction(e, 'edit', data)}
                  type="button"
                />
                <Button
                  icon="pi pi-trash"
                  tooltip={`Delete ${data.name}`}
                  tooltipOptions={{ position: 'bottom' }}
                  className="p-button-text p-button-sm"
                  style={{ marginLeft: '1rem' }}
                  onClick={(e: any) => handleOnClickAction(e, 'delete', data)}
                  type="button"
                />
              </>
            )}
            headerStyle={{ width: '8em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
        </DataTable>
      </DataTableWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} type="button" className="p-button-sm">
            {loading ? 'Loading' : 'Clear Filters'}
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            {loading ? 'Loading' : 'Load More'}
          </Button>
        </div>
      </div>
    </>
  )
}

export { ProductVariationTypeList };