import { FC, FormEvent, useEffect, useState } from 'react';
import {
  ClientErrors,
  setSchemaErrors,
  validateField,
  validateSchema,
} from '../../helpers/handleClientValidation';
import { Dropdown, Input } from '../form';
import * as Yup from 'yup';
import { Customer } from '../../generated/graphql';
import { titles } from '../../helpers/getTitles';
import { Button } from 'primereact/button';
import { MdDateRange } from 'react-icons/md';
import { IoMdPerson } from 'react-icons/io';
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineIdcard } from 'react-icons/ai';
import { HiPhone } from 'react-icons/hi';
import { DatePicker } from '../form/DatePicker';

export interface CustomerState {
  title: string;
  firstName: string;
  lastName: string;
  phone: string;
  joined: Date;
  nic: string;
  dob?: Date;
}

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  joined: Yup.date().required('Join date is required'),
  phone: Yup.string().required('Phone is required'),
});

interface CustomerFormProps {
  buttonText: string;
  customer?: Customer;
  handleSubmit: (form: CustomerState) => void;
}

const CustomerForm: FC<CustomerFormProps> = (props) => {
  const [form, setForm] = useState<CustomerState>({
    title: '',
    firstName: '',
    lastName: '',
    joined: new Date(),
    phone: '',
    dob: new Date(),
    nic: '',
  });
  const [errors, setErrors] = useState<ClientErrors[]>([]);

  const onTitleChange = (e: {
    originalEvent: Event;
    value: any;
    target: {
      name: string;
      id: string;
      value: any;
    };
  }) => {
    setForm((f) => ({ ...f, title: e.value }));
  };

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setForm((f) => ({ ...f, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors([]);
    setSchemaErrors(schema, form, setErrors);
    if (await validateSchema(schema, form)) {
      props.handleSubmit(form);
    }
  };

  useEffect(() => {
    if (props.customer !== undefined) {
      setForm(() => ({
        title: props.customer!.title,
        firstName: props.customer!.first_name,
        lastName: props.customer!.last_name,
        joined: new Date(Number(props.customer!.joined)),
        phone: props.customer!.phone,
        dob: new Date(Number(props.customer!.dob!)),
        nic: props.customer!.nic!,
      }));
    }
  }, [props.customer]);
  return (
    <form onSubmit={handleSubmit}>
      <Dropdown
        name="title"
        icon={<IoMdPerson color="#02c0f8" />}
        value={form.title}
        handleChange={onTitleChange}
        options={titles}
        optionLabel="name"
        optionValue="name"
        label="Title"
        placeholder="Pick a title"
        errorMsg={validateField('title', errors)}
      />
      <Input
        icon={<BsPencilSquare color="#02c0f8" />}
        value={form.firstName}
        name="firstName"
        placeholder="First Name"
        change={handleChange}
        errorMsg={validateField('firstName', errors)}
      />
      <Input
        icon={<BsPencilSquare color="#02c0f8" />}
        value={form.lastName}
        name="lastName"
        placeholder="Last Name"
        change={handleChange}
        errorMsg={validateField('lastName', errors)}
      />
      <Input
        icon={<HiPhone color="#02c0f8" />}
        value={form.phone}
        name="phone"
        placeholder="Phone Number"
        change={handleChange}
        errorMsg={validateField('phone', errors)}
      />
      <DatePicker
        icon={<MdDateRange color="#02c0f8" />}
        placeHolder="Joined Date"
        value={form.joined}
        onDayChange={(day) => setForm((f) => ({ ...f, joined: day }))}
        errorMsg={validateField('joined', errors)}
      />
      <DatePicker
        icon={<MdDateRange color="#02c0f8" />}
        placeHolder="Date of Birth"
        value={form.dob}
        onDayChange={(day) => setForm((f) => ({ ...f, dob: day }))}
      />
      <Input
        icon={<AiOutlineIdcard color="#02c0f8" />}
        value={form.nic}
        name="nic"
        placeholder="NIC"
        change={handleChange}
      />
      <div className="p-grid" style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            {props.buttonText}
          </Button>
        </div>
      </div>
    </form>
  );
};
export { CustomerForm };
