import { FC } from 'react';
import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

interface CardProps {
  icon?: JSX.Element;
  count?: string;
  title: string;
  style?: {}
}

const Box = styled.div`
  padding: 0.4rem;
  height: 15rem;
  width: 100%;
  margin: 20px;
  background: #2d2d33;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Card: FC<CardProps> = (props) => (
  <Box style={props.style}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.7rem'
      }}
    >
      <div>
        <div
          style={{
            background: '#1B1B21',
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            padding: '5px',
            fontSize: '1.5rem',
          }}
        >
          {props.icon}
        </div>
      </div>
      <div style={{ fontWeight: 100, fontSize: '.9rem' }}>View</div>
    </div>
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          padding: '0 1.7rem',
        }}
      >
        <div>
          <div style={{ fontSize: '2rem', color: '#0AA1D3' }}>
            {props.count}
          </div>
          <div
            style={{ fontSize: '.9rem', textAlign: 'left', color: '#b6b0b0' }}
          >
            {props.title}
          </div>
        </div>
        <div>
          <BsArrowRight />
        </div>
      </div>
    </div>
  </Box>
);
export { Card };
