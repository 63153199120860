import { FC, useEffect, useState } from 'react';
import { Calendar, Button } from '../../components/form';
import { useExportHcmSalesMutation } from '../../generated/graphql'
import { format } from 'date-fns';

const SalesExport:FC = () => {
	const [exportSales] = useExportHcmSalesMutation();
	const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();

	const handleExport = async () => {
		if(!from || !to) {
			alert('Dates need to be filled');
			return;
		}
		const { data } = await exportSales({ variables: { input: { 
			from: from ? format(from, 'Y-MM-dd') : '',
			to: to ? format(to, 'Y-MM-dd') : '', 
		} } });
		if(data?.downloadUsersExcel) {
			console.log(data?.downloadUsersExcel);
			const link = document.createElement('a');
	        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.downloadUsersExcel}`;
	        link!.download = `sales-${format(new Date(), 'Y-MM-dd HH-mm')}.xlsx`;
	        document.body.appendChild(link);
	        link.click();
	        document.body.removeChild(link);
		}
	}

	const handleRangeDateFilters = (
        e: { originalEvent: Event; value: Date },
        filter: string,
    ) => {
        if (filter === 'from') {
            setFrom(e.value);
        } else {
            setTo(e.value);
        }
    };

	return <div className="p-grid p-mb-1">
            <Calendar
                columnSize="p-col-6"
                placeholder="From"
                value={from}
                handleSelect={(e) => handleRangeDateFilters(e, 'from')}
            />
            <Calendar
                columnSize="p-col-6"
                placeholder="To"
                value={to}
                handleSelect={(e) => handleRangeDateFilters(e, 'to')}
            />
            <Button onClick={handleExport}>Download</Button>
        </div> 
}

export { SalesExport };