import { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useCategoriesQuery, useGetStockInvoicesQuery, useGetSupplierListQuery, useGetUniqueProductCodesQuery, useGetUniqueProductColorsQuery } from '../../generated/graphql';
import { BiSearchAlt } from 'react-icons/bi';
import { AutoComplete, Calendar, Dropdown } from '../../components/form';
import { arrayUnique } from '../../helpers/arrayUnique';
import { AiOutlineNumber } from 'react-icons/ai';
import { Dialog, Spinner } from '../../components';
import { AvailabilityWrapper } from '../../components/AvailabilityWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { InvoicePreview } from '../../components/inventory/InvoicePreview';

const StockInvoiceList: FC = () => {
    const history = useHistory();
    const dateFilterOptions = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'thisWeek', label: 'This Week' },
        { value: 'thisMonth', label: 'This Month' },
        { value: 'thisYear', label: 'This Year' },
      ];
    const limitOptions = [
        { value: '100', label: '100' },
        { value: '250', label: '250' },
        { value: '500', label: '500' },
    ];
    const [dateFilter, setDateFilter] = useState('');
    const [code, setCode] = useState('');
    const [category, setCategory] = useState('');
    const [supplier, setSupplier] = useState('');
    const [limit, setLimit] = useState('100');
    const [cursor, setCursor] = useState('');
    const [color, setColor] = useState('');
    const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();
    const [hasMore, setHasMore] = useState(true);
    const [filteredCodes, setFilteredCodes] = useState<string[]>();
    const [filteredColors, setFilteredColors] = useState<string[]>();
    const [filteredCategories, setFilteredCategories] = useState<string[]>();
    const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>();
    const [showInvoice, setShowInvoice] = useState(false);
    const { data: codes } = useGetUniqueProductCodesQuery();
    const { data: colors } = useGetUniqueProductColorsQuery();
    const { data: categories } = useCategoriesQuery();
    const { data: suppliers } = useGetSupplierListQuery();
    const { loading, data, client, fetchMore } = useGetStockInvoicesQuery({
        variables: {
            input: {
                limit,
                code,
                category,
                color,
                supplier,
                from: from?.toString(),
                to: to?.toString(),
                date_filter: dateFilter,
            }
        }
    });
    const [stockId, setStockId] = useState<number | null>(null);

    const getInvoiceData = useCallback(() => {
        if (data?.getStockInvoices.bgInvoice) {
            return data.getStockInvoices.bgInvoice;
        } else if (data?.getStockInvoices.ogfInvoice) {
            return data.getStockInvoices.ogfInvoice;
        } else if (data?.getStockInvoices.ogffInvoice) {
            return data.getStockInvoices.ogffInvoice;
         }
        else {
            return data?.getStockInvoices.hcInvoice;
        }
    }, [data?.getStockInvoices]);

    useEffect(() => {
        setHasMore(data?.getStockInvoices.hasMore!)
        if (data?.getStockInvoices.hasMore) {
            const invoices = getInvoiceData()!;
            if (invoices.length > 0) {
                setCursor(invoices[invoices.length - 1].stock_id.toString())
            }
        }
    }, [loading, data?.getStockInvoices.hasMore, getInvoiceData])
    
    const handleLoadMore = () => {
        fetchMore({
            variables: {
                input: {
                    limit, code, cursor, category, color, supplier, from: from?.toString(), to: to?.toString(), date_filter: dateFilter,
                },
            },
        })
    };

    const clearFilters = () => {
        setLimit('')
        setCode('')
        setCategory('')
        setColor('');
        setSupplier('');
        setFrom(undefined);
        setTo(undefined);
        setDateFilter('');
        client.resetStore()
    };

    useEffect(() => {
        clearFilters()
    }, []);

    const searchCategory = (e: { originalEvent: Event; query: string }) => {
        let filteredCategories: string[]
        if (categories && categories.categories.length > 0) {
            filteredCategories = categories.categories
                .filter((c) => {
                    return c.name!.toLowerCase().includes(e.query.toLowerCase())
                })
                .map((fc) => fc.name) as string[]
            setFilteredCategories(arrayUnique(filteredCategories))
        }
    };

    const handleAutoCompleteCategory = (e: {
        originalEvent: Event
        value: string
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setCategory(e.value)
    };

    const handleAutoCompleteSelect = () => {
        client.resetStore()
    };

    const searchCode = (e: { originalEvent: Event; query: string }) => {
        let filteredCodes: string[]
        filteredCodes =
            codes?.getUniqueProductCodes.filter((c) => {
                return c.toLowerCase().includes(e.query.toLowerCase())
            }) || []
        setFilteredCodes(arrayUnique(filteredCodes))
    };

    const handleAutoCompleteCode = (e: {
        originalEvent: Event
        value: string
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setCode(_code => e.value)
    };

    const searchSupplier = (e: { originalEvent: Event; query: string }) => {
        let filteredSuppliers: string[]
        filteredSuppliers =
            suppliers?.getSupplierList.filter((c) => {
                return c.name.toLowerCase().includes(e.query.toLowerCase())
            }).map(c => c.name) || []
        setFilteredSuppliers(arrayUnique(filteredSuppliers))
    };

    const handleAutoCompleteSupplier = (e: {
        originalEvent: Event
        value: string
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setSupplier(e.value);
    };

    const searchColor = (e: { originalEvent: Event; query: string }) => {
        let filteredColors: string[]
        filteredColors =
            colors?.getUniqueProductColors.filter((c) => {
                return c.toLowerCase().includes(e.query.toLowerCase())
            }) || []
        setFilteredColors(arrayUnique(filteredColors))
    };

    const handleAutoCompleteColor = (e: {
        originalEvent: Event
        value: string
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setColor(e.value)
    };

    const handleLimit = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setLimit(e.value);
        client.resetStore();
    };

    const handleShow = (data: any) => {
        setShowInvoice(true);
        setStockId(data.stock_id);
    };
    
    const handlePrint = () => {
        history.push(`/inventory/invoice/print/${stockId}`);
    };

    const handleRangeDateFilters = (
        e: { originalEvent: Event; value: Date },
        filter: string,
    ) => {
        if (filter === 'from') {
            setFrom(e.value)
        } else {
            setTo(e.value)
        }
        client.resetStore()
    };

    const handleDateDropdownFilters = (e: {
        originalEvent: Event
        value: any
        target: {
            name: string
            id: string
            value: any
        }
    }) => {
        setDateFilter(e.value)
        client.resetStore()
    };

    const tableHeader = (
        <div className="p-grid">
            <Calendar
                columnSize="p-col-3"
                placeholder="From"
                value={from}
                handleSelect={(e) => handleRangeDateFilters(e, 'from')}
            />
            <Calendar
                columnSize="p-col-3"
                placeholder="To"
                value={to}
                handleSelect={(e) => handleRangeDateFilters(e, 'to')}
            />
            <Dropdown
                columnSize="p-col-3"
                placeholder="Pick a date filter"
                value={dateFilter}
                handleChange={handleDateDropdownFilters}
                name="dateFilter"
                options={dateFilterOptions}
                optionLabel="label"
                optionValue="value"
            />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search Category"
            value={category}
            handleCompleteMethod={searchCategory}
            suggestions={filteredCategories}
            handleChange={handleAutoCompleteCategory}
            handleSelect={handleAutoCompleteSelect}
          />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search Code"
            value={code}
            handleCompleteMethod={searchCode}
            suggestions={filteredCodes}
            handleChange={handleAutoCompleteCode}
            handleSelect={handleAutoCompleteSelect}
          />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search supplier"
            value={supplier}
            handleCompleteMethod={searchSupplier}
            suggestions={filteredSuppliers}
            handleChange={handleAutoCompleteSupplier}
            handleSelect={handleAutoCompleteSelect}
        />
          <AutoComplete
            columnSize="p-col-3"
            icon={<BiSearchAlt color="#02c0f8" />}
            placeholder="Search color"
            value={color}
            handleCompleteMethod={searchColor}
            suggestions={filteredColors}
            handleChange={handleAutoCompleteColor}
            handleSelect={handleAutoCompleteSelect}
          />
          <Dropdown
            columnSize="p-col-3"
            icon={<AiOutlineNumber color="#01c0f8" />}
            placeholder="Record Limit"
            value={limit}
            handleChange={handleLimit}
            name="limit"
            options={limitOptions}
            optionLabel="label"
            optionValue="value"
          />
        </div>
    );
    
    return loading ? <Spinner /> : <>
        <AvailabilityWrapper>
        <Dialog
          header="Stock Invoice"
          visible={showInvoice}
          handleOnHide={() => setShowInvoice(false)}
        >
            <InvoicePreview stockId={stockId} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '1rem' }}>
                <Button onClick={handlePrint}>Print</Button>
            </div>
        </Dialog>
            <DataTable
                header={tableHeader}
                value={getInvoiceData()!}
                rowGroupMode="rowspan"
                groupField="code"
                sortMode="single"
                sortField="code"
                sortOrder={1}
                emptyMessage="No records"
            >
                <Column header="Stock Date" body={(data: any) => format(Number(data.date), 'yyyy/MM/dd')}></Column>
                <Column header="Supplier" field="supplier" />
                <Column body={(data: any) => <Button style={{ padding: '.2rem 1rem', fontSize: '1rem' }} onClick={() => handleShow(data)}>View</Button>} />
            </DataTable>
            <div className="p-d-flex p-jc-between p-mt-5">
                <Button onClick={clearFilters} type="button" className="p-button-sm">
                    {loading ? 'Loading' : 'Clear Filters'}
                </Button>
                <Button
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                    className="p-button-sm"
                >
                    {loading ? 'Loading' : 'Load More'}
                </Button>
            </div>
        </AvailabilityWrapper>
    </>
}

export { StockInvoiceList };