import { FC, FormEvent, useState } from 'react'
import {
  ClientErrors,
  validateField,
  validateSchema,
} from '../../helpers/handleClientValidation'
import { Input, Dropdown } from '../../components/form'
import { useGetRolesQuery, User } from '../../generated/graphql'
import { IoMdPerson } from 'react-icons/io'
import { FaUser } from 'react-icons/fa'
import { RiKeyLine } from 'react-icons/ri'
import { BsPencilSquare } from 'react-icons/bs'
import { Button } from 'primereact/button'
import { setSchemaErrors } from '../../helpers/handleClientValidation'
import * as Yup from 'yup'
import { useMemo } from 'react'

export interface UserState {
  username: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  roleId: string
}

interface UserFormProps {
  buttonText: string
  user?: User & { roleId: number }
  handleSubmit: (form: UserState) => void
}

const UserForm: FC<UserFormProps> = (props) => {
  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: !props.user
      ? Yup.string().required('Password is required')
      : Yup.string(),
    confirmPassword: !props.user
      ? Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      : Yup.string(),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    roleId: Yup.string().required('Role is required'),
  })
  const { data: roles } = useGetRolesQuery()
  const [form, setForm] = useState<UserState>({
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    roleId: '',
  })
  const [errors, setErrors] = useState<ClientErrors[]>([])

  useMemo(() => {
    if (props.user) {
      setForm({
        username: props.user.username,
        password: '',
        confirmPassword: '',
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        roleId: String(props.user.roleId),
      })
    }
  }, [props.user])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setForm((f) => ({ ...f, [name]: value }))
  }

  const onRoleChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setForm((f) => ({ ...f, roleId: e.value }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    setSchemaErrors(schema, form, setErrors)
    if (props.user) {
      if (await validateSchema(schema, form)) {
        props.handleSubmit(form)
      }
      return
    }
    if (await validateSchema(schema, form)) {
      props.handleSubmit(form)
      setForm({
        username: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        roleId: '',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        icon={<FaUser />}
        value={form.username}
        name="username"
        placeholder="Username"
        change={handleChange}
        errorMsg={validateField('username', errors)}
      />
      {!props.user && (
        <Input
          icon={<RiKeyLine />}
          type="password"
          value={form.password}
          name="password"
          placeholder="Password"
          change={handleChange}
          errorMsg={validateField('password', errors)}
        />
      )}
      {!props.user && (
        <Input
          icon={<RiKeyLine />}
          type="password"
          value={form.confirmPassword}
          name="confirmPassword"
          placeholder="Confirm Password"
          change={handleChange}
          errorMsg={validateField('confirmPassword', errors)}
        />
      )}
      <Input
        icon={<BsPencilSquare />}
        value={form.firstName}
        name="firstName"
        placeholder="First Name"
        change={handleChange}
        errorMsg={validateField('firstName', errors)}
      />
      <Input
        icon={<BsPencilSquare />}
        value={form.lastName}
        name="lastName"
        placeholder="Last Name"
        change={handleChange}
        errorMsg={validateField('lastName', errors)}
      />
      <Dropdown
        name="roleId"
        icon={<IoMdPerson color="#02c0f8" />}
        value={Number(form.roleId)}
        handleChange={onRoleChange}
        options={roles?.roles}
        optionLabel="name"
        optionValue="id"
        placeholder="Pick a Role"
        errorMsg={validateField('roleId', errors)}
      />
      <div style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            {props.buttonText}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { UserForm }
