import { Button } from 'primereact/button'
import { FC, FormEvent, useEffect, useState } from 'react'
import { Input } from '../form/Input'
import * as Yup from 'yup'
import {
  ClientErrors,
  validateErrors,
  validateField,
} from '../../helpers/handleClientValidation'
import {
  useCreateProductTypeMutation,
  ProductVariationType,
  useUpdateProductTypeMutation,
  useGetBrandsQuery,
  AvaiableStockDocument,
  GetProductTypesDocument,
} from '../../generated/graphql'
import { GrProductHunt } from 'react-icons/gr'
import { AiOutlineNumber } from 'react-icons/ai'
import { AutoComplete } from '../form'
import { BiSearchAlt } from 'react-icons/bi'
import { CgSize } from 'react-icons/cg'

interface AddMainProductProps {
  hideDialog: () => void
  toastSuccess: (message: string) => void
  toastError: (message: string) => void
  productType?: ProductVariationType
  edit?: boolean
}

const schema = Yup.object().shape({
  name: Yup.string().required('Product name is required').max(50),
  code: Yup.string().required('Code is required').max(12),
  from: Yup.string().required('From size is required'),
  to: Yup.string().required('To size is required'),
});

const AddMainProduct: FC<AddMainProductProps> = (props) => {
  const [form, setForm] = useState<{
    name: string;
    code: string;
    productId: string;
    from: string;
    to: string;
  }>({
    name: '',
    code: '',
    productId: '',
    from: '',
    to: ''
  })
  const [selectedBrand, setSelectedBrand] = useState<string>('')
  const [filteredBrands, setFilteredBrands] = useState<
    { id: number; name: string }[]
  >([])
  const { data: brands } = useGetBrandsQuery()
  const [createProduct] = useCreateProductTypeMutation()
  const [updateProduct] = useUpdateProductTypeMutation()
  const [errors, setErrors] = useState<ClientErrors[]>([])

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setForm((s) => ({ ...s, [name]: value }))
  }

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    query: string
  }) => {
    const getFilteredBrands = brands?.getBrands
      .filter((b) => b.name.toLowerCase().includes(e.query.toLowerCase()))
      .map((b) => ({ id: b.id, name: b.name }))
    setFilteredBrands(getFilteredBrands!)
  }

  const handleAutoCompleteChange = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSelectedBrand(e.value)
    setForm((s) => ({ ...s, productId: e.value.id }))
  }

  useEffect(() => {
    props.productType &&
      setForm((s) => ({
        ...s,
        code: props.productType!.code,
        name: props.productType!.name,
        productId: String(props.productType!.product.id),
        from: props.productType!.size?.split('-')[0] ?? '',
        to: props.productType!.size?.split('-')[1] ?? '',
      }))
    props.productType && setSelectedBrand(props.productType.product.name)
  }, [props.productType])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    schema
      .validate(form, { abortEarly: false })
      .catch((error: Yup.ValidationError) => {
        setErrors(validateErrors(error.inner))
      })
    if (await schema.isValid(form, { abortEarly: false })) {
      if (props.edit) {
        const response = await updateProduct({
          variables: {
            input: {
              id: props.productType!.id,
              code: form.code,
              name: form.name,
              productId: Number(form.productId),
              size: form.from + '-' + form.to
            },
          },
          refetchQueries: [
            {
              query: AvaiableStockDocument,
              variables: { input: { limit: '25' } },
            },
          ],
        })
        if (!response.data?.updateProductType.errors) {
          props.toastSuccess('Product has been updated.')
        } else {
          props.toastError(response.data.updateProductType.errors[0].message)
        }
      } else {
        const { from, to, ...rest } = form;
        const response = await createProduct({
          variables: { input: { ...rest, size: form.from + '-' + form.to, productId: Number(form.productId) } },
          refetchQueries: [
            {
              query: GetProductTypesDocument,
              variables: { input: {} },
            },
            {
              query: AvaiableStockDocument,
              variables: { input: {} },
            },
          ],
        })
        if (!response.data?.createProductType.errors) {
          props.toastSuccess(response.data?.createProductType.message!)
        } else {
          props.toastError(response.data.createProductType.errors[0].message)
        }
      }
      props.hideDialog()
    }
  }

  return (
    <form style={{ height: '40vh' }} onSubmit={handleSubmit}>
      <AutoComplete
        columnSize="p-col-12"
        icon={<BiSearchAlt color="#02c0f8" />}
        field="name"
        placeholder="Brand Name"
        value={selectedBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteChange}
        handleCompleteMethod={handleAutoCompleteBrand}
      />
      <Input
        change={handleInputChange}
        icon={<GrProductHunt />}
        name="name"
        placeholder="Name"
        value={form.name}
        errorMsg={validateField('name', errors)}
      />
      <Input
        change={handleInputChange}
        icon={<AiOutlineNumber />}
        name="code"
        placeholder="Code"
        value={form.code}
        errorMsg={validateField('code', errors)}
      />
      <div className="p-grid p-nogutter">
        <div className="p-col-6">
          <Input
            change={handleInputChange}
            icon={<CgSize />}
            name="from"
            placeholder="From Size"
            value={form.from}
            errorMsg={validateField('from', errors)}
          />
        </div>
        <div className="p-col-6">
          <Input
            change={handleInputChange}
            icon={<CgSize />}
            name="to"
            placeholder="To Size"
            value={form.to}
            errorMsg={validateField('to', errors)}
          />
        </div>
      </div>
      <div className="p-grid" style={{ marginTop: '3rem' }}>
        <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
          <Button className="button" type="submit">
            {props.edit ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { AddMainProduct }
