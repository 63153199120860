import { FC, useEffect, useState, MouseEvent, useRef, FormEvent } from 'react'
import {
  StockVariance,
  useGetSupplierListQuery,
  useGetStockVarianceQuery,
  useUpdateStockMutation,
  GetStockVarianceDocument,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductNamesQuery,
  useGetUniqueProductColorsQuery,
} from '../../generated/graphql'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { format } from 'date-fns'
import { currencyFormat } from '../../helpers/currencyFormat'
import { AutoComplete, Calendar, Dropdown, Input } from '../../components/form'
import { VarianceDatatableWrapper } from '../../components/VarianceDatatableWrapper'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Dialog } from '../../components/Dialog'
import { GiPriceTag } from 'react-icons/gi'
import { showToast } from '../../helpers/showSuccessToast'
import { BiSearchAlt, BiSortDown } from 'react-icons/bi'
import { arrayUnique } from '../../helpers/arrayUnique'
import { AiOutlineNumber } from 'react-icons/ai'

const Variance: FC = () => {
  const dateFilterOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'thisWeek', label: 'This Week' },
    { value: 'thisMonth', label: 'This Month' },
    { value: 'thisYear', label: 'This Year' },
  ];

  const limitOptions = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ];

  const [cursor, setCursor] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [from, setFrom] = useState<Date | undefined>();
  const [to, setTo] = useState<Date | undefined>();
  const [code, setCode] = useState('');
  const [color, setColor] = useState('');
  const [name, setName] = useState('');
  const [order, setOrder] = useState('DESC');
  const [supplier, setSupplier] = useState('');
  const [updateSupplier, setUpdateSupplier] = useState('');
  const [limit, setLimit] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const { data: suppliers } = useGetSupplierListQuery();
  const { loading, data, client, fetchMore } = useGetStockVarianceQuery({
    variables: {
      input: {
        limit,
        code,
        color,
        from: from?.toString(),
        to: to?.toString(),
        order,
        date_filter: dateFilter,
        name,
        supplier,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const [show, setShow] = useState(false);
  const [variance, setVariance] = useState<StockVariance>();
  const [form, setForm] = useState({ purchasePrice: '', date: '' });
  const [updateStock] = useUpdateStockMutation();
  const { data: codes } = useGetUniqueProductCodesQuery();
  const { data: colors } = useGetUniqueProductColorsQuery();
  const { data: names } = useGetUniqueProductNamesQuery();
  const [filteredNames, setFilteredNames] = useState<string[]>();
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredSuppliers, setFilteredSuppliers] = useState<string[]>();
  const [filteredColors, setFilteredColors] = useState<string[]>();

  const toastSuccess = useRef<Toast>(null);
  const toastError = useRef<Toast>(null);

  useEffect(() => {
    setHasMore(data?.getStockVariance.hasMore!)
    if (data?.getStockVariance.hasMore) {
      const stockVariances = data.getStockVariance.stockVariance || []
      if (stockVariances.length > 0) {
        setCursor(stockVariances[stockVariances.length - 1].id.toString())
      }
    }
  }, [loading]);

  const handleRangeDateFilters = (
    e: { originalEvent: Event; value: Date },
    filter: string,
  ) => {
    if (filter === 'from') {
      setFrom(e.value)
    } else {
      setTo(e.value)
    }
    client.resetStore()
  }

  const handleDateDropdownFilters = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setDateFilter(e.value)
    client.resetStore()
  }

  const handleUpdateSupplier = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setUpdateSupplier(e.value)
  }

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value);
    client.resetStore();
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          code,
          from: from?.toString(),
          to: to?.toString(),
          order,
          date_filter: dateFilter,
          name,
          supplier,
        },
      },
    });
  };

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchSupplier = (e: { originalEvent: Event; query: string }) => {
    let filteredSuppliers: string[]
    filteredSuppliers =
      suppliers?.getSupplierList.filter((c) => {
        return c.name.toLowerCase().includes(e.query.toLowerCase())
      }).map(c => c.name) || []
    setFilteredSuppliers(arrayUnique(filteredSuppliers))
  }

  const searchColor = (e: { originalEvent: Event; query: string }) => {
    let filteredColors: string[]
    filteredColors =
      colors?.getUniqueProductColors.filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredColors(arrayUnique(filteredColors))
  }

  const searchName = (e: { originalEvent: Event; query: string }) => {
    let filteredNames: string[]
    filteredNames =
      names?.getUniqueProductNames.filter((p) => {
        return p.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredNames(arrayUnique(filteredNames))
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleAutoCompleteSupplier = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSupplier(suppliers?.getSupplierList.find(s => s.name === e.value)?.id ?? '');
  }

  const handleAutoCompleteColor = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value)
  }

  const handleAutoCompleteName = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setName(e.value)
  }

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const handleOrder = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setOrder(e.value);
    client.resetStore();
  };

  const clearFilters = () => {
    setLimit('');
    setFrom(undefined);
    setTo(undefined);
    setDateFilter('');
    setSupplier('');
    setCode('');
    setColor('');
    setName('');
    setOrder('DESC');
    client.resetStore();
  };

  useEffect(() => {
    clearFilters();
  }, []);

  const handleOnClickAction = (
    _e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: StockVariance,
  ) => {
    if (action === 'edit') {
      setVariance(data);
      setForm({ purchasePrice: data.purchase_price.toString(), date: format(Number(data.date), 'Y-MM-dd') });
      setUpdateSupplier(String(data.supplier_id));
      setShow(true);
    }
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const f = { ...form };
    f.purchasePrice = e.currentTarget.value;
    setForm(f);
  }

  const updateStockVariance = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShow(false)
    const { data } = await updateStock({
      variables: {
        input: { spv_id: variance?.spv_id!, supplier_id: Number(updateSupplier), purchasePrice: form.purchasePrice, date: format(new Date(form.date), 'Y-MM-dd') },
      },
      refetchQueries: [
        {
          query: GetStockVarianceDocument,
          variables: {
            input: {
              limit,
              code,
              color,
              from: from?.toString(),
              to: to?.toString(),
              order,
              date_filter: dateFilter,
              name,
              supplier,
            },
          },
        },
      ],
    })
    if (data?.updateStock) {
      showToast(toastSuccess, 'Stock has been updated', 'success')
    } else {
      showToast(toastError, 'Something went wrong', 'error')
    }
  }

  const tableHeader = (
    <div className="p-grid p-mb-1">
      <Calendar
        columnSize="p-col-3"
        placeholder="From"
        value={from}
        handleSelect={(e) => handleRangeDateFilters(e, 'from')}
      />
      <Calendar
        columnSize="p-col-3"
        placeholder="To"
        value={to}
        handleSelect={(e) => handleRangeDateFilters(e, 'to')}
      />
      <Dropdown
        columnSize="p-col-3"
        placeholder="Pick a date filter"
        value={dateFilter}
        handleChange={handleDateDropdownFilters}
        name="dateFilter"
        options={dateFilterOptions}
        optionLabel="label"
        optionValue="value"
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search supplier"
        value={suppliers?.getSupplierList.find(s => s.id === supplier)?.name ?? ''}
        handleCompleteMethod={searchSupplier}
        suggestions={filteredSuppliers}
        handleChange={handleAutoCompleteSupplier}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search color"
        value={color}
        handleCompleteMethod={searchColor}
        suggestions={filteredColors}
        handleChange={handleAutoCompleteColor}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Name"
        value={name}
        handleCompleteMethod={searchName}
        suggestions={filteredNames}
        handleChange={handleAutoCompleteName}
        handleSelect={handleAutoCompleteSelect}
      />
      <Dropdown
        columnSize="p-col-3"
        placeholder="Sort Records"
        icon={<BiSortDown color="#02c0f8" />}
        value={order}
        handleChange={handleOrder}
        name="order"
        options={[
          { value: 'ASC', label: 'Oldest First' },
          { value: 'DESC', label: 'Latest First' },
        ]}
        optionLabel="label"
        optionValue="value"
      />
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  )

  return (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <Dialog
        header="Update Stock"
        visible={show}
        handleOnHide={() => setShow(false)}
      >
        <form onSubmit={updateStockVariance}>
          <Calendar
            handleSelect={(e: { originalEvent: Event; value: Date }) => setForm(f => ({ ...f, date: e.value.toString() }))}
            value={new Date(form.date)} />
          <Input
            icon={<GiPriceTag />}
            name="purchase_price"
            value={form.purchasePrice}
            placeholder="Purchase Price"
            change={handleChange}
          />
          <Dropdown
            placeholder="Select Supplier"
            value={updateSupplier}
            handleChange={handleUpdateSupplier}
            name="supplier"
            options={suppliers?.getSupplierList}
            optionLabel="name"
            optionValue="id"
          />
          <div className="p-grid" style={{ marginTop: '3rem' }}>
            <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
              <Button className="button" type="submit">
                <i className={`pi pi-pencil} p-mr-2`} /> Update
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
      <VarianceDatatableWrapper>
        <DataTable
          value={data?.getStockVariance.stockVariance}
          header={tableHeader}
        >
          <Column
            header="#"
            body={(_: any, props: any) => props.rowIndex + 1}
          />
          <Column field="code" header="Code" />
          <Column field="name" header="Name" />
          <Column field="quantity" header="Quantity" />
          <Column
            field="purchase_price"
            header="Purchase Price"
            body={(x: StockVariance) => currencyFormat(x.purchase_price)}
          />
          <Column
            field="total"
            header="Total"
            body={(x: StockVariance) => currencyFormat(x.total)}
          />
          <Column
            field="date"
            header="Date"
            body={(x: StockVariance) => format(Number(x.date), 'Y-MM-dd')}
          />
          <Column field="supplier_name" header="Supplier" />
          <Column
            header="Actions"
            body={(x: StockVariance) => (
              <Button
                icon="pi pi-pencil"
                tooltip={`Edit ${x.name}`}
                tooltipOptions={{ position: 'bottom' }}
                className="p-button-text p-button-sm"
                onClick={(e: any) => handleOnClickAction(e, 'edit', x)}
                type="button"
              />
            )}
          />
        </DataTable>
      </VarianceDatatableWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} type="button" className="p-button-sm">
            {loading ? 'Loading' : 'Clear Filters'}
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            {loading ? 'Loading' : 'Load More'}
          </Button>
        </div>
      </div>
    </>
  )
}

export { Variance }
