import styled from 'styled-components';

export const ThankYou = styled.div`
  margin-top: 0.8rem;
  display: block;
  padding: 0.8rem 0;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
`;
