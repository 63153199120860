import { FC, FormEvent, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Spinner } from '../../components'
import {
  GetReturnableStocksDocument,
  useGetReturnableStocksQuery,
  useSupplierReturnMutation,
} from '../../generated/graphql'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { format } from 'date-fns'
import { Calendar, Input } from '../../components/form'
import { AiOutlineNumber } from 'react-icons/ai'
import { Toast } from 'primereact/toast'

interface Stock {
  stock_id: number
  date: string
  name: string
  product_variation_id: number
  quantity: number
  returnable: number
}

interface ReturnStock {
  stockId: number
  date: string
  supplierId: number
  supplierReturnItems: [{ productVariationId: number; quantity: number }]
}

const SupplierReturn: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [returnStock, setReturnStock] = useState<ReturnStock[]>([])
  const { loading, data } = useGetReturnableStocksQuery({
    variables: { input: Number(match.params.id) },
    nextFetchPolicy: 'no-cache'
  })
  const [supplierReturn] = useSupplierReturnMutation()
  const toastSuccess = useRef<Toast>(null);

  const handleQuantity = (e: FormEvent<HTMLInputElement>, data: Stock) => {
    const currentReturnStock = [...returnStock]
    if (currentReturnStock.length < 1) {
      currentReturnStock.push({
        stockId: data.stock_id,
        date: '',
        supplierId: Number(match.params.id),
        supplierReturnItems: [
          {
            productVariationId: data.product_variation_id,
            quantity: Number(e.currentTarget.value),
          },
        ],
      })
      setReturnStock(currentReturnStock)
    } else {
      const rsIndex = currentReturnStock.findIndex(
        (rs) => rs.stockId === data.stock_id,
      )
      if (rsIndex !== -1) {
        const itemIndex = currentReturnStock[
          rsIndex
        ].supplierReturnItems.findIndex(
          (i) => i.productVariationId === data.product_variation_id,
        )
        if (itemIndex !== -1) {
          currentReturnStock[rsIndex].supplierReturnItems[
            itemIndex
          ].quantity = Number(e.currentTarget.value)
          setReturnStock(currentReturnStock)
        } else {
          currentReturnStock[rsIndex].supplierReturnItems.push({
            productVariationId: data.product_variation_id,
            quantity: Number(e.currentTarget.value),
          })
          setReturnStock(currentReturnStock)
        }
      } else {
        currentReturnStock.push({
          stockId: data.stock_id,
          date: '',
          supplierId: Number(match.params.id),
          supplierReturnItems: [
            {
              productVariationId: data.product_variation_id,
              quantity: Number(e.currentTarget.value),
            },
          ],
        })
        setReturnStock(currentReturnStock)
      }
    }
  }

  const handleDate = (
    e: { originalEvent: Event; value: Date },
    data: Stock,
  ) => {
    const state = [...returnStock]
    const stateIndex = state.findIndex((s) => s.stockId === data.stock_id)
    if (stateIndex !== -1) {
      state[stateIndex].date = e.value.toISOString()
    }
  }

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 3000,
      })
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { data } = await supplierReturn({
      variables: {
        input: returnStock,
      },
      refetchQueries: [
        {
          query: GetReturnableStocksDocument,
          variables: { input: Number(match.params.id) },
        },
      ],
    })
    if (data?.supplierReturn) {
      setReturnStock([])
      showSuccess('Stock has been returned');
    }
  }

  return loading ? (
    <Spinner />
  ) : (
      <>
        <Toast ref={toastSuccess} />
        <form onSubmit={handleSubmit}>
          <DataTable
            value={data?.getReturnableStocks}
            emptyMessage="No Stocks"
            rowGroupMode="rowspan"
            groupField="stock_id"
            sortMode="single"
            sortField="stock_id"
            sortOrder={1}
          >
            <Column
              field="stock_id"
              header="Stock Date"
              body={(data: Stock) => format(Number(data.date), 'yyyy-MM-dd')}
            />
            <Column field="name" header="Name" />
            <Column field="returnable" header="Returnable" />
            <Column
              field="quantity"
              header="Quantity"
              body={(data: Stock) => (
                <Input
                  change={(e) => handleQuantity(e, data)}
                  placeholder="Return Quantity"
                  name="quantity"
                  icon={<AiOutlineNumber />}
                  type="number"
                />
              )}
            />
            <Column
              field="date"
              header="Date"
              body={(data: Stock) => (
                <Calendar
                  handleSelect={(e) => handleDate(e, data)}
                  placeholder="Return Date"
                />
              )}
            />
          </DataTable>
          {data?.getReturnableStocks && data?.getReturnableStocks.length > 0 && (
            <div className="p-mt-2">
              <div className="p-col-2 p-offset-10 p-text-right">
                <Button className="button p-text-uppercase" type="submit">
                  Submit
                </Button>
              </div>
            </div>
          )}
        </form>
      </>
  )
}

export { SupplierReturn }
