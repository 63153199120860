import { AuthTypes, AuthAction, AuthState } from "./types";

const initialState: AuthState = { user: null, allPermissions: [] }

export const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthTypes.FETCH_AUTH_USER:
            return {
                ...state,
                user: action.payload
            }
        case AuthTypes.FETCH_PERMISSIONS:
            return { ...state, allPermissions: action.payload }
        default:
            return state;
    }
}