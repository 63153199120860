import { FC, FormEvent } from 'react'
import { InputText } from 'primereact/inputtext'
import styled from 'styled-components'
import { ErrorMessage } from './ErrorMessage'

interface InputProps {
  icon?: JSX.Element
  placeholder: string
  label?: string
  name: string
  value?: string | number | readonly string[] | undefined
  defaultValue?: string | number | readonly string[] | undefined
  change?: (e: FormEvent<HTMLInputElement>) => void
  keyUp?: (e: FormEvent<HTMLInputElement>) => void
  keyDown?: (e: FormEvent<HTMLInputElement>) => void
  type?: string
  style?: Object
  tooltip?: string
  tooltipOptions?: { position: string }
  min?: number
  max?: number
  minNumber?: number
  maxNumber?: number
  step?: number
  autoFocus?: boolean
  errorMsg?: string
  columnSize?: string
  input?: (e: FormEvent<HTMLInputElement>) => void,
  id?: string;
  disabled?: boolean;
}

export const InputWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))``

export const InputGroup = styled.div.attrs((props) => ({
  className: props.className,
}))``

export const InputGroupAddon = styled.span.attrs((props) => ({
  className: props.className,
}))``

export const InputLabel = styled.label`
  color: #fff;
  display: block;
  margin-bottom: 5px;
`

const Input: FC<InputProps> = (props) => {
  const {
    type = 'text',
    tooltipOptions = { position: 'top' },
    columnSize = 'p-col-12',
  } = props

  return (
    <InputWrapper className={columnSize}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <InputGroup className="p-inputgroup">
        <InputGroupAddon className="p-inputgroup-addon">
          {props.icon}
        </InputGroupAddon>
        <InputText
          disabled={props.disabled}
          min={props.minNumber}
          max={props.maxNumber}
          minLength={props.min}
          maxLength={props.max}
          step={props.step}
          tooltip={props.tooltip}
          tooltipOptions={tooltipOptions}
          style={props.style}
          type={type}
          value={props.value}
          placeholder={props.placeholder}
          name={props.name}
          onChange={props.change}
          onKeyUp={props.keyUp}
          onKeyDown={props.keyDown}
          autoFocus={props.autoFocus}
          onInput={props.input}
          id={props.id}
        />
      </InputGroup>
      {props.errorMsg && (
        <div className="p-col-12">
          <ErrorMessage message={props.errorMsg} />
        </div>
      )}
    </InputWrapper>
  )
}
export { Input }
