import { FC } from 'react';
import styled from 'styled-components';

interface ErrorMessageProps {
  message: string;
}

const ErrorWrapper = styled.div`
  color: #ecec3f;
`;

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => (
  <ErrorWrapper>{message}</ErrorWrapper>
);

export { ErrorMessage };
