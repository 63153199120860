import { FC, FormEvent, useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { useSelector } from 'react-redux'
import {
  ProductListResponse,
  Customer,
  useGetCustomerMutation,
  useCreateOrderMutation,
  OrderItem,
  useOrderPaymentTypesQuery,
  Payment,
  OrderPaymentType,
  GetOrderListDocument,
  GetproductsForReceiptDocument,
  GetOrderNumberDocument,
} from '../../generated/graphql'
import { RouteComponentProps } from 'react-router-dom'
import { Input } from '../../components/form/Input'
import { BarcodeModule } from '../../components'
import { RadioButton } from 'primereact/radiobutton'
import { FaMobileAlt } from 'react-icons/fa'
import { IoIosCash } from 'react-icons/io'
import { AiFillCreditCard, AiOutlineNumber } from 'react-icons/ai'
import { AppState } from '../../redux/rootReducer'

const CreateReceipt: FC<RouteComponentProps> = ({ history }) => {
  const [items, setItems] = useState<ProductListResponse[]>([])
  const [customer, setCustomer] = useState<Customer>()
  const [settleInPoints, setSettleInPoints] = useState<boolean>(false)
  const [available, setAvailable] = useState<boolean>(false)
  const [form, setForm] = useState({
    amountPaid: '',
    removePoints: 0,
  })
  const [paymentMethod, setPaymentMethod] = useState(1)
  const [payments, setPayments] = useState<Payment[]>([])
  const [cashAmount, setCashAmount] = useState<string>('')
  const [cardAmount, setCardAmount] = useState<string>('')
  const [receiptTotal, setReceiptTotal] = useState<number>(0)
  const [balance, setBalance] = useState<number>(0)
  const [date, setDate] = useState<Date>(new Date())
  const [getCustomer, { loading: loadingCustomer }] = useGetCustomerMutation()
  const [createOrder, { loading: lodingCreateOrder }] = useCreateOrderMutation()
  const [isHcmloyality, setIsHcmLoyality] = useState(false);
  const [hcmloyality, setHcmLoyality] = useState('');
  const [hcmCustomerPhone, setHcmCustomerPhone] = useState('');
  const [isZero, setIsZero] = useState(false);
  const branchId = useSelector<AppState, number>(
    (state) => state.auth.user?.branch.id!,
  )
  const {
    loading: loadingOrderPayments,
    data: orderPayments,
  } = useOrderPaymentTypesQuery()

  const changePaymentMethod = (o: OrderPaymentType) => {
    setCardAmount('');
    setCashAmount('');
    setForm({ ...form, amountPaid: '' });
    setPaymentMethod(o.id)
    switch (o.id) {
      case 1:
        setPayments([{ paymentType: 1, amount: receiptTotal }])
        break
      case 2:
        setForm({ ...form, amountPaid: String(receiptTotal) })
        setPayments([{ paymentType: 2, amount: receiptTotal }])
        break
      case 3:
        setPayments([{ paymentType: 1, amount: 0 }, { paymentType: 2, amount: 0 }]);
        break
      default:
        break
    }
  }

  useEffect(() => {
    for (let i of items) {
      if (i.quantity === 0) {
        setIsZero(true);
        break;
      }
      setIsZero(false);
    }
  }, [items]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let updatedPayment: Payment[] = [];
    const updateItems: Array<OrderItem> = items.map((i) => ({
      id: i.id,
      quantity: i.quantity,
      price: i.price,
      discount: i.discount,
      discountType: i.discount_type,
      addToStock: 0,
    }))
    if (paymentMethod === 3) {
      const p = [...payments]
      const x = p.find((p) => p.paymentType === 1)
      const y = p.find((p) => p.paymentType === 2)
      x!.amount = Number(cashAmount)
      y!.amount = Number(cardAmount)
      updatedPayment = [x!, y!];
      setPayments([x!, y!]);
    } else if (paymentMethod === 2) { 
      updatedPayment = [{
        paymentType: 2, amount: Number(receiptTotal)
      }];
      setPayments([{ paymentType: 2, amount: Number(receiptTotal) }])
    } else {
      updatedPayment = payments;
    }
    try {
      const { data } = await createOrder({
        variables: {
          input: {
            amountPaid: Number(form.amountPaid),
            branchId,
            addPoints: (Number(form.amountPaid) * 1) / 100,
            settleInPoints,
            customerId: customer?.id,
            removePoints: form.removePoints,
            items: updateItems,
            payments: updatedPayment,
            date: date.toISOString(),
            hcmLoyality: Number(hcmloyality),
            hcmCustomerPhone,
          },
        },
        refetchQueries: [
          { query: GetOrderListDocument, variables: { input: { } } },
          { query: GetproductsForReceiptDocument, variables: { input: 'receipt' } },
          { query: GetOrderNumberDocument }
        ],
      })
      form.amountPaid = ''
      if (data) {
        const { createOrder } = data
        if (createOrder.orderNumber) {
          return history.replace(
            `/sales/receipt/preview/${createOrder.orderNumber}`,
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCustomer = async (e: FormEvent<HTMLInputElement>) => {
    const { data } = await getCustomer({
      variables: { phone: e.currentTarget.value },
    })
    if (!loadingCustomer && data) {
      const customer = data.getCustomer as Customer
      setCustomer(customer)
    }
  }
  return (
    <div>
      {lodingCreateOrder ? (
        'Creating Receipt'
      ) : (
        <form onSubmit={handleSubmit}>
          <BarcodeModule
            getDate={setDate}
            dateName="Receipt"
            headers={{ priceType: 'Unit', discount: true }}
            showInvoiceNumber={true}
            getItems={setItems}
            receiptTotal={setReceiptTotal}
            checkAvailability
            getAvailable={setAvailable}
            balance={balance}
            hcmLoyality={hcmloyality}
            type="receipt"
            />
            {branchId === 3 && <div className="p-grid">
              <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                <RadioButton
                  name="hcmLoyality"
                  checked={isHcmloyality}
                  value={isHcmloyality}
                  onChange={() => setIsHcmLoyality(state => !state)}
                />
                <label htmlFor="cash" style={{ fontSize: '.9rem', marginLeft: '1rem' }}>Add loyality points</label>
                {isHcmloyality && <Input
                  name="hcmLoyality"
                  icon={<AiOutlineNumber />}
                  value={hcmloyality}
                  change={(e) => setHcmLoyality(e.currentTarget.value)}
                  keyUp={(e) => setHcmLoyality(e.currentTarget.value)}
                  placeholder="Loyality points"
                />}
              </div>
            </div>}
            {branchId === 3 && <div className="p-grid">
              <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                <Input
                  name="hcmCustomerPhone"
                  icon={<FaMobileAlt />}
                  change={(e) => setHcmCustomerPhone(e.currentTarget.value)}
                  keyUp={(e) => setHcmCustomerPhone(e.currentTarget.value)}
                  placeholder="Phone Number"
                  label="HCM customer phone number"
                />
              </div>
            </div>}
          <div className="p-grid">
            <div className="p-col-4">
              <Input
                name="phone"
                icon={<FaMobileAlt />}
                change={(e) => handleCustomer(e)}
                keyUp={(e) => handleCustomer(e)}
                placeholder="Phone Number"
                label="Add points to customer"
              />
              {loadingCustomer ? null : (
                <div style={{ marginTop: '2.5rem' }}>
                  {customer && (
                    <>
                      <div style={{ fontSize: '1.3rem', fontWeight: 'normal' }}>
                        {customer?.title}. {customer?.first_name}{' '}
                        {customer?.last_name}
                      </div>
                      <div
                        className="p-d-fex p-ai-center"
                        style={{ fontSize: '1rem', marginTop: '1.5rem' }}
                      >
                        Available Points:{' '}
                        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                          {customer.points}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}
              </div>
            <div className="p-col-4 p-offset-4">
              <h4
                style={{
                  marginTop: '1rem',
                  fontWeight: 'normal',
                  textAlign: 'right',
                  padding: '8px',
                }}
              >
                Payment Method
              </h4>
              <div className="p-d-flex p-justify-end">
                {loadingOrderPayments
                  ? 'Loading order payments'
                  : orderPayments?.orderPaymentTypes.map((o, i) => (
                      <div
                        className="p-field-radiobutton"
                        key={o.id}
                        style={{
                          padding: '0 8px',
                          marginRight:
                            i !== orderPayments?.orderPaymentTypes.length - 1
                              ? '.6rem'
                              : 0,
                        }}
                      >
                        <RadioButton
                          name="paymentMethod"
                          checked={o.id === paymentMethod}
                          value={paymentMethod}
                          inputId="cash"
                          onChange={() => changePaymentMethod(o)}
                        />
                        <label htmlFor="cash" style={{ fontSize: '.9rem' }}>
                          {o.name}
                        </label>
                      </div>
                    ))}
              </div>
              {paymentMethod === 1 && (
                <div className="p-grid">
                  <div className="p-col">
                    <Input
                      icon={<IoIosCash />}
                      name="cashAmount"
                      placeholder="Cash"
                      change={(e) => {
                        setCashAmount(e.currentTarget.value)
                        setForm({
                          ...form,
                          amountPaid: e.currentTarget.value,
                        })
                        setBalance(Number(e.currentTarget.value) - receiptTotal)
                        setPayments([
                          {
                            amount: receiptTotal,
                            paymentType: 1,
                          },
                        ])
                      }}
                    />
                  </div>
                </div>
              )}
              {paymentMethod === 3 && (
                <div className="p-grid">
                  <div className="p-col">
                    <Input
                      icon={<IoIosCash />}
                      name="cashAmount"
                      value={cashAmount}
                      placeholder="Cash"
                      change={(e) => {
                        setCashAmount(e.currentTarget.value)
                        setCardAmount(
                          String(receiptTotal - Number(e.currentTarget.value)),
                        )
                        setForm({
                          ...form,
                          amountPaid: String(
                            Number(cashAmount) + Number(cardAmount),
                          ),
                        })
                      }}
                    />
                  </div>
                  <div className="p-col">
                    <Input
                      placeholder="Card"
                      change={(e) => {
                        setCardAmount(e.currentTarget.value)
                        setCashAmount(
                          String(receiptTotal - Number(e.currentTarget.value)),
                        )
                        setForm({
                          ...form,
                          amountPaid: String(
                            Number(cashAmount) + Number(cardAmount),
                          ),
                        })
                      }}
                      icon={<AiFillCreditCard />}
                      name="cardAmount"
                      value={cardAmount}
                    />
                  </div>
                </div>
              )}
              {customer && (
                <div className="p-d-flex p-ai-center p-justify-end">
                  <Checkbox
                    onChange={(e) => setSettleInPoints(e.checked)}
                    checked={settleInPoints}
                  ></Checkbox>
                  <h4
                    style={{
                      marginLeft: '.6rem',
                      fontWeight: 'normal',
                      padding: '0 8px',
                    }}
                  >
                    Settle using points
                  </h4>
                </div>
              )}
              {settleInPoints && (
                <Input
                  name="removePoints"
                  icon={<AiOutlineNumber />}
                  change={(e) =>
                    setForm({
                      ...form,
                      removePoints: parseFloat(e.currentTarget.value),
                    })
                  }
                  placeholder="Points"
                />
              )}
            </div>
          </div>
          <div className="p-grid" style={{ marginTop: '1.5rem' }}>
            <div className="p-col-2 p-offset-10" style={{ textAlign: 'right' }}>
              <Button
                className="button"
                type="submit"
                disabled={
                  !available ||
                  items.length === 0 ||
                  Number(form.amountPaid) === 0 ||
                  isZero
                }
              >
                Create Receipt
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export { CreateReceipt };