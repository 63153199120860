import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @page {
      size: 80mm auto;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    body {
      font-family: 'Roboto', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 100%;
      height: 100vh;
      background: #1b1b21;
      overflow: hidden;
      margin: 0;
      padding: 0;
  }
  .active {
    color: #02c0f8 !important;
  }
`;
export { GlobalStyles };