import { combineReducers } from 'redux';
import { authReducer } from './auth/reducer';
import { AuthAction } from './auth/types';

export interface AppState {
    auth: ReturnType<typeof authReducer>
}

export type ActionTypes = AuthAction;

export default combineReducers<AppState, ActionTypes>({ auth: authReducer });