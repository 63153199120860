import { FC, FormEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import { Input } from '../../components/form'
import { FaUserTie } from 'react-icons/fa'
import { AiFillLock } from 'react-icons/ai'
import { Button } from 'primereact/button'
import { AiOutlineLogin } from 'react-icons/ai'
import { ReactComponent as LoginImage } from '../../images/login.svg'
import {
  useConfirmUserMutation,
  useLoginMutation,
  useMeQuery,
} from '../../generated/graphql'
import { Spinner } from '../../components/Spinner'
import { RouteComponentProps } from 'react-router'
import * as Yup from 'yup'
import {
  ClientErrors,
  setSchemaErrors,
  validateField,
  validateSchema,
} from '../../helpers/handleClientValidation'
import { Toast } from 'primereact/toast'
import { showToast } from '../../helpers/showSuccessToast'

const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 15rem;
`

const VectorWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(2, 192, 248, 1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.04);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
`

const FormWrapper = styled.div`
  flex: 50%;
  border: 1px solid rgba(2, 192, 248, 0.05);
  background: rgba(2, 192, 248, 0.05);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.18);
`

const Header = styled.h1`
  font-weight: 100;
  font-size: 3rem;
  color: #02c0f8;
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 8rem;
`

const GeneralButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #02c0f8;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  transition: transform 0.3s ease;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  span {
    margin-left: 0.5rem;
  }
  &:hover {
    background: #02c0f8 !important;
    transform: translateY(-0.1rem);
  }
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`

interface InitialState {
  username: string
  password: string
  newPassword?: string
  confirmNewPassword?: string
}

const Login: FC<RouteComponentProps> = ({ history }) => {
  const toastSuccess = useRef(null)
  const toastError = useRef(null)
  const [errors, setErrors] = useState<ClientErrors[]>([])
  const [serverError, setServerError] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(true)
  const { loading: loadingMe } = useMeQuery({
    onCompleted: ({ me }) => {
      if (me) {
        return history.replace('/')
      }
    },
  })
  const [form, setForm] = useState<InitialState>({
    username: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [login, { loading }] = useLoginMutation()
  const [confirmUser] = useConfirmUserMutation()
  const [id, setId] = useState<number>()

  const schema = Yup.object().shape({
    username: isConfirmed
      ? Yup.string().required('Username is required')
      : Yup.string(),
    password: isConfirmed
      ? Yup.string().required('Password is required')
      : Yup.string(),
    newPassword: !isConfirmed
      ? Yup.string()
          .required('Password is required')
          .min(6, 'Password has to be at least 6 characters long')
      : Yup.string(),
    confirmNewPassword: !isConfirmed
      ? Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
      : Yup.string(),
  })

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const state = { ...form, [name]: value }
    setForm(state)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors([])
    setServerError('')
    setSchemaErrors(schema, form, setErrors)
    if (await validateSchema(schema, form)) {
      if (!isConfirmed && id && form.newPassword) {
        const { data } = await confirmUser({
          variables: { input: { id: id, newPassword: form.newPassword } },
        });
        if (data?.confirmUser.id) {
          setIsConfirmed(true)
          setForm({
            username: '',
            password: '',
            newPassword: '',
            confirmNewPassword: '',
          })
          showToast(
            toastSuccess,
            'Password has been changed, please log in with the new password',
          )
        } else {
          showToast(toastError, data?.confirmUser.errors![0].message!, 'error')
        }
      } else {
        const response = await login({
          variables: { username: form.username, password: form.password },
        })
        if (response.data?.login.errors) {
          setServerError(response.data?.login.errors[0].message)
          return
        }
        if (response.data?.login.access_token) {
          if (response.data?.login.user?.approved) {
            localStorage.setItem('atci', response.data?.login.access_token);
            history.replace('/');
          } else {
            setId(response.data?.login.user?.id)
            setIsConfirmed(false)
          }
        }
      }
      setForm({ username: '', password: '' })
    }
  }

  return loading || loadingMe ? (
    <Spinner />
  ) : (
    <LoginWrapper>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
      <VectorWrapper>
        <LoginImage width="60%" />
      </VectorWrapper>
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <Header>Saheli Shoes</Header>
          {isConfirmed && (
            <Input
              icon={<FaUserTie />}
              name="username"
              value={form.username}
              placeholder="Username"
              change={handleChange}
              errorMsg={validateField('username', errors)}
            />
          )}
          {isConfirmed && (
            <Input
              icon={<AiFillLock />}
              name="password"
              value={form.password}
              placeholder="Password"
              type="password"
              change={handleChange}
              errorMsg={validateField('password', errors)}
            />
          )}
          {!isConfirmed && (
            <Input
              icon={<AiFillLock />}
              name="newPassword"
              placeholder="Pick new password"
              value={form.newPassword || ''}
              type="password"
              change={handleChange}
              errorMsg={validateField('newPassword', errors)}
            />
          )}
          {!isConfirmed && (
            <Input
              icon={<AiFillLock />}
              name="confirmNewPassword"
              placeholder="Confirm new password"
              value={form.confirmNewPassword || ''}
              type="password"
              change={handleChange}
              errorMsg={validateField('confirmNewPassword', errors)}
            />
          )}
          <div className="p-col-12">
            <GeneralButton>
              <AiOutlineLogin />{' '}
              <span>{isConfirmed ? 'Login' : 'Change Password'}</span>
            </GeneralButton>
          </div>
          {serverError !== '' && (
            <div style={{ color: '#ecec3f' }}>{serverError}</div>
          )}
        </Form>
      </FormWrapper>
    </LoginWrapper>
  )
}
export { Login }
